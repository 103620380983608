import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { routes } from '../utility/constants/constants';
import { userRoles } from '../utility/constants/constants';
import SignInContainer from '../adminOrSuperAdminOrRequestor/container/SignInContainer/signInContainer';
import ForgetPassword from '../adminOrSuperAdminOrRequestor/container/ForgetPasswordContainer/ForgetPassword';
import ResetPassword from '../adminOrSuperAdminOrRequestor/container/ResetPasswordContainer/ResetPassword';
import AdminDashbord from '../adminOrSuperAdminOrRequestor/container/DashbordContainer/DashbordContainer';
// import SuperAdminDashbord from '../adminOrSuperAdminOrRequestor/container/DashbordContainer/SuperAdminDashboardContainer';
// import RequestorDashbord from '../adminOrSuperAdminOrRequestor/container/DashbordContainer/RequestorDashboardContainer';
import UserContainer from '../adminOrSuperAdminOrRequestor/container/UserContainer/UserContainer';
import NewUser from '../adminOrSuperAdminOrRequestor/container/UserContainer/NewUserContainer/NewUserContainer';
import Accounts from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/AccountsContainer';
import NewAccounts from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/NewAccountContainer/NewAccountContainer';
import Quotes from '../adminOrSuperAdminOrRequestor/container/QuotesContainer/QuotesContainer';
import NewQuotes from '../adminOrSuperAdminOrRequestor/container/QuotesContainer/NewQuoteContainer/NewQuoteContainer';
import ApproveQuote from '../adminOrSuperAdminOrRequestor/container/QuotesContainer/ApproveQuoteContainer';
import Settings from '../adminOrSuperAdminOrRequestor/container/Settings/SettingsContainer';
import Thankyou from '../adminOrSuperAdminOrRequestor/container/ThanYouContainer/ThankyouContainer';
import Companies from '../adminOrSuperAdminOrRequestor/container/CompaniesContainer/CompaniesContainer';
import NewCompany from '../adminOrSuperAdminOrRequestor/container/CompaniesContainer/NewCompanieContainer/NewCompanieContainer';
import CompanyUsersContainer from '../adminOrSuperAdminOrRequestor/container/CompaniesContainer/CompanyUsersContainer/CompanyUsersContainer';
import AccountUsersContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/AccountUsersContainer/AccountUsersContainer';

//import AccountAreaContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/AccountAreaContainer';
import AddAreaContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/AccountAreaContainer/AddArea';
import EditAreaContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/AccountAreaContainer/EditArea';

import RatingsContainer from '../adminOrSuperAdminOrRequestor/container/RatingsContainer/RatingsContainer';
import NewRatingsContaier from '../adminOrSuperAdminOrRequestor/container/RatingsContainer/NewRatingContainer/NewRatingContainer';
import InspectionFormContainer from '../adminOrSuperAdminOrRequestor/container/InspectionFormContainer/InspectionFormContainer';
import NewInspectionFormContainer from '../adminOrSuperAdminOrRequestor/container/InspectionFormContainer/NewInspectionFormContainer/NewInspectionFormContainer';
import Inspections from '../adminOrSuperAdminOrRequestor/container/InspectionsContainer/InspectionsContainer';
import MyInspections from '../adminOrSuperAdminOrRequestor/container/MyInspectionsContainer/InspectionsContainer';
import PendingInspections from '../adminOrSuperAdminOrRequestor/container/PendingInspectionsContainer/InspectionsContainer';
import PerformInspection from '../adminOrSuperAdminOrRequestor/container/InspectionsContainer/PerformInspectionContainer/performInspectionContainer';
import TicketContainer from '../adminOrSuperAdminOrRequestor/container/TicketContainer/TicketContainer';
import PriorityEscTicketContainer from '../adminOrSuperAdminOrRequestor/container/PriorEscalationTicketsContainer/PriorityEscTicketContainer';
import MyTicketContainer from '../adminOrSuperAdminOrRequestor/container/MyTicketContainer/TicketContainer';
import ScheduledTicketContainer from '../adminOrSuperAdminOrRequestor/container/ScheduledTicketContainer/scheduledTicketContainer';
import NewTicketContainer from '../adminOrSuperAdminOrRequestor/container/TicketContainer/NewTicektContainer/NewTicketContainer';
import PreviewTicket from '../adminOrSuperAdminOrRequestor/container/TicketContainer/PreviewTicketContainer/PreviewTicketContainer';
import PreviewSingleTicket from '../adminOrSuperAdminOrRequestor/container/PreviewScheduledTicketContainer/PreviewSingleTicketContainer/previewSingleTicketContainer';
import PriorPreviewTicket from '../adminOrSuperAdminOrRequestor/container/PriorEscalationTicketsContainer/PriorEscPreviewTicketContainer/PriorEscPreviewTicketContainer';
import PreviewInspection from '../adminOrSuperAdminOrRequestor/container/InspectionsContainer/ViewInspectionContainer/viewInspectionContainer';
import PendingPreviewInspection from '../adminOrSuperAdminOrRequestor/container/PendingInspectionsContainer/ViewInspectionContainer/viewInspectionContainer';
import PreviewUser from '../adminOrSuperAdminOrRequestor/container/UserContainer/PreviewUserContainer/PreviewUserContainer';
import CategoryContainer from '../adminOrSuperAdminOrRequestor/container/CategoryContainer/CategoryContainer';
import SettingsPreferences from '../adminOrSuperAdminOrRequestor/container/SettingsPreferencesContainer/SettingsPreferencesContainer';
import CreateQuoteManually from '../adminOrSuperAdminOrRequestor/component/Quotes/NewQuotes/createQuoteManually';
import ReportsContainer from '../adminOrSuperAdminOrRequestor/container/ReportsContainer/ReportsContainer';
import Layout from '../hoc/layout/layout';

import ScanQr from "../adminOrSuperAdminOrRequestor/container/QR";
import SearchHistory from "../adminOrSuperAdminOrRequestor/container/SearchHistory";
import NewSearchHistory from "../adminOrSuperAdminOrRequestor/container/SearchHistory/HistoryNew";
import SchedulePage from "../adminOrSuperAdminOrRequestor/container/SearchHistory/SchedulePage";
import ScanSearchResult from "../adminOrSuperAdminOrRequestor/container/SearchHistory/ScanSearchResult";

import Schedule from "../adminOrSuperAdminOrRequestor/container/Schedule";

import FrontlineList from "../adminOrSuperAdminOrRequestor/container/Frontline";
import AddFrontline from "../adminOrSuperAdminOrRequestor/container/Frontline/AddFrontline";
import EditFrontline from "../adminOrSuperAdminOrRequestor/container/Frontline/EditFrontline";
import Insights from "../adminOrSuperAdminOrRequestor/container/Insights";
import Pegassure from '../adminOrSuperAdminOrRequestor/container/Peassure/Pegassure';

import CHIServysContainer from '../adminOrSuperAdminOrRequestor/container/CHIServysContainer/CHIServysContainer';
import CHIReviewContianer from '../adminOrSuperAdminOrRequestor/container/CHIReviewContainer/ChiReviewContainer';
import Oux from '../hoc/Oux/Oux';
import CHIPreviewContainer from '../adminOrSuperAdminOrRequestor/container/CHIPreviewContainer/CHIPreviewContainer';
import CHIPreview from '../adminOrSuperAdminOrRequestor/component/CHIPreview/CHIPreview';
import KpiTabs from '../adminOrSuperAdminOrRequestor/component/KpiDashboard/KpiTabs';
import CriteriaContainer from '../adminOrSuperAdminOrRequestor/container/CriteriaContainer/CriteriaContainer';
import AccountsCalenderTableContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/AccountCalenderContainer/AccountsCalenderTableContainer';
import AccountNewSchuleContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/AccountScheduleContainer/AccountNewSchuleContainer';
import SchedulesContainer from '../adminOrSuperAdminOrRequestor/container/SchedulesContainer/SchedulesContainer';
import InspectionScheduleHistoryContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/InspectionScheduleHistoryContainer/InspectionScheduleHistoryContainer';
import PegassureLogbookContainer from '../adminOrSuperAdminOrRequestor/container/PegassureLogbookContainer/PegassureLogbookContainer';
import AddLogBookAreaContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/LogBookAreaContainer/LogBookAreaFormContainer/AddLogBookAreaContainer';
import EditLogBookAreaContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/LogBookAreaContainer/EditLogBookAreaContainer';
import PreviewContainerLogBookArea from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/LogBookAreaContainer/PreviewContainerLogBookArea/PreviewContainerLogBookArea';
import LogBookFeedbackContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/LogBookAreaContainer/LogBookFeedbackContainer/logBookFeedbackContainer';
import LogBookThankYou from '../adminOrSuperAdminOrRequestor/component/Accounts/LogBook Area/LogBook Feedback/LogBookThankYou';
import LogBookAreaContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/LogBookAreaContainer/LogBookAreaContainer'
import PreviewLogBookLogsArea from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/LogBookAreaContainer/PreviewContainerLogBookArea/PreviewLogBookLogsArea';
import LogBookQrReader from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/LogBookAreaContainer/LogBookQRContainer/LogBookQRContainer';
import SearchLogbookHistoryContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/LogBookAreaContainer/SearchLogbookHistoryContainer/SearchLogbookHistoryContainer';
import FeedbackContainer from '../adminOrSuperAdminOrRequestor/container/PegAssureContainer/FeedbackContainer';
import CommentsContainer from '../adminOrSuperAdminOrRequestor/container/PegAssureContainer/CommentsContainer';
import ThankyouContainer from '../adminOrSuperAdminOrRequestor/container/PegAssureContainer/ThankyouContainer';
import PegAssureContainer from '../adminOrSuperAdminOrRequestor/container/PegAssureContainer/PegAssureContainer';
import PreviewFeedbackContainer from '../adminOrSuperAdminOrRequestor/container/PegAssureContainer/PreviewFeedbackContainer';
import HomePageContainer from '../adminOrSuperAdminOrRequestor/container/HomePageContainer/HomePageContainer';
import UserProfileComponent from '../adminOrSuperAdminOrRequestor/component/UserProfileComponent/UserProfileComponent';
import previewScheduledTicketContainer from '../adminOrSuperAdminOrRequestor/container/PreviewScheduledTicketContainer/previewScheduledTicketContainer';
import LogBookQrButton from '../adminOrSuperAdminOrRequestor/container/HomePageContainer/LogBookQrButton';
import AccountNewTicketScheduleContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/AccountTicketScheduleContainer/AccountNewTicketScheduleContainer';
import TicketScheduleHistoryContainer from '../adminOrSuperAdminOrRequestor/container/ AccountsContainer/TicketScheduleHistoryContainer/TicketScheduleHistoryContainer';
import NewVersionContainer from '../adminOrSuperAdminOrRequestor/container/NewVersionContainer/NewVersionContainer';
import APIAccessContainer from '../adminOrSuperAdminOrRequestor/container/CompaniesContainer/APIAccessContainer/APIAccessContainer';
import storage from '../utility/storage';
// import ReactGA from "react-ga4";
// import ReactGA from "react-ga4";
// import { logEvent } from "firebase/analytics";
// import { analytics } from '../App';
import boxContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/BoxContainer/boxContainer';
import newBoxContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/BoxContainer/NewBoxContainer/newBoxContainer';
import auditLogsContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryTicketsContainer/AuditLogsContainer/auditLogsContainer';
import PickingTicketContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryTicketsContainer/PickingTicketContainer/pickingTicketContainer';
import InventoryThankYou from '../adminOrSuperAdminOrRequestor/component/Inventory/ThankYouComponent/InventoryThankyou';
import emergencyPickupContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryTicketsContainer/EmergencyPickupContainer/emergencyPickupContainer';
import assetsListContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/AssetsContainer/AssetsListContainer/assetsListContainer';
import NewAssetsContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/AssetsContainer/NewAssetsContainer/NewAssetsContainer';
import assetsPreviewContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/AssetsContainer/AssetsPreviewContainer/assetsPreviewContainer';
import AssetCheckInQRScan from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/AssetsContainer/AssetScansContainer/AssetCheckInQRScan/AssetCheckInQRScan';
import AssetCheckOutQRScan from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/AssetsContainer/AssetScansContainer/AssetCheckOutQRScan/AssetCheckOutQRScan';
import AssetCheckInFormContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/AssetsContainer/AssetScanExamineFormContainer/AssetCheckInFormContainer/AssetCheckInFormContainer';
import AssetCheckOutFormContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/AssetsContainer/AssetScanExamineFormContainer/AssetCheckOutFormContainer/AssetCheckOutFormContainer';
import InventoryListContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryListContainer/InventoryListContainer';
import NewInventoryItemContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryListContainer/NewInventoryItemContainer/NewInventoryItemContainer';
import previewInventoryContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryListContainer/PreviewInventoryContainer/previewInventoryContainer';
import InventoryPullRequestContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryPullRequestContainer/InventoryPullRequestContainer';
import PickupsTicketContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryTicketsContainer/PickupsTicketContainer/PickupsTicketContainer';
import FulfillmentManagerFormContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryTicketsContainer/PickupsTicketContainer/FulfillmentManagerFormContainer/FulfillmentManagerFormContainer';
import ReadyForPickupQRScan from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryScansContainer/ReadyForPickupQRScan/ReadyForPickupQRScan';
import PickupDetailsFormContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryTicketsContainer/PickupsTicketContainer/PickupDetailsFormContainer/PickupDetailsFormContainer';
import ReadyForDeliveryQRScan from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryScansContainer/ReadyForDeliveryQRScan/ReadyForDeliveryQRScan';
import ForPickedDeliveryQRScan from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryScansContainer/ForPickedDeliveryQRScan/ForPickedDeilveryQRScan';
import ScanForDeliveryFormContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryTicketsContainer/PickupsTicketContainer/ScanForDeliveryFormContainer/ScanForDeliveryFormContainer';
import ListedItemsComingBackFormContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryTicketsContainer/PickupsTicketContainer/ListedItemsComingBackFormContainer/ListedItemsComingBackFormContainer';
import DeliveryReturnWarehouseQRScan from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/InventoryScansContainer/DeliveryReturnWarehouseQRScan/DeliveryReturnWarehouseQRScan';
import LocationContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/LocationContainer';
import UnavailableItem from '../adminOrSuperAdminOrRequestor/component/Inventory/InventoryPullRequestForm/UnavailableItem';
import assetStockContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/AssetsContainer/AssetStockContainer/assetStockContainer';
import newAssetStockContainer from '../adminOrSuperAdminOrRequestor/container/InventoryContainer/AssetsContainer/NewAssetStockContainer/newAssetStockContainer';
import ChiQuarterlySurveyWaitScreen from '../adminOrSuperAdminOrRequestor/component/CHISurveyQuarterly/ChiQuarterlySurveyWaitScreen';
import SkillSetContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/SkillSetContainer/SkillSetContainer';
import DepartmentContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/DepartmentContainer/DepartmentContainer';
import LeaveListContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/LeavesTrackContainer/LeaveListContainer';
import NewLeaveContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/LeavesTrackContainer/NewLeaveContainer/NewLeaveContainer';
import PreviewLeaveRequestContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/LeavesTrackContainer/PreviewLeaveRequestContainer/PreviewLeaveRequestContainer';
import ScheduledTaskContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ScheduledTaskContainer/ScheduledTaskContainer';
import SubScheduledTaskContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/SubScheduledTaskContainer/SubScheduledTaskContainer';
import PreviewSubScheduledTaskContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/SubScheduledTaskContainer/PreviewSubScheduledTaskContainer/PreviewSubScheduledTaskContainer';
import NewScheduledTaskContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ScheduledTaskContainer/NewScheduledTaskContainer/NewScheduledTaskContainer';
import FaceRecognitionContainer from '../adminOrSuperAdminOrRequestor/container/Frontline/FrontlineUserFaceRecognition/FaceRecognitionContainer';
import addAvailabilityContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/AvailabilityContainer/AddAvailabilityContainer/addAvailabilityContainer';
import AvailabilityCalendarContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/AvailabilityContainer/AvailabilityCalendarContainer/AvailabilityCalendarContainer';
import overrideAvailabilityContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/AvailabilityContainer/OverrideAvailabilityContainer/overrideAvailabilityContainer';
import ShiftScheduleContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ShiftContainer/ShiftScheduleContainer/ShiftScheduleContainer';
import ShiftScheduleDetailContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ShiftContainer/ShiftScheduleDetailContainer/ShiftScheduleDetailContainer';
import PreviewShiftScheduleContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ShiftScheduleContainer/PreviewShiftScheduleContainer/PreviewShiftScheduleContainer';
import ShiftCalendarContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ShiftContainer/ShiftCalendarContainer/ShiftCalendarContainer';
import NewShiftContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ShiftContainer/NewShiftContainer/NewShiftContainer';
import EditPerDayShiftDetailContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ShiftContainer/EditPerDayShiftDetailContainer/EditPerDayShiftDetailContainer';
import ShiftContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ShiftContainer/ShiftContainer/ShiftContainer';
import SwapListContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/SwapListContainer/SwapListContainer';
import NewShiftSwapContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ShiftContainer/NewShiftSwapContainer/NewShiftSwapContainer';
import PreviewShiftSwapInfoContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ShiftContainer/PreviewShiftSwapInfoContainer/PreviewShiftSwapInfoContainer';
import OverrideAvailabilityRequestListContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/AvailabilityContainer/OverrideAvailabilityRequestListContainer/OverrideAvailabilityRequestListContainer';
import AvailabilityListContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/AvailabilityContainer/AvailabilityListContainer/AvailabilityListContainer';
import SingleAvailabilityListContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/AvailabilityContainer/SingleAvailabilityListContainer/SingleAvailabilityListContainer';
import teamChecklistLogContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/TeamManagementContainer/TeamChecklistLogContainer/teamChecklistLogContainer';
import NewCheckListManagerSummaryContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/TeamManagementContainer/ChecklistManagerContainer/NewChecListManager/NewChecklistManagerContainer';
import teamChecklistContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/TeamManagementContainer/TeamCheckistContainer/teamChecklistContainer';
import checklistManagerContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/TeamManagementContainer/ChecklistManagerContainer/checklistManagerContainer';
import checklistSupervisiorContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/TeamManagementContainer/ChecklistSupervisiorContainer/checklistSupervisiorContainer';
import trainingLogContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/TeamManagementContainer/TrainingLogContainer/trainingLogContainer';
import TrainingLogContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/TeamManagementContainer/TrainingLogContainer/newTrainingLog/newTrainingLogForm';
import NewChecklistSupervisiorContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/TeamManagementContainer/ChecklistSupervisiorContainer/NewCheckListSupervisor/NewCheckListSupervisorContainer';
import NewTeamChecklistFormContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/TeamManagementContainer/TeamCheckistContainer/NewTeamChecklistContainer/newTeamChecklistContainer';
import NewTeamChecklistLogFormContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/TeamManagementContainer/TeamChecklistLogContainer/NewTeamChecklistLogContainer.js/newTeamChecklistLogContainer';
import { round } from 'face-api.js/build/commonjs/utils';
import NewRestroomPorteringContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/LogBook/NewRestroomPortering/NewRestRoomPortering/NewRestroomPorteringCollection';
import restroomPorteringContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/LogBook/restroomPorteringContainer';
import NewBuildingProfileFormContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/BuildingProfileContainer/BuildingProfile/NewBuildingProfile/newBuildingProfileContainer';
import NewJobCardsContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/BuildingProfileContainer/JobCards/NewJobCards/NewJobCardsContainer';
import NewRequestFormContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/TagModuleContainer/RequestFormContainer/NewRequestForm/NewRequestFormContainer';
import projectModuleContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/ProjectModuleContainer/DeepCleanChart/projectModuleContainer';
import tagModuleContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/TagModuleContainer/tagModuleContainer';
import NewDailyKIttingPage from '../adminOrSuperAdminOrRequestor/component/FormAuditModule/Inventory/DailyKittingPage/NewDailyKittingPage/NewDailyKIttingPage';
import NewDailyKittingContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/Inventory/DailyKitting/NewDailyKittingContainer/NewDailyKittingContainer';
import JobCardsContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/BuildingProfileContainer/JobCards/JobCardsContainer';
import buildingProfileContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/BuildingProfileContainer/BuildingProfile/buildingProfileContainer';
import dailyKittingContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/Inventory/DailyKitting/dailyKittingContainer';
import monthlyUsageReportContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/Inventory/MonthlyUsageReport/monthlyUsageReportContainer';
import NewProjectModuleContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/ProjectModuleContainer/NewProjectModuleContainer/NewProjectModuleContainer';
import NewBulkstorageContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/Inventory/BulkStorage/NewBulkStorage/NewBulkstorageContainer';
import MonthlyCostSummaryAnalysisContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/Inventory/MonthlyCostSummaryAnalysis/monthlyCostAnalysisContainer';
import SelfAuditFormContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/AuditsContainer/SelfAuditContainer/SelfAuditForm/SelfAuditForm';
import performanceAuditPage from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/AuditsContainer/PerformanceAuditContainer/PerformanceAuditPage/performanceAuditPage';
import projectModuleListContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/ProjectModuleContainer/ProjectModuleListContainer/projectModuleListContainer';
import PreviewPMListContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/ProjectModuleContainer/PreviewPMListContainer/PreviewPMListContainer';
import singlePMTicketContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/ProjectModuleContainer/SinglePMTicketContainer/singlePMTicketContainer';
import SelfAuditContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/AuditsContainer/SelfAuditContainer/selfAuditContainer';
import ClockInClockOutScreenContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ClockInClockOutScreenContainer/ClockInClockOutScreenContainer';
import ClockOutFeedbackContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ClockInClockOutScreenContainer/ClockOutFeedbackContainer';
import ClkInClkOutShiftRecapContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ClockInClockOutScreenContainer/ClkInClkOutShiftRecapContainer/ClkInClkOutShiftRecapContainer';
import ScheduledTaskMyTicketContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/ScheduledTaskContainer/ScheduledTaskMyTicketContainer/ScheduledTaskMyTicketContainer';
import TimeCardCorrectionModuleContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/TimeCardCorrectionModule/TimeCardCorrectionModuleContainer';
import TimeCardReviewListContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/TimeCardReviewListContainer/TimeCardReviewListContainer';
import TimecardCorrectionReviewerContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/TimeCardCorrectionModule/TimecardCorrectionReviewerContainer';
import PreviewTimecardCorrectedContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/TimeCardCorrectionModule/PreviewTimecardCorrectedContainer';
import TimeSheetListScreenContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/TimeSheetListScreenContainer/TimeSheetListScreenContainer';
import AttendanceDashboardContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/AttendanceDashboardContainer/AttendanceDashboardContainer';
import MealPenaltyGenerateContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/MealPenaltyGenerateContainer/MealPenaltyGenerateContainer';
import performanceAuditContainer from '../adminOrSuperAdminOrRequestor/container/FormAuditModuleContainer/AuditsContainer/PerformanceAuditContainer/performanceAuditContainer';
import PegassurePlaybookListContainer from '../adminOrSuperAdminOrRequestor/container/PegassurePlaybookModuleContainer/PegassurePlaybookListContainer/PegassurePlaybookListContainer';
import PreviewPegassurePlaybookPdfContainer from '../adminOrSuperAdminOrRequestor/container/PegassurePlaybookModuleContainer/PreviewPegassurePlaybookPdfContainer/PreviewPegassurePlaybookPdfContainer';
import PegassurePlaybookAreaListContainer from '../adminOrSuperAdminOrRequestor/container/PegassurePlaybookModuleContainer/PegassurePlaybookAreaListContainer/PegassurePlaybookAreaListContainer';
import HoursTypeContainer from '../adminOrSuperAdminOrRequestor/container/TimeTrackerModuleContainer/HoursTypeContainer/HoursTypeContainer';
import PreviewEventContainer from '../adminOrSuperAdminOrRequestor/container/CompaniesContainer/BRMEventContainer/previewEventContainer';
import BrmScheduleContainer from '../adminOrSuperAdminOrRequestor/container/CompaniesContainer/BRMScheduleContainer/BrmScheduleContainer';
import StandardMatrixcontainer from '../adminOrSuperAdminOrRequestor/container/CompaniesContainer/StandardMatrixContainer/StandardMatrixcontainer';
import ClockInClockOutFaceRecognitionContainer from '../adminOrSuperAdminOrRequestor/container/Frontline/ClockInClockOutFaceRecognition/ClockInClockOutFaceRecognitionContainer';

const route = (props) => {
    const inventoryAccessRole = storage.get('InventoryAccessRole');
    const user = storage.get('user')
    const formAccess = user?.form_access_actions
    if (props.token) {
        if (props.user.role === userRoles.SUPER_ADMIN) {
            let commonRoutes;
            const externalRoute = storage.get('externalRoute')

            if (externalRoute) {
                storage.remove('externalRoute')
                commonRoutes = <Switch> <Route path='*' render={(props) => <Redirect to={externalRoute} />} /> </Switch>
            } else {
                commonRoutes = <Switch>
                    <Route path="/reload" component={null} key="reload" />
                    <Route exact path={routes.PREVIEW_USER} component={PreviewUser} />
                    <Route exact path={routes.PREVIEW_LOGBOOK_LOG_AREA} component={PreviewLogBookLogsArea} />
                    <Route exact path={routes.LOGBOOK} component={LogBookAreaContainer} />
                    <Route exact path={[routes.LOGBOOK_FEEDBACK, routes.UPDATE_LOGBOOK_FEEDBACK]} component={LogBookFeedbackContainer} />
                    <Route exact path={routes.LOGBOOK_THANKYOU} component={LogBookThankYou} />
                    <Route exact path={routes.SCAN_LOGBOOK_QR} component={LogBookQrReader} />
                    <Route path={routes.CHI_REVIEW} component={CHIReviewContianer} />
                    <Route exact path={[routes.NEW_USER, routes.EDIT_USER, routes.NEW_COMPANY_USER, routes.NEW_ACCOUNT_USER]} component={NewUser} />
                    <Route exact path={routes.NEW_USER} component={NewUser} />
                    <Route exact path={[routes.TICKETS, routes.VIEW_FILTERED_TICKETS]} component={TicketContainer} />
                    <Route exact path={[routes.PRIORITY_ESCALATION_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={PriorityEscTicketContainer} />
                    <Route exact path={[routes.MY_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={MyTicketContainer} />
                    <Route exact path={[routes.SCHEDULED_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={ScheduledTicketContainer} />
                    <Route exact path={[routes.PREVIEW_SCHEDULED_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={previewScheduledTicketContainer} />
                    <Route exact path={routes.REPORTS} component={ReportsContainer} />
                    <Route exact path={[routes.NEW_TICKET, routes.EDIT_TICKET]} component={NewTicketContainer} />
                    <Route exact path={routes.VIEW_TICKET} component={PreviewTicket} />
                    <Route exact path={routes.PREVIEW_SINGLE_TICKET} component={PreviewSingleTicket} />
                    <Route exact path={routes.VIEW_PRIORITY_ESCALATION_TICKET} component={PriorPreviewTicket} />
                    <Route exact path={[routes.INSPECTIONS, routes.VIEW_FILTERED_INSPECTIONS]} component={Inspections} />
                    <Route exact path={[routes.MY_INSPECTIONS]} component={MyInspections} />
                    <Route exact path={[routes.PENDING_INSPECTIONS]} component={PendingInspections} />
                    <Route exact path={routes.PREVIEW_PENDING_INSPECTIONS} component={PendingPreviewInspection} />
                    <Route exact path={[routes.PERFORM_INSPECTION, routes.EDIT_INSPECTION]} component={PerformInspection} />
                    <Route exact path={routes.PREVIEW_INSPECTION} component={PreviewInspection} />
                    <Route exact path={routes.INSPECTION_FORMS} component={InspectionFormContainer} />
                    <Route exact path={[routes.NEW_INSPECTION_FORMS, routes.EDIT_INSPECTION_FORM]} component={NewInspectionFormContainer} />
                    <Route exact path={routes.RATINGS} component={RatingsContainer} />
                    <Route exact path={[routes.NEW_RATING, routes.EDIT_RATING]} component={NewRatingsContaier} />
                    <Route exact path={routes.COMPANY_USERS} component={CompanyUsersContainer} />
                    <Route exact path={[routes.NEW_COMPANY, routes.EDIT_COMPANY]} component={NewCompany} />
                    <Route exact path={routes.COMPANIES} component={Companies} />
                    <Route exact path={routes.KPI_DASHBOARD} component={KpiTabs} />
                    <Route exact path={routes.QUOTE_APPROVED} component={ApproveQuote} />
                    <Route exact path={[routes.NEW_QUOTES, routes.MANUALL_QUOTE, routes.EDIT_QUOTES]} component={NewQuotes} />
                    {/* <Route exact path={routes.MANUALL_QUOTE} component={New} /> */}
                    <Route exact path={[routes.QUOTES, routes.VIEW_FILTERED_QUOTES]} component={Quotes} />
                    <Route exact path={routes.ACCOUNT_USERS} component={AccountUsersContainer} />
                    <Route exact path={[routes.NEW_ACCOUNTS, routes.EDIT_ACCOUNT]} component={NewAccounts} />
                    <Route exact path={routes.ACCOUNTS} component={Accounts} />
                    <Route exact path={routes.DASHBORD} component={AdminDashbord} />
                    <Route exact path={routes.USER} component={UserContainer} />
                    <Route path={routes.NEW_POSITIONS_AND_REGIONS} component={Settings} />
                    <Route path={routes.CATEGORIES} component={CategoryContainer} />
                    <Route exact path={routes.CHI_SERVYS} component={CHIServysContainer} />
                    <Route exact path={[routes.SETTINGS_PREFERENCES, routes.USER_EMAIL_PREFERENCES]} component={SettingsPreferences} />
                    <Route exact path={routes.ADD_AREA} component={AddAreaContainer} />
                    <Route exact path={routes.ADD_LOGBOOK_AREA} component={AddLogBookAreaContainer} />
                    <Route exact path={routes.EDIT_LOGBOOK_AREA} component={EditLogBookAreaContainer} />
                    <Route exact path={routes.PREVIEW_LOGBOOK_AREA} component={PreviewContainerLogBookArea} />
                    <Route exact path={routes.EDIT_AREA} component={EditAreaContainer} />
                    <Route exact path={routes.SEARCH_HISTORY} component={SearchHistory} />
                    <Route exact path={routes.SEARCH_HISTORY_NEW} component={NewSearchHistory} />
                    <Route exact path={routes.SEARCH_LOGBOOK_HISTORY} component={SearchLogbookHistoryContainer} />
                    <Route exact path={routes.SCAN_SEARCH_RESULT} component={ScanSearchResult} />
                    <Route exact path={routes.SCAN_SEARCH_SCHEDULE} component={SchedulePage} />
                    <Route path={routes.THANKYOU} component={Thankyou} />
                    <Route exact path={routes.PEGASSURE} component={Pegassure} />
                    <Route exact path={routes.PREVIEW_CHI} component={CHIPreviewContainer} />
                    <Route exact path={routes.CRITERIA} component={CriteriaContainer} />
                    <Route exact path={routes.SCHEDULE_TIME} component={AccountsCalenderTableContainer} />
                    <Route exact path={routes.CREATE_NEW_SCHEDULE} component={AccountNewSchuleContainer} />
                    <Route exact path={routes.CREATE_NEW_SCHEDULE_FROM_SCHEDULE} component={AccountNewSchuleContainer} />
                    <Route exact path={routes.SCHEDULE_TAB} component={SchedulesContainer} />
                    <Route exact path={routes.SCHEDULE_TIME_PREVIEW} component={AccountsCalenderTableContainer} />
                    <Route exact path={[routes.INSPECTION_SCHEDULE_HISTORY, routes.ALL_INSPECTION_SCHEDULE_HISTORY]} component={InspectionScheduleHistoryContainer} />
                    <Route exact path={routes.FEEDBACK_TABLE} component={PegAssureContainer} />
                    <Route exact path={routes.PREVIEW_FEEDBACK} component={PreviewFeedbackContainer} />
                    <Route exact path={routes.CREATE_NEW_TICKET_SCHEDULE} component={AccountNewTicketScheduleContainer} />
                    <Route exact path={routes.CREATE_NEW_TICKET_SCHEDULE_FROM_SCHEDULE} component={AccountNewTicketScheduleContainer} />
                    <Route exact path={[routes.TICKET_SCHEDULE_HISTORY, routes.ALL_TICKET_SCHEDULE_HISTORY]} component={TicketScheduleHistoryContainer} />
                    <Route exact path={routes.NEW_VERION} component={NewVersionContainer} />
                    <Route exact path={routes.API_ACCESS} component={APIAccessContainer} />
                    <Route exact path={routes.INVENTORY_MAIN_LIST} component={InventoryListContainer} />
                    <Route exact path={[routes.NEW_INVENTORY_ITEM, routes.EDIT_INVENTORY_ITEM]} component={NewInventoryItemContainer} />
                    <Route exact path={routes.PREVIEW_INVENTORY_ITEM} component={previewInventoryContainer} />
                    <Route exact path={routes.BOX} component={boxContainer} />
                    <Route exact path={[routes.ADD_BOX, routes.EDIT_BOX]} component={newBoxContainer} />
                    <Route exact path={routes.ASSETS_LIST} component={assetsListContainer} />
                    <Route exact path={routes.ASSETS_PREVIEW} component={assetsPreviewContainer} />
                    <Route exact path={[routes.NEW_ASSET, routes.EDIT_ASSET]} component={NewAssetsContainer} />
                    <Route exact path={routes.UNAVAILABLE_ITEMS} component={UnavailableItem} />
                    <Route exact path={routes.ASSETS_STOCK} component={assetStockContainer} />
                    <Route exact path={[routes.ADD_ASSET_STOCK, routes.UPDATE_ASSET_STOCK]} component={newAssetStockContainer} />
                    <Route exact
                        path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_SCAN_CHECK_OUT_QR : '/'}
                        component={AssetCheckOutQRScan}
                    />
                    <Route exact
                        path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_SCAN_CHECK_IN_QR : '/'}
                        component={AssetCheckInQRScan}
                    />
                    <Route exact
                        path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_CHECK_OUT_FORM : '/'}
                        component={AssetCheckInFormContainer}
                    />
                    <Route exact
                        path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_CHECK_IN_FORM : '/'}
                        component={AssetCheckOutFormContainer}
                    />
                    <Route exact path={routes.LOCATION} component={LocationContainer} />
                    <Route exact
                        path={
                            (inventoryAccessRole == 'manager') || (inventoryAccessRole == 'fulfillmentManager') ||
                                (inventoryAccessRole == 'approver') ?
                                [routes.PICKING_TICKETS, routes.PICKING_TICKETS_WITH_FILTERED_ID]
                                : '/'
                        }
                        component={PickingTicketContainer}
                    />
                    <Route exact
                        path={
                            (inventoryAccessRole == 'manager') || (inventoryAccessRole == 'fulfillmentManager') ||
                                (inventoryAccessRole == 'approver') ?
                                routes.FULFILLMENT_MANAGER : '/'
                        }
                        component={FulfillmentManagerFormContainer}
                    />
                    <Route exact
                        path={
                            (inventoryAccessRole == 'manager') || (inventoryAccessRole == 'fulfillmentManager') ||
                                (inventoryAccessRole == 'approver') ?
                                routes.INVENTORY_SCAN_READY_FOR_PICKUP_QR : '/'
                        }
                        component={ReadyForPickupQRScan}
                    />
                    <Route exact
                        path={
                            (inventoryAccessRole == 'approver') ?
                                [routes.EMERGENCY_PICKING_TICKETS, routes.EMERGENCY_PICKING_TICKETS_WITH_FILTERED_ID]
                                : '/'
                        }
                        component={emergencyPickupContainer}
                    />
                    <Route exact path={routes.AUDIT_LOGS} component={auditLogsContainer} />
                    <Route exact
                        path={(inventoryAccessRole == 'manager') ? routes.NEW_INVENTORY_PULL_REQUEST_TICKET : '/'}
                        component={InventoryPullRequestContainer}
                    />
                    <Route exact
                        path={(inventoryAccessRole == 'supervisor') ? routes.PICKUPS_TICKETS : '/'}
                        component={PickupsTicketContainer}
                    />
                    <Route exact
                        path={(inventoryAccessRole == 'supervisor') ? routes.PICKUP_DETAILS : '/'}
                        component={PickupDetailsFormContainer}
                    />
                    <Route exact
                        path={(inventoryAccessRole == 'supervisor') ? routes.INVENTORY_SCAN_READY_FOR_DELIVERY_QR : '/'}
                        component={ReadyForDeliveryQRScan}
                    />
                    <Route exact
                        path={(inventoryAccessRole == 'supervisor') ? routes.INVENTORY_SCAN_FOR_PICKED_DELIVERY_QR : '/'}
                        component={ForPickedDeliveryQRScan}
                    />
                    <Route exact
                        path={(inventoryAccessRole == 'supervisor') ? routes.SCAN_FOR_DELIVERY : '/'}
                        component={ScanForDeliveryFormContainer}
                    />
                    <Route exact
                        path={(inventoryAccessRole == 'supervisor') ? routes.LISTED_ITEMS_COMING_BACK : '/'}
                        component={ListedItemsComingBackFormContainer}
                    />
                    <Route exact
                        path={(inventoryAccessRole == 'supervisor') ? routes.SCAN_FOR_DELIVERY_RETURN : '/'}
                        component={DeliveryReturnWarehouseQRScan}
                    />
                    <Route exact
                        path={(inventoryAccessRole == 'supervisor') ? routes.INVENTORY_THANKYOU : '/'}
                        component={InventoryThankYou}
                    />
                    {/* PAYROLL FLOW STARTS HERE */}
                    <Route exact path={routes.SKILLSET} component={SkillSetContainer} />
                    <Route exact path={routes.HOURSTYPE} component={HoursTypeContainer} />
                    <Route exact path={routes.DEPARTMENT} component={DepartmentContainer} />
                    <Route exact path={routes.LEAVE_LIST} component={LeaveListContainer} />
                    <Route exact path={routes.PREVIEW_LEAVE_REQUEST} component={PreviewLeaveRequestContainer} />
                    <Route exact path={routes.SCHEDULED_TASKS} component={ScheduledTaskContainer} />
                    <Route exact path={routes.SUB_SCHEDULED_TASK} component={SubScheduledTaskContainer} />
                    <Route exact path={routes.PREVIEW_SUB_SCHEDULED_TASK} component={PreviewSubScheduledTaskContainer} />
                    <Route exact path={routes.NEW_SCHEDULED_TASK} component={NewScheduledTaskContainer} />
                    <Route exact path={routes.ADD_AVAILABILITY} component={addAvailabilityContainer} />
                    <Route exact path={routes.OVERRIDE_AVAILABILITY} component={overrideAvailabilityContainer} />
                    <Route exact path={routes.CALENDAR_AVAILABILITY} component={AvailabilityCalendarContainer} />
                    <Route exact path={routes.SHIFT_SCHEDULE} component={ShiftScheduleContainer} />
                    <Route exact path={routes.SHIFT_SCHEDULE_DETAIL} component={ShiftScheduleDetailContainer} />
                    <Route exact path={routes.PREVIEW_SHIFT_SCHEDULE} component={PreviewShiftScheduleContainer} />
                    <Route exact path={routes.CALENDAR_SHIFT} component={ShiftCalendarContainer} />
                    <Route exact path={[routes.FLUSER_NEW_SHIFT, routes.FLUSER_EDIT_SHIFT]} component={NewShiftContainer} />
                    <Route exact path={routes.FLUSER_EDIT_PERDAY_SHIFT_DETAIL} component={EditPerDayShiftDetailContainer} />
                    <Route exact path={routes.SWAP_LIST} component={SwapListContainer} />
                    <Route exact path={[routes.PREVIEW_SHIFT_SWAP_REQUEST_INFO, routes.PREVIEW_SHIFT_SWAPPED_SPECIFICS]} component={PreviewShiftSwapInfoContainer} />
                    <Route exact path={routes.OVERRIDE_AVAILABILITY_REQUEST_LIST} component={OverrideAvailabilityRequestListContainer} />
                    <Route exact path={routes.AVAILABILITY_LIST} component={AvailabilityListContainer} />
                    <Route exact path={routes.SINGLE_AVAILABILITY_LIST} component={SingleAvailabilityListContainer} />
                    <Route exact path={routes.TIMECARD_CORRECTION_MODULE_V1} component={TimeCardCorrectionModuleContainer} />
                    <Route exact path={[routes.TIMECARD_CORRECTION_MODULE_V2, routes.PREVIEW_TIMECARD_CORRECTION]} component={TimecardCorrectionReviewerContainer} />
                    <Route exact path={routes.TIMECARD_REVIEWED_LIST} component={TimeCardReviewListContainer} />
                    <Route exact path={routes.TIME_SHEET_LIST} component={TimeSheetListScreenContainer} />
                    <Route exact path={routes.ATTENDANCE_DASHBOARD} component={AttendanceDashboardContainer} />
                    {/* PAYROLL FLOW ENDS HERE */}
                    {/* Form Audits Routes */}
                    {/* Team Management Module */}
                    <Route exact path={formAccess?.team_management?.checklist_log?.view ? routes.TEAM_CHECKLIST_LOG : '/'} component={teamChecklistLogContainer} />
                    <Route exact path={formAccess?.team_management?.checklist_log?.create ? [routes.NEW_TEAM_CHECKLIST_LOG, routes.EDIT_TEAM_CHECKLIST_LOG] : '/'} component={NewTeamChecklistLogFormContainer} />
                    <Route exact path={formAccess?.team_management?.checklist_log?.view ? routes.VIEW_TEAM_CHECKLIST_LOG : '/'} component={NewTeamChecklistLogFormContainer} />

                    <Route exact path={formAccess?.team_management?.checklist?.view ? routes.TEAM_CHECKLIST : '/'} component={teamChecklistContainer} />
                    <Route exact path={formAccess?.team_management?.checklist?.create ? [routes.NEW_TEAM_CHECKLIST, routes.EDIT_TEAM_CHECKLIST] : '/'} component={NewTeamChecklistFormContainer} />
                    <Route exact path={formAccess?.team_management?.checklist?.view ? routes.VIEW_TEAM_CHECKLIST : '/'} component={NewTeamChecklistFormContainer} />

                    <Route exact path={formAccess?.team_management?.checklist_manager?.view ? routes.TEAM_CHECKLIST_MANAGER_SUMMARY : '/'} component={checklistManagerContainer} />
                    <Route exact path={formAccess?.team_management?.checklist_manager?.create ? [routes.TEAM_CHECKLIST_MANAGER_SUMMARY_NEW, routes, routes.TEAM_CHECKLIST_MANAGER_SUMMARY_EDIT] : '/'} component={NewCheckListManagerSummaryContainer} />
                    <Route exact path={formAccess?.team_management?.checklist_manager?.view ? routes.TEAM_CHECKLIST_MANAGER_SUMMARY_VIEW : '/'} component={NewCheckListManagerSummaryContainer} />

                    <Route exact path={formAccess?.team_management?.checklist_supervisor_summary?.view ? routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY : '/'} component={checklistSupervisiorContainer} />
                    <Route exact path={formAccess?.team_management?.checklist_supervisor_summary?.create ? [routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_NEW, routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_EDIT] : '/'} component={NewChecklistSupervisiorContainer} />
                    <Route exact path={formAccess?.team_management?.checklist_supervisor_summary?.view ? routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_VIEW : '/'} component={NewChecklistSupervisiorContainer} />

                    <Route exact path={formAccess?.team_management?.training_log?.view ? routes.TRAINING_LOG : '/'} component={trainingLogContainer} />
                    <Route exact path={formAccess?.team_management?.training_log?.create ? [routes.TRAINING_LOG_NEW, routes.TRAINING_LOG_EDIT] : '/'} component={TrainingLogContainer} />
                    <Route exact path={formAccess?.team_management?.training_log?.view ? routes.TRAINING_LOG_VIEW : '/'} component={TrainingLogContainer} />
                    {/* Logbook Module */}
                    <Route exact path={formAccess?.logbook?.porter_log?.create ? [routes.NEW_LOG_RESTROOM_PORTERING, routes.EDIT_RESTROOM_PORTERING] : '/'} component={NewRestroomPorteringContainer} />
                    <Route exact path={formAccess?.logbook?.porter_log?.view ? routes.VIEW_RESTROOM_PORTERING : '/'} component={NewRestroomPorteringContainer} />
                    <Route exact path={formAccess?.logbook?.porter_log?.view ? routes.RESTROOM_PORTERING : '/'} component={restroomPorteringContainer} />
                    {/* Tag Module */}
                    <Route exact path={formAccess?.tag?.request_form?.create ? [routes.NEW_REQUSET_FORM, routes.EDIT_REQUSET_FORM] : '/'} component={NewRequestFormContainer} />
                    <Route exact path={formAccess?.tag?.request_form?.view ? routes.VIEW_REQUSET_FORM : '/'} component={NewRequestFormContainer} />
                    <Route exact path={formAccess?.tag?.request_form?.view ? routes.REQUSET_FORM : '/'} component={tagModuleContainer} />
                    {/* Project Module */}
                    <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.DEEP_CLEAN_CHART : '/'} component={projectModuleContainer} />
                    <Route exact path={formAccess?.projects?.deep_clean_chart?.create ? [routes.NEW_PROJECT_MODULE] : '/'} component={NewProjectModuleContainer} />
                    <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.PROJECT_MODULE : '/'} component={projectModuleListContainer} />
                    <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.VIEW_PROJECT_MODULE : '/'} component={PreviewPMListContainer} />
                    <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.PREVIEW_SINGLE_PROJECT_MODULE : '/'} component={singlePMTicketContainer} />
                    {/* Building Profile */}
                    <Route exact path={formAccess?.building_profiles?.building_profile?.create ? [routes.NEW_BUILDING_PROFILE, routes.EDIT_BUILDING_PROFILE] : '/'} component={NewBuildingProfileFormContainer} />
                    <Route exact path={formAccess?.building_profiles?.building_profile?.view ? [routes.VIEW_BUILDING_PROFILE] : '/'} component={NewBuildingProfileFormContainer} />
                    <Route exact path={formAccess?.building_profiles?.job_cards?.create ? [routes.NEW_JOB_CORE_MAP_CARD, routes.EDIT_JOB_CORE_MAP_CARD] : '/'} component={NewJobCardsContainer} />
                    <Route exact path={formAccess?.building_profiles?.job_cards?.view ? [routes.VIEW_JOB_CORE_MAP_CARD] : '/'} component={NewJobCardsContainer} />
                    <Route exact path={formAccess?.building_profiles?.job_cards?.view ? routes.JOB_CORE_MAP_CARD : '/'} component={JobCardsContainer} />
                    <Route exact path={formAccess?.building_profiles?.building_profile?.view ? routes.BUILDING_PROFILE : '/'} component={buildingProfileContainer} />
                    {/* Inventory Module */}
                    <Route exact path={formAccess?.inventory?.daily_kitting_safety?.create ? [routes.NEW_DAILY_KITTING, routes.EDIT_DAILY_KITTING] : '/'} component={NewDailyKittingContainer} />
                    <Route exact path={formAccess?.inventory?.daily_kitting_safety.view ? [routes.VIEW_DAILY_KITTING] : '/'} component={NewDailyKittingContainer} />
                    <Route exact path={formAccess?.inventory?.daily_kitting_safety.view ? routes.DAILY_KITTING : '/'} component={dailyKittingContainer} />
                    <Route exact path={formAccess?.inventory?.monthly_usage_report.view ? routes.MONTHLY_USAGE_REPORT : '/'} component={monthlyUsageReportContainer} />
                    <Route exact path={formAccess?.inventory?.monthly_cost_summary.view ? [routes.MONTHLY_COST_SUMMARY_ANALYSIS] : '/'} component={MonthlyCostSummaryAnalysisContainer} />
                    {/* Audits */}
                    <Route exact path={formAccess?.audit?.self_audit?.create ? [routes.NEW_SELF_AUDIT, routes.EDIT_SELF_AUDIT] : '/'} component={SelfAuditFormContainer} />
                    <Route exact path={formAccess?.audit?.self_audit?.view ? [routes.VIEW_SELF_AUDIT] : '/'} component={SelfAuditFormContainer} />
                    <Route exact path={formAccess?.audit?.self_audit?.view ? routes.SELF_AUDIT : '/'} component={SelfAuditContainer} />
                    <Route exact path={formAccess?.audit?.performance_audit?.create ? [routes.NEW_PERFORMANCE_AUDIT, routes.EDIT_PERFORMANCE_AUDIT] : '/'} component={performanceAuditPage} />
                    <Route exact path={formAccess?.audit?.performance_audit?.view ? [routes.VIEW_PERFORMANCE_AUDIT] : '/'} component={performanceAuditPage} />
                    <Route exact path={formAccess?.audit?.performance_audit?.view ? routes.PERFORMANCE_AUDIT : '/'} component={performanceAuditContainer} />

                    <Route exact
                        path={formAccess?.inventory?.bulk_storage_supplies_usage?.create ? routes.NEW_INVENTORY_PULL_REQUEST_TICKET : '/'}
                        component={InventoryPullRequestContainer}
                    />
                    <Route exact path={routes.PICKING_TICKETS} component={PickingTicketContainer} />
                    {/* BRM */}
                    <Route exact path={[routes.BRM_Schedule]} component={BrmScheduleContainer} />
                     {/* StandardMatrixcontainer */}
                     <Route exact path={routes.Standard_Matrix} component={StandardMatrixcontainer} />
                    {/* Pegassure Playbook */}
                    <Route exact path={routes.PEGASSURE_PLAYBOOK_LIST} component={PegassurePlaybookListContainer} />
                    <Route exact path={routes.PREVIEW_PEGASSURE_PLAYBOOK_PDF} component={PreviewPegassurePlaybookPdfContainer} />
                    <Route exact path={routes.PREVIEW_PLAYBOOK_LOGAREA_GRID} component={PegassurePlaybookAreaListContainer} />
                    <Route exact path={routes.PREVIEW_EVENTS} component={PreviewEventContainer} />
                    <Route path='*' render={(props) => <Redirect to={routes.DASHBORD} />} />

                </Switch>
            }
            if (props.user.selected_mode && props.user.selected_mode === 'internal') {
                return (
                    <Switch>
                        <Route exact path={routes.HOME} render={(props) => <Redirect to={routes.DASHBORD} />} />
                        {commonRoutes}
                        <Route path='*' render={(props) => <Redirect to={routes.DASHBORD} />} />
                        <Route path={routes.THANKYOU} component={Thankyou} />
                    </Switch>
                )
            } else {
                return (
                    <Switch>
                        <Route exact path={routes.HOME} render={(props) => <Redirect to={routes.DASHBORD} />} />
                        <Route exact path={routes.FRONTLINE_LIST} component={FrontlineList} />
                        <Route exact path={routes.ADD_FRONTLINE} component={AddFrontline} />
                        <Route exact path={routes.EDIT_FRONTLINE} component={EditFrontline} />
                        <Route path={routes.THANKYOU} component={Thankyou} />
                        <Route exact path={routes.INSIGHTS} component={Insights} />
                        {commonRoutes}
                        <Route path='*' render={(props) => <Redirect to={routes.DASHBORD} />} />
                    </Switch>
                )
            }
        } else if (props.user.role === userRoles.ADMIN) {
            const externalRoute = storage.get('externalRoute')
            if (externalRoute) {
                storage.remove('externalRoute')
                return <Switch> <Route path='*' render={(props) => <Redirect to={externalRoute} />} /> </Switch>
            } else {
                return (
                    <Switch>
                        <Route path="/reload" component={null} key="reload" />
                        <Route exact path={routes.PREVIEW_SINGLE_TICKET} component={PreviewSingleTicket} />
                        <Route exact path={routes.LOGBOOK} component={LogBookAreaContainer} />
                        <Route exact path={routes.PREVIEW_LOGBOOK_LOG_AREA} component={PreviewLogBookLogsArea} />
                        <Route exact path={routes.HOME} render={(props) => <Redirect to={routes.DASHBORD} />} />
                        <Route path={routes.CHI_REVIEW} component={CHIReviewContianer} />
                        <Route exact path={routes.CHI_SERVYS} component={CHIServysContainer} />
                        <Route exact path={routes.PREVIEW_CHI} component={CHIPreviewContainer} />
                        <Route exact path={[routes.TICKETS, routes.VIEW_FILTERED_TICKETS]} component={TicketContainer} />
                        <Route exact path={[routes.PRIORITY_ESCALATION_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={PriorityEscTicketContainer} />
                        <Route exact path={[routes.MY_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={MyTicketContainer} />
                        <Route exact path={[routes.SCHEDULED_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={ScheduledTicketContainer} />
                        <Route exact path={[routes.PREVIEW_SCHEDULED_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={previewScheduledTicketContainer} />
                        <Route exact path={[routes.NEW_TICKET, routes.EDIT_TICKET]} component={NewTicketContainer} />
                        <Route exact path={routes.VIEW_TICKET} component={PreviewTicket} />
                        <Route exact path={routes.LOGBOOK} component={LogBookAreaContainer} />
                        <Route exact path={[routes.LOGBOOK_FEEDBACK, routes.UPDATE_LOGBOOK_FEEDBACK]} component={LogBookFeedbackContainer} />
                        <Route exact path={routes.LOGBOOK_THANKYOU} component={LogBookThankYou} />
                        <Route exact path={routes.PEGASSURE_LOGBOOK} component={PegassureLogbookContainer} />
                        <Route exact path={routes.SCAN_LOGBOOK_QR} component={LogBookQrReader} />
                        <Route exact path={routes.SCAN_QR} component={ScanQr} />
                        <Route exact path={routes.VIEW_PRIORITY_ESCALATION_TICKET} component={PriorPreviewTicket} />
                        <Route exact path={[routes.INSPECTIONS, routes.VIEW_FILTERED_INSPECTIONS]} component={Inspections} />
                        <Route exact path={[routes.MY_INSPECTIONS]} component={MyInspections} />
                        <Route exact path={[routes.PENDING_INSPECTIONS]} component={PendingInspections} />
                        <Route exact path={routes.PREVIEW_PENDING_INSPECTIONS} component={PendingPreviewInspection} />
                        <Route exact path={[routes.PERFORM_INSPECTION, routes.EDIT_INSPECTION]} component={PerformInspection} />
                        <Route exact path={routes.PREVIEW_INSPECTION} component={PreviewInspection} />
                        <Route exact path={routes.INSPECTION_FORMS} component={InspectionFormContainer} />
                        <Route exact path={[routes.NEW_INSPECTION_FORMS, routes.EDIT_INSPECTION_FORM]} component={NewInspectionFormContainer} />
                        <Route exact path={routes.RATINGS} component={RatingsContainer} />
                        <Route exact path={[routes.NEW_RATING, routes.EDIT_RATING]} component={NewRatingsContaier} />
                        <Route exact path={routes.COMPANY_USERS} component={CompanyUsersContainer} />
                        {/* <Route exact path={[routes.NEW_COMPANY, routes.EDIT_COMPANY]} component={NewCompany} /> */}
                        <Route exact path={routes.COMPANIES} component={Companies} />
                        <Route exact path={routes.QUOTE_APPROVED} component={ApproveQuote} />
                        <Route exact path={[routes.NEW_QUOTES, routes.MANUALL_QUOTE, routes.EDIT_QUOTES]} component={NewQuotes} />
                        <Route exact path={[routes.QUOTES, routes.VIEW_FILTERED_QUOTES]} component={Quotes} />
                        <Route exact path={routes.ACCOUNT_USERS} component={AccountUsersContainer} />
                        {/* <Route exact path={[routes.NEW_ACCOUNTS, routes.EDIT_ACCOUNT]} component={NewAccounts} /> */}
                        <Route exact path={routes.ACCOUNTS} component={Accounts} />
                        <Route exact path={routes.DASHBORD} component={AdminDashbord} />
                        <Route exact path={routes.SETTINGS_PREFERENCES} component={SettingsPreferences} />
                        <Route exact path={routes.ADD_AREA} component={AddAreaContainer} />
                        <Route exact path={routes.ADD_LOGBOOK_AREA} component={AddLogBookAreaContainer} />
                        <Route exact path={routes.EDIT_LOGBOOK_AREA} component={EditLogBookAreaContainer} />
                        <Route exact path={routes.PREVIEW_LOGBOOK_AREA} component={PreviewContainerLogBookArea} />
                        <Route exact path={routes.EDIT_AREA} component={EditAreaContainer} />
                        <Route path={routes.THANKYOU} component={Thankyou} />
                        <Route exact path={routes.SEARCH_HISTORY} component={SearchHistory} />
                        <Route exact path={routes.SEARCH_HISTORY_NEW} component={NewSearchHistory} />
                        <Route exact path={routes.SEARCH_LOGBOOK_HISTORY} component={SearchLogbookHistoryContainer} />
                        <Route exact path={routes.SCAN_SEARCH_RESULT} component={ScanSearchResult} />
                        <Route exact path={routes.SCAN_SEARCH_SCHEDULE} component={SchedulePage} />
                        <Route exact path={routes.KPI_DASHBOARD} component={KpiTabs} />
                        <Route exact path={routes.SCHEDULE_TIME} component={AccountsCalenderTableContainer} />
                        <Route exact path={routes.CREATE_NEW_SCHEDULE} component={AccountNewSchuleContainer} />
                        <Route exact path={routes.CREATE_NEW_SCHEDULE_FROM_SCHEDULE} component={AccountNewSchuleContainer} />
                        <Route exact path={routes.SCHEDULE_TAB} component={SchedulesContainer} />
                        <Route exact path={routes.SCHEDULE_TIME_PREVIEW} component={AccountsCalenderTableContainer} />
                        <Route exact path={[routes.INSPECTION_SCHEDULE_HISTORY, routes.ALL_INSPECTION_SCHEDULE_HISTORY]} component={InspectionScheduleHistoryContainer} />
                        <Route exact path={routes.FEEDBACK_TABLE} component={PegAssureContainer} />
                        <Route exact path={routes.PREVIEW_FEEDBACK} component={PreviewFeedbackContainer} />
                        <Route exact path={routes.CREATE_NEW_TICKET_SCHEDULE} component={AccountNewTicketScheduleContainer} />
                        <Route exact path={routes.CREATE_NEW_TICKET_SCHEDULE_FROM_SCHEDULE} component={AccountNewTicketScheduleContainer} />
                        <Route exact path={[routes.TICKET_SCHEDULE_HISTORY, routes.ALL_TICKET_SCHEDULE_HISTORY]} component={TicketScheduleHistoryContainer} />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.INVENTORY_MAIN_LIST : '/'}
                            component={InventoryListContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? [routes.NEW_INVENTORY_ITEM, routes.EDIT_INVENTORY_ITEM] : '/'}
                            component={NewInventoryItemContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.PREVIEW_INVENTORY_ITEM : '/'}
                            component={previewInventoryContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.BOX : '/'}
                            component={boxContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? [routes.ADD_BOX, routes.EDIT_BOX] : '/'}
                            component={newBoxContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.ASSETS_LIST : '/'}
                            component={assetsListContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.ASSETS_PREVIEW : '/'}
                            component={assetsPreviewContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? [routes.NEW_ASSET, routes.EDIT_ASSET] : '/'}
                            component={NewAssetsContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.UNAVAILABLE_ITEMS : '/'}
                            component={UnavailableItem}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.ASSETS_STOCK : '/'}
                            component={assetStockContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? [routes.ADD_ASSET_STOCK, routes.UPDATE_ASSET_STOCK] : '/'}
                            component={newAssetStockContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_SCAN_CHECK_OUT_QR : '/'}
                            component={AssetCheckOutQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_SCAN_CHECK_IN_QR : '/'}
                            component={AssetCheckInQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_CHECK_OUT_FORM : '/'}
                            component={AssetCheckInFormContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_CHECK_IN_FORM : '/'}
                            component={AssetCheckOutFormContainer}
                        />
                        <Route exact
                            path={
                                (inventoryAccessRole == 'manager') || (inventoryAccessRole == 'fulfillmentManager') ||
                                    (inventoryAccessRole == 'approver') ?
                                    [routes.PICKING_TICKETS, routes.PICKING_TICKETS_WITH_FILTERED_ID]
                                    : '/'
                            }
                            component={PickingTicketContainer}
                        />
                        <Route exact
                            path={
                                (inventoryAccessRole == 'manager') || (inventoryAccessRole == 'fulfillmentManager') ||
                                    (inventoryAccessRole == 'approver') ?
                                    routes.FULFILLMENT_MANAGER : '/'
                            }
                            component={FulfillmentManagerFormContainer}
                        />
                        <Route exact
                            path={
                                (inventoryAccessRole == 'manager') || (inventoryAccessRole == 'fulfillmentManager') ||
                                    (inventoryAccessRole == 'approver') ?
                                    routes.INVENTORY_SCAN_READY_FOR_PICKUP_QR : '/'
                            }
                            component={ReadyForPickupQRScan}
                        />
                        <Route exact
                            path={
                                (inventoryAccessRole == 'approver') ?
                                    [routes.EMERGENCY_PICKING_TICKETS, routes.EMERGENCY_PICKING_TICKETS_WITH_FILTERED_ID]
                                    : '/'
                            }
                            component={emergencyPickupContainer}
                        />
                        <Route exact path={routes.AUDIT_LOGS} component={auditLogsContainer} />
                        <Route exact
                            path={(inventoryAccessRole == 'manager') ? routes.NEW_INVENTORY_PULL_REQUEST_TICKET : '/'}
                            component={InventoryPullRequestContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.PICKUPS_TICKETS : '/'}
                            component={PickupsTicketContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.PICKUP_DETAILS : '/'}
                            component={PickupDetailsFormContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.INVENTORY_SCAN_READY_FOR_DELIVERY_QR : '/'}
                            component={ReadyForDeliveryQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.INVENTORY_SCAN_FOR_PICKED_DELIVERY_QR : '/'}
                            component={ForPickedDeliveryQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.SCAN_FOR_DELIVERY : '/'}
                            component={ScanForDeliveryFormContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.LISTED_ITEMS_COMING_BACK : '/'}
                            component={ListedItemsComingBackFormContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.SCAN_FOR_DELIVERY_RETURN : '/'}
                            component={DeliveryReturnWarehouseQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.INVENTORY_THANKYOU : '/'}
                            component={InventoryThankYou}
                        />
                        {/* PAYROLL FLOW STARTS HERE */}
                        <Route exact path={routes.SKILLSET} component={SkillSetContainer} />
                        <Route exact path={routes.DEPARTMENT} component={DepartmentContainer} />
                        <Route exact path={routes.LEAVE_LIST} component={LeaveListContainer} />
                        <Route exact path={routes.PREVIEW_LEAVE_REQUEST} component={PreviewLeaveRequestContainer} />
                        <Route exact path={routes.FRONTLINE_LIST} component={FrontlineList} />
                        <Route exact path={routes.SCHEDULED_TASKS} component={ScheduledTaskContainer} />
                        <Route exact path={routes.SUB_SCHEDULED_TASK} component={SubScheduledTaskContainer} />
                        <Route exact path={routes.PREVIEW_SUB_SCHEDULED_TASK} component={PreviewSubScheduledTaskContainer} />
                        <Route exact path={routes.NEW_SCHEDULED_TASK} component={NewScheduledTaskContainer} />
                        <Route exact path={routes.ADD_AVAILABILITY} component={addAvailabilityContainer} />
                        <Route exact path={routes.OVERRIDE_AVAILABILITY} component={overrideAvailabilityContainer} />
                        <Route exact path={routes.CALENDAR_AVAILABILITY} component={AvailabilityCalendarContainer} />
                        <Route exact path={routes.CALENDAR_SHIFT} component={ShiftCalendarContainer} />
                        <Route exact path={routes.SHIFT_SCHEDULE} component={ShiftScheduleContainer} />
                        <Route exact path={routes.SHIFT_SCHEDULE_DETAIL} component={ShiftScheduleDetailContainer} />
                        <Route exact path={routes.PREVIEW_SHIFT_SCHEDULE} component={PreviewShiftScheduleContainer} />
                        <Route exact path={[routes.FLUSER_NEW_SHIFT, routes.FLUSER_EDIT_SHIFT]} component={NewShiftContainer} />
                        <Route exact path={routes.FLUSER_EDIT_PERDAY_SHIFT_DETAIL} component={EditPerDayShiftDetailContainer} />
                        <Route exact path={routes.SWAP_LIST} component={SwapListContainer} />
                        <Route exact path={[routes.PREVIEW_SHIFT_SWAP_REQUEST_INFO, routes.PREVIEW_SHIFT_SWAPPED_SPECIFICS]} component={PreviewShiftSwapInfoContainer} />
                        <Route exact path={routes.OVERRIDE_AVAILABILITY_REQUEST_LIST} component={OverrideAvailabilityRequestListContainer} />
                        <Route exact path={routes.AVAILABILITY_LIST} component={AvailabilityListContainer} />
                        <Route exact path={routes.SINGLE_AVAILABILITY_LIST} component={SingleAvailabilityListContainer} />
                        <Route exact path={[routes.TIMECARD_CORRECTION_MODULE_V2, routes.PREVIEW_TIMECARD_CORRECTION]} component={TimecardCorrectionReviewerContainer} />
                        <Route exact path={routes.TIME_SHEET_LIST} component={TimeSheetListScreenContainer} />
                        <Route exact path={routes.ATTENDANCE_DASHBOARD} component={AttendanceDashboardContainer} />
                        <Route exact path={routes.TIMECARD_REVIEWED_LIST} component={TimeCardReviewListContainer} />
                        {/* PAYROLL FLOW ENDS HERE */}
                        {/* Form Audits Routes */}
                        {/* Team Management Module */}
                        <Route exact path={formAccess?.team_management?.checklist_log?.view ? routes.TEAM_CHECKLIST_LOG : '/'} component={teamChecklistLogContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_log?.create ? [routes.NEW_TEAM_CHECKLIST_LOG, routes.EDIT_TEAM_CHECKLIST_LOG] : '/'} component={NewTeamChecklistLogFormContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_log?.view ? routes.VIEW_TEAM_CHECKLIST_LOG : '/'} component={NewTeamChecklistLogFormContainer} />

                        <Route exact path={formAccess?.team_management?.checklist?.view ? routes.TEAM_CHECKLIST : '/'} component={teamChecklistContainer} />
                        <Route exact path={formAccess?.team_management?.checklist?.create ? [routes.NEW_TEAM_CHECKLIST, routes.EDIT_TEAM_CHECKLIST] : '/'} component={NewTeamChecklistFormContainer} />
                        <Route exact path={formAccess?.team_management?.checklist?.view ? routes.VIEW_TEAM_CHECKLIST : '/'} component={NewTeamChecklistFormContainer} />

                        <Route exact path={formAccess?.team_management?.checklist_manager?.view ? routes.TEAM_CHECKLIST_MANAGER_SUMMARY : '/'} component={checklistManagerContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_manager?.create ? [routes.TEAM_CHECKLIST_MANAGER_SUMMARY_NEW, routes, routes.TEAM_CHECKLIST_MANAGER_SUMMARY_EDIT] : '/'} component={NewCheckListManagerSummaryContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_manager?.view ? routes.TEAM_CHECKLIST_MANAGER_SUMMARY_VIEW : '/'} component={NewCheckListManagerSummaryContainer} />

                        <Route exact path={formAccess?.team_management?.checklist_supervisor_summary?.view ? routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY : '/'} component={checklistSupervisiorContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_supervisor_summary?.create ? [routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_NEW, routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_EDIT] : '/'} component={NewChecklistSupervisiorContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_supervisor_summary?.view ? routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_VIEW : '/'} component={NewChecklistSupervisiorContainer} />

                        <Route exact path={formAccess?.team_management?.training_log?.view ? routes.TRAINING_LOG : '/'} component={trainingLogContainer} />
                        <Route exact path={formAccess?.team_management?.training_log?.create ? [routes.TRAINING_LOG_NEW, routes.TRAINING_LOG_EDIT] : '/'} component={TrainingLogContainer} />
                        <Route exact path={formAccess?.team_management?.training_log?.view ? routes.TRAINING_LOG_VIEW : '/'} component={TrainingLogContainer} />
                        {/* Logbook Module */}
                        <Route exact path={formAccess?.logbook?.porter_log?.create ? [routes.NEW_LOG_RESTROOM_PORTERING, routes.EDIT_RESTROOM_PORTERING] : '/'} component={NewRestroomPorteringContainer} />
                        <Route exact path={formAccess?.logbook?.porter_log?.view ? routes.VIEW_RESTROOM_PORTERING : '/'} component={NewRestroomPorteringContainer} />
                        <Route exact path={formAccess?.logbook?.porter_log?.view ? routes.RESTROOM_PORTERING : '/'} component={restroomPorteringContainer} />
                        {/* Tag Module */}
                        <Route exact path={formAccess?.tag?.request_form?.create ? [routes.NEW_REQUSET_FORM, routes.EDIT_REQUSET_FORM] : '/'} component={NewRequestFormContainer} />
                        <Route exact path={formAccess?.tag?.request_form?.view ? routes.VIEW_REQUSET_FORM : '/'} component={NewRequestFormContainer} />
                        <Route exact path={formAccess?.tag?.request_form?.view ? routes.REQUSET_FORM : '/'} component={tagModuleContainer} />
                        {/* Project Module */}
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.DEEP_CLEAN_CHART : '/'} component={projectModuleContainer} />
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.create ? [routes.NEW_PROJECT_MODULE] : '/'} component={NewProjectModuleContainer} />
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.PROJECT_MODULE : '/'} component={projectModuleListContainer} />
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.VIEW_PROJECT_MODULE : '/'} component={PreviewPMListContainer} />
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.PREVIEW_SINGLE_PROJECT_MODULE : '/'} component={singlePMTicketContainer} />
                        {/* Building Profile */}
                        <Route exact path={formAccess?.building_profiles?.building_profile?.create ? [routes.NEW_BUILDING_PROFILE, routes.EDIT_BUILDING_PROFILE] : '/'} component={NewBuildingProfileFormContainer} />
                        <Route exact path={formAccess?.building_profiles?.building_profile?.view ? [routes.VIEW_BUILDING_PROFILE] : '/'} component={NewBuildingProfileFormContainer} />
                        <Route exact path={formAccess?.building_profiles?.job_cards?.create ? [routes.NEW_JOB_CORE_MAP_CARD, routes.EDIT_JOB_CORE_MAP_CARD] : '/'} component={NewJobCardsContainer} />
                        <Route exact path={formAccess?.building_profiles?.job_cards?.view ? [routes.VIEW_JOB_CORE_MAP_CARD] : '/'} component={NewJobCardsContainer} />
                        <Route exact path={formAccess?.building_profiles?.job_cards?.view ? routes.JOB_CORE_MAP_CARD : '/'} component={JobCardsContainer} />
                        <Route exact path={formAccess?.building_profiles?.building_profile?.view ? routes.BUILDING_PROFILE : '/'} component={buildingProfileContainer} />
                        {/* Inventory Module */}
                        <Route exact path={formAccess?.inventory?.daily_kitting_safety?.create ? [routes.NEW_DAILY_KITTING, routes.EDIT_DAILY_KITTING] : '/'} component={NewDailyKittingContainer} />
                        <Route exact path={formAccess?.inventory?.daily_kitting_safety.view ? [routes.VIEW_DAILY_KITTING] : '/'} component={NewDailyKittingContainer} />
                        <Route exact path={formAccess?.inventory?.daily_kitting_safety.view ? routes.DAILY_KITTING : '/'} component={dailyKittingContainer} />
                        <Route exact path={formAccess?.inventory?.monthly_usage_report.view ? routes.MONTHLY_USAGE_REPORT : '/'} component={monthlyUsageReportContainer} />
                        <Route exact path={formAccess?.inventory?.monthly_cost_summary.view ? [routes.MONTHLY_COST_SUMMARY_ANALYSIS] : '/'} component={MonthlyCostSummaryAnalysisContainer} />
                        {/* Audits */}
                        <Route exact path={formAccess?.audit?.self_audit?.create ? [routes.NEW_SELF_AUDIT, routes.EDIT_SELF_AUDIT] : '/'} component={SelfAuditFormContainer} />
                        <Route exact path={formAccess?.audit?.self_audit?.view ? [routes.VIEW_SELF_AUDIT] : '/'} component={SelfAuditFormContainer} />
                        <Route exact path={formAccess?.audit?.self_audit?.view ? routes.SELF_AUDIT : '/'} component={SelfAuditContainer} />
                        <Route exact path={formAccess?.audit?.performance_audit?.create ? [routes.NEW_PERFORMANCE_AUDIT, routes.EDIT_PERFORMANCE_AUDIT] : '/'} component={performanceAuditPage} />
                        <Route exact path={formAccess?.audit?.performance_audit?.view ? [routes.VIEW_PERFORMANCE_AUDIT] : '/'} component={performanceAuditPage} />
                        <Route exact path={formAccess?.audit?.performance_audit?.view ? routes.PERFORMANCE_AUDIT : '/'} component={performanceAuditContainer} />

                        <Route exact
                            path={formAccess?.inventory?.bulk_storage_supplies_usage?.create ? routes.NEW_INVENTORY_PULL_REQUEST_TICKET : '/'}
                            component={InventoryPullRequestContainer}
                        />
                        <Route exact path={routes.PICKING_TICKETS} component={PickingTicketContainer} />
                        <Route exact path={[routes.BRM_Schedule]} component={BrmScheduleContainer} />
                        <Route exact path={routes.PREVIEW_EVENTS} component={PreviewEventContainer} />
                        <Route path='*' render={(props) => <Redirect to={routes.DASHBORD} />} />
                    </Switch>
                )
            }
        } else if (props.user.role === userRoles.CLIENT) {
            const externalRoute = storage.get('externalRoute')
            if (externalRoute) {
                storage.remove('externalRoute')
                return <Switch> <Route path='*' render={(props) => <Redirect to={externalRoute} />} /> </Switch>
            } else {
                return (
                    <Switch>
                        <Route path="/reload" component={null} key="reload" />
                        <Route exact path={routes.PREVIEW_SINGLE_TICKET} component={PreviewSingleTicket} />
                        <Route exact path={routes.DASHBORD} component={AdminDashbord} />
                        <Route path={routes.CHI_REVIEW} component={CHIReviewContianer} />
                        <Route exact path={routes.HOME} render={(props) => <Redirect to={routes.INSPECTIONS} />} />
                        <Route exact path={[routes.TICKETS, routes.VIEW_FILTERED_TICKETS]} component={TicketContainer} />
                        <Route exact path={[routes.PRIORITY_ESCALATION_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={PriorityEscTicketContainer} />
                        <Route exact path={[routes.MY_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={MyTicketContainer} />
                        <Route exact path={[routes.SCHEDULED_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={ScheduledTicketContainer} />
                        <Route exact path={[routes.PREVIEW_SCHEDULED_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={previewScheduledTicketContainer} />
                        <Route exact path={[routes.NEW_TICKET, routes.EDIT_TICKET]} component={NewTicketContainer} />
                        <Route exact path={routes.VIEW_TICKET} component={PreviewTicket} />
                        <Route exact path={routes.VIEW_PRIORITY_ESCALATION_TICKET} component={PriorPreviewTicket} />
                        <Route exact path={[routes.INSPECTIONS, routes.VIEW_FILTERED_INSPECTIONS]} component={Inspections} />
                        <Route exact path={[routes.MY_INSPECTIONS]} component={MyInspections} />
                        <Route exact path={routes.PREVIEW_PENDING_INSPECTIONS} component={PendingPreviewInspection} />
                        <Route exact path={[routes.PENDING_INSPECTIONS]} component={PendingInspections} />
                        <Route exact path={[routes.PERFORM_INSPECTION, routes.EDIT_INSPECTION]} component={PerformInspection} />
                        <Route exact path={routes.PREVIEW_INSPECTION} component={PreviewInspection} />
                        <Route exact path={routes.INSPECTION_FORMS} component={InspectionFormContainer} />
                        <Route exact path={routes.SETTINGS_PREFERENCES} component={SettingsPreferences} />
                        <Route exact path={routes.SEARCH_HISTORY_NEW} component={NewSearchHistory} />
                        <Route exact path={routes.SEARCH_LOGBOOK_HISTORY} component={SearchLogbookHistoryContainer} />
                        <Route exact path={routes.SCAN_SEARCH_RESULT} component={ScanSearchResult} />
                        <Route exact path={routes.SCHEDULE_TAB} component={SchedulesContainer} />
                        <Route path={routes.THANKYOU} component={Thankyou} />
                        <Route exact path={routes.SCAN_SEARCH_SCHEDULE} component={SchedulePage} />
                        {/* <Route exact path={[routes.NEW_INSPECTION_FORMS, routes.EDIT_INSPECTION_FORM]} component={NewInspectionFormContainer} />
                    <Route exact path={routes.RATINGS} component={RatingsContainer} />
                    <Route exact path={[routes.NEW_RATING, routes.EDIT_RATING]} component={NewRatingsContaier} /> */}
                        <Route exact path={routes.CHI_QUARTERLY_SURVEY_CHECK_SCREEN} component={ChiQuarterlySurveyWaitScreen} />

                        <Route path='*' render={(props) => <Redirect to={routes.DASHBORD} />} />
                    </Switch>
                )
            }
        } else if (props.user.role === userRoles.INSPECTOR) {
            const externalRoute = storage.get('externalRoute')
            if (externalRoute) {
                storage.remove('externalRoute')
                return <Switch> <Route path='*' render={(props) => <Redirect to={externalRoute} />} /> </Switch>
            } else {
                return (
                    <Switch>
                        <Route path="/reload" component={null} key="reload" />
                        <Route exact path={routes.PREVIEW_SINGLE_TICKET} component={PreviewSingleTicket} />
                        <Route exact path={routes.LOGBOOK} component={LogBookAreaContainer} />
                        <Route exact path={routes.PREVIEW_LOGBOOK_LOG_AREA} component={PreviewLogBookLogsArea} />
                        <Route exact path={routes.HOME} render={(props) => <Redirect to={routes.INSPECTIONS} />} />
                        <Route path={routes.CHI_REVIEW} component={CHIReviewContianer} />
                        <Route exact path={[routes.QUOTES, routes.VIEW_FILTERED_QUOTES]} component={Quotes} />
                        <Route exact path={[routes.EDIT_QUOTES]} component={NewQuotes} />
                        <Route exact path={routes.TICKETS} component={TicketContainer} />
                        <Route exact path={[routes.LOGBOOK_FEEDBACK, routes.UPDATE_LOGBOOK_FEEDBACK]} component={LogBookFeedbackContainer} />
                        <Route exact path={routes.LOGBOOK_THANKYOU} component={LogBookThankYou} />
                        <Route exact path={routes.SCAN_LOGBOOK_QR} component={LogBookQrReader} />
                        <Route exact path={[routes.MY_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={MyTicketContainer} />
                        <Route exact path={[routes.SCHEDULED_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={ScheduledTicketContainer} />
                        <Route exact path={[routes.PREVIEW_SCHEDULED_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={previewScheduledTicketContainer} />
                        <Route exact path={[routes.NEW_TICKET, routes.EDIT_TICKET]} component={NewTicketContainer} />
                        <Route exact path={routes.VIEW_TICKET} component={PreviewTicket} />
                        <Route exact path={routes.PEGASSURE_LOGBOOK} component={PegassureLogbookContainer} />
                        <Route exact path={routes.SCAN_QR} component={ScanQr} />
                        <Route exact path={[routes.PRIORITY_ESCALATION_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={PriorityEscTicketContainer} />
                        <Route exact path={routes.VIEW_PRIORITY_ESCALATION_TICKET} component={PriorPreviewTicket} />
                        <Route exact path={[routes.INSPECTIONS, routes.VIEW_FILTERED_INSPECTIONS]} component={Inspections} />
                        <Route exact path={[routes.MY_INSPECTIONS]} component={MyInspections} />
                        <Route exact path={[routes.PENDING_INSPECTIONS]} component={PendingInspections} />
                        <Route exact path={routes.PREVIEW_PENDING_INSPECTIONS} component={PendingPreviewInspection} />
                        <Route exact path={[routes.PERFORM_INSPECTION, routes.EDIT_INSPECTION]} component={PerformInspection} />
                        <Route exact path={routes.PREVIEW_INSPECTION} component={PreviewInspection} />
                        <Route exact path={routes.SETTINGS_PREFERENCES} component={SettingsPreferences} />
                        <Route exact path={routes.SEARCH_HISTORY_NEW} component={NewSearchHistory} />
                        <Route exact path={routes.SEARCH_LOGBOOK_HISTORY} component={SearchLogbookHistoryContainer} />
                        <Route exact path={routes.SCAN_SEARCH_RESULT} component={ScanSearchResult} />
                        <Route exact path={routes.SCAN_SEARCH_SCHEDULE} component={SchedulePage} />
                        <Route exact path={routes.ACCOUNT_USERS} component={AccountUsersContainer} />
                        <Route exact path={[routes.NEW_ACCOUNTS, routes.EDIT_ACCOUNT]} component={NewAccounts} />
                        <Route exact path={routes.ACCOUNTS} component={Accounts} />
                        <Route exact path={routes.SCHEDULE_TIME} component={AccountsCalenderTableContainer} />
                        <Route exact path={routes.SCHEDULE_TAB} component={SchedulesContainer} />
                        <Route exact path={routes.SCHEDULE_TIME_PREVIEW} component={AccountsCalenderTableContainer} />
                        <Route path={routes.THANKYOU} component={Thankyou} />
                        <Route exact path={[routes.INSPECTION_SCHEDULE_HISTORY, routes.ALL_INSPECTION_SCHEDULE_HISTORY]} component={InspectionScheduleHistoryContainer} />
                        <Route exact path={[routes.TICKET_SCHEDULE_HISTORY, routes.ALL_TICKET_SCHEDULE_HISTORY]} component={TicketScheduleHistoryContainer} />
                        <Route exact path={routes.NEW_VERION} component={NewVersionContainer} />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.INVENTORY_MAIN_LIST : '/'}
                            component={InventoryListContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? [routes.NEW_INVENTORY_ITEM, routes.EDIT_INVENTORY_ITEM] : '/'}
                            component={NewInventoryItemContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.PREVIEW_INVENTORY_ITEM : '/'}
                            component={previewInventoryContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.BOX : '/'}
                            component={boxContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? [routes.ADD_BOX, routes.EDIT_BOX] : '/'}
                            component={newBoxContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.ASSETS_LIST : '/'}
                            component={assetsListContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.ASSETS_PREVIEW : '/'}
                            component={assetsPreviewContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? [routes.NEW_ASSET, routes.EDIT_ASSET] : '/'}
                            component={NewAssetsContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.UNAVAILABLE_ITEMS : '/'}
                            component={UnavailableItem}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.ASSETS_STOCK : '/'}
                            component={assetStockContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? [routes.ADD_ASSET_STOCK, routes.UPDATE_ASSET_STOCK] : '/'}
                            component={newAssetStockContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_SCAN_CHECK_OUT_QR : '/'}
                            component={AssetCheckOutQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_SCAN_CHECK_IN_QR : '/'}
                            component={AssetCheckInQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_CHECK_OUT_FORM : '/'}
                            component={AssetCheckInFormContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_CHECK_IN_FORM : '/'}
                            component={AssetCheckOutFormContainer}
                        />
                        <Route exact
                            path={
                                (inventoryAccessRole == 'manager') || (inventoryAccessRole == 'fulfillmentManager') ||
                                    (inventoryAccessRole == 'approver') ?
                                    [routes.PICKING_TICKETS, routes.PICKING_TICKETS_WITH_FILTERED_ID]
                                    : '/'
                            }
                            component={PickingTicketContainer}
                        />
                        <Route exact
                            path={
                                (inventoryAccessRole == 'manager') || (inventoryAccessRole == 'fulfillmentManager') ||
                                    (inventoryAccessRole == 'approver') ?
                                    routes.FULFILLMENT_MANAGER : '/'
                            }
                            component={FulfillmentManagerFormContainer}
                        />
                        <Route exact
                            path={
                                (inventoryAccessRole == 'manager') || (inventoryAccessRole == 'fulfillmentManager') ||
                                    (inventoryAccessRole == 'approver') ?
                                    routes.INVENTORY_SCAN_READY_FOR_PICKUP_QR : '/'
                            }
                            component={ReadyForPickupQRScan}
                        />
                        <Route exact
                            path={
                                (inventoryAccessRole == 'approver') ?
                                    [routes.EMERGENCY_PICKING_TICKETS, routes.EMERGENCY_PICKING_TICKETS_WITH_FILTERED_ID]
                                    : '/'
                            }
                            component={emergencyPickupContainer}
                        />
                        <Route exact path={routes.AUDIT_LOGS} component={auditLogsContainer} />
                        <Route exact
                            path={(inventoryAccessRole == 'manager') ? routes.NEW_INVENTORY_PULL_REQUEST_TICKET : '/'}
                            component={InventoryPullRequestContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.PICKUPS_TICKETS : '/'}
                            component={PickupsTicketContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.PICKUP_DETAILS : '/'}
                            component={PickupDetailsFormContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.INVENTORY_SCAN_READY_FOR_DELIVERY_QR : '/'}
                            component={ReadyForDeliveryQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.INVENTORY_SCAN_FOR_PICKED_DELIVERY_QR : '/'}
                            component={ForPickedDeliveryQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.SCAN_FOR_DELIVERY : '/'}
                            component={ScanForDeliveryFormContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.LISTED_ITEMS_COMING_BACK : '/'}
                            component={ListedItemsComingBackFormContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.SCAN_FOR_DELIVERY_RETURN : '/'}
                            component={DeliveryReturnWarehouseQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.INVENTORY_THANKYOU : '/'}
                            component={InventoryThankYou}
                        />
                        <Route exact path={routes.SKILLSET} component={SkillSetContainer} />
                        <Route exact path={routes.DEPARTMENT} component={DepartmentContainer} />
                        <Route exact path={routes.LEAVE_LIST} component={LeaveListContainer} />
                        <Route exact path={routes.PREVIEW_LEAVE_REQUEST} component={PreviewLeaveRequestContainer} />
                        <Route exact path={routes.SCHEDULED_TASKS} component={ScheduledTaskContainer} />
                        <Route exact path={routes.SUB_SCHEDULED_TASK} component={SubScheduledTaskContainer} />
                        <Route exact path={routes.PREVIEW_SUB_SCHEDULED_TASK} component={PreviewSubScheduledTaskContainer} />
                        <Route exact path={routes.NEW_SCHEDULED_TASK} component={NewScheduledTaskContainer} />
                        {/* Form Audits Routes */}
                        {/* Team Management Module */}
                        <Route exact path={formAccess?.team_management?.checklist_log?.view ? routes.TEAM_CHECKLIST_LOG : '/'} component={teamChecklistLogContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_log?.create ? [routes.NEW_TEAM_CHECKLIST_LOG, routes.EDIT_TEAM_CHECKLIST_LOG] : '/'} component={NewTeamChecklistLogFormContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_log?.view ? routes.VIEW_TEAM_CHECKLIST_LOG : '/'} component={NewTeamChecklistLogFormContainer} />

                        <Route exact path={formAccess?.team_management?.checklist?.view ? routes.TEAM_CHECKLIST : '/'} component={teamChecklistContainer} />
                        <Route exact path={formAccess?.team_management?.checklist?.create ? [routes.NEW_TEAM_CHECKLIST, routes.EDIT_TEAM_CHECKLIST] : '/'} component={NewTeamChecklistFormContainer} />
                        <Route exact path={formAccess?.team_management?.checklist?.view ? routes.VIEW_TEAM_CHECKLIST : '/'} component={NewTeamChecklistFormContainer} />

                        <Route exact path={formAccess?.team_management?.checklist_manager?.view ? routes.TEAM_CHECKLIST_MANAGER_SUMMARY : '/'} component={checklistManagerContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_manager?.create ? [routes.TEAM_CHECKLIST_MANAGER_SUMMARY_NEW, routes, routes.TEAM_CHECKLIST_MANAGER_SUMMARY_EDIT] : '/'} component={NewCheckListManagerSummaryContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_manager?.view ? routes.TEAM_CHECKLIST_MANAGER_SUMMARY_VIEW : '/'} component={NewCheckListManagerSummaryContainer} />

                        <Route exact path={formAccess?.team_management?.checklist_supervisor_summary?.view ? routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY : '/'} component={checklistSupervisiorContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_supervisor_summary?.create ? [routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_NEW, routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_EDIT] : '/'} component={NewChecklistSupervisiorContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_supervisor_summary?.view ? routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_VIEW : '/'} component={NewChecklistSupervisiorContainer} />

                        <Route exact path={formAccess?.team_management?.training_log?.view ? routes.TRAINING_LOG : '/'} component={trainingLogContainer} />
                        <Route exact path={formAccess?.team_management?.training_log?.create ? [routes.TRAINING_LOG_NEW, routes.TRAINING_LOG_EDIT] : '/'} component={TrainingLogContainer} />
                        <Route exact path={formAccess?.team_management?.training_log?.view ? routes.TRAINING_LOG_VIEW : '/'} component={TrainingLogContainer} />
                        {/* Logbook Module */}
                        <Route exact path={formAccess?.logbook?.porter_log?.create ? [routes.NEW_LOG_RESTROOM_PORTERING, routes.EDIT_RESTROOM_PORTERING] : '/'} component={NewRestroomPorteringContainer} />
                        <Route exact path={formAccess?.logbook?.porter_log?.view ? routes.VIEW_RESTROOM_PORTERING : '/'} component={NewRestroomPorteringContainer} />
                        <Route exact path={formAccess?.logbook?.porter_log?.view ? routes.RESTROOM_PORTERING : '/'} component={restroomPorteringContainer} />
                        {/* Tag Module */}
                        <Route exact path={formAccess?.tag?.request_form?.create ? [routes.NEW_REQUSET_FORM, routes.EDIT_REQUSET_FORM] : '/'} component={NewRequestFormContainer} />
                        <Route exact path={formAccess?.tag?.request_form?.view ? routes.VIEW_REQUSET_FORM : '/'} component={NewRequestFormContainer} />
                        <Route exact path={formAccess?.tag?.request_form?.view ? routes.REQUSET_FORM : '/'} component={tagModuleContainer} />
                        {/* Project Module */}
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.DEEP_CLEAN_CHART : '/'} component={projectModuleContainer} />
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.create ? [routes.NEW_PROJECT_MODULE] : '/'} component={NewProjectModuleContainer} />
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.PROJECT_MODULE : '/'} component={projectModuleListContainer} />
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.VIEW_PROJECT_MODULE : '/'} component={PreviewPMListContainer} />
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.PREVIEW_SINGLE_PROJECT_MODULE : '/'} component={singlePMTicketContainer} />
                        {/* Building Profile */}
                        <Route exact path={formAccess?.building_profiles?.building_profile?.create ? [routes.NEW_BUILDING_PROFILE, routes.EDIT_BUILDING_PROFILE] : '/'} component={NewBuildingProfileFormContainer} />
                        <Route exact path={formAccess?.building_profiles?.building_profile?.view ? [routes.VIEW_BUILDING_PROFILE] : '/'} component={NewBuildingProfileFormContainer} />
                        <Route exact path={formAccess?.building_profiles?.job_cards?.create ? [routes.NEW_JOB_CORE_MAP_CARD, routes.EDIT_JOB_CORE_MAP_CARD] : '/'} component={NewJobCardsContainer} />
                        <Route exact path={formAccess?.building_profiles?.job_cards?.view ? [routes.VIEW_JOB_CORE_MAP_CARD] : '/'} component={NewJobCardsContainer} />
                        <Route exact path={formAccess?.building_profiles?.job_cards?.view ? routes.JOB_CORE_MAP_CARD : '/'} component={JobCardsContainer} />
                        <Route exact path={formAccess?.building_profiles?.building_profile?.view ? routes.BUILDING_PROFILE : '/'} component={buildingProfileContainer} />
                        {/* Inventory Module */}
                        <Route exact path={formAccess?.inventory?.daily_kitting_safety?.create ? [routes.NEW_DAILY_KITTING, routes.EDIT_DAILY_KITTING] : '/'} component={NewDailyKittingContainer} />
                        <Route exact path={formAccess?.inventory?.daily_kitting_safety.view ? [routes.VIEW_DAILY_KITTING] : '/'} component={NewDailyKittingContainer} />
                        <Route exact path={formAccess?.inventory?.daily_kitting_safety.view ? routes.DAILY_KITTING : '/'} component={dailyKittingContainer} />
                        <Route exact path={formAccess?.inventory?.monthly_usage_report.view ? routes.MONTHLY_USAGE_REPORT : '/'} component={monthlyUsageReportContainer} />
                        <Route exact path={formAccess?.inventory?.monthly_cost_summary.view ? [routes.MONTHLY_COST_SUMMARY_ANALYSIS] : '/'} component={MonthlyCostSummaryAnalysisContainer} />
                        {/* Audits */}
                        <Route exact path={formAccess?.audit?.self_audit?.create ? [routes.NEW_SELF_AUDIT, routes.EDIT_SELF_AUDIT] : '/'} component={SelfAuditFormContainer} />
                        <Route exact path={formAccess?.audit?.self_audit?.view ? [routes.VIEW_SELF_AUDIT] : '/'} component={SelfAuditFormContainer} />
                        <Route exact path={formAccess?.audit?.self_audit?.view ? routes.SELF_AUDIT : '/'} component={SelfAuditContainer} />
                        <Route exact path={formAccess?.audit?.performance_audit?.create ? [routes.NEW_PERFORMANCE_AUDIT, routes.EDIT_PERFORMANCE_AUDIT] : '/'} component={performanceAuditPage} />
                        <Route exact path={formAccess?.audit?.performance_audit?.view ? [routes.VIEW_PERFORMANCE_AUDIT] : '/'} component={performanceAuditPage} />
                        <Route exact path={formAccess?.audit?.performance_audit?.view ? routes.PERFORMANCE_AUDIT : '/'} component={performanceAuditContainer} />

                        <Route exact
                            path={formAccess?.inventory?.bulk_storage_supplies_usage?.create ? routes.NEW_INVENTORY_PULL_REQUEST_TICKET : '/'}
                            component={InventoryPullRequestContainer}
                        />
                        <Route exact path={routes.PICKING_TICKETS} component={PickingTicketContainer} />

                        <Route path='*' render={(props) => <Redirect to={routes.QUOTES} />} />
                    </Switch>
                )
            }
        }
        else if (props.user.role === userRoles.REQUESTOR) {
            const externalRoute = storage.get('externalRoute')
            if (externalRoute) {
                storage.remove('externalRoute')
                return <Switch> <Route path='*' render={(props) => <Redirect to={externalRoute} />} /> </Switch>
            } else {
                return (
                    <Switch>
                        <Route path="/reload" component={null} key="reload" />
                        <Route exact path={routes.PREVIEW_SINGLE_TICKET} component={PreviewSingleTicket} />
                        <Route exact path={routes.HOME} render={(props) => <Redirect to={routes.DASHBORD} />} />
                        <Route exact path={routes.PREVIEW_LOGBOOK_LOG_AREA} component={PreviewLogBookLogsArea} />
                        <Route path={routes.CHI_REVIEW} component={CHIReviewContianer} />
                        <Route exact path={routes.LOGBOOK} component={LogBookAreaContainer} />
                        <Route exact path={[routes.LOGBOOK_FEEDBACK, routes.UPDATE_LOGBOOK_FEEDBACK]} component={LogBookFeedbackContainer} />
                        <Route exact path={routes.LOGBOOK_THANKYOU} component={LogBookThankYou} />
                        <Route exact path={routes.SCAN_LOGBOOK_QR} component={LogBookQrReader} />
                        {/* <Route path={routes.COMPANY_USERS} component={CompanyUsersContainer} />
                    <Route path={[routes.NEW_COMPANY, routes.EDIT_COMPANY]} component={NewCompany} />
                    <Route path={routes.COMPANIES} component={Companies} /> */}
                        <Route exact path={[routes.TICKETS, routes.VIEW_FILTERED_TICKETS]} component={TicketContainer} />
                        <Route exact path={[routes.PRIORITY_ESCALATION_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={PriorityEscTicketContainer} />
                        <Route exact path={[routes.MY_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={MyTicketContainer} />
                        <Route exact path={[routes.SCHEDULED_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={ScheduledTicketContainer} />
                        <Route exact path={[routes.PREVIEW_SCHEDULED_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={previewScheduledTicketContainer} />
                        <Route exact path={[routes.NEW_TICKET, routes.EDIT_TICKET]} component={NewTicketContainer} />
                        <Route exact path={routes.VIEW_TICKET} component={PreviewTicket} />
                        <Route exact path={routes.VIEW_PRIORITY_ESCALATION_TICKET} component={PriorPreviewTicket} />
                        <Route exact path={[routes.INSPECTIONS, routes.VIEW_FILTERED_INSPECTIONS]} component={Inspections} />
                        <Route exact path={[routes.MY_INSPECTIONS]} component={MyInspections} />
                        <Route exact path={[routes.PENDING_INSPECTIONS]} component={PendingInspections} />
                        <Route exact path={routes.PREVIEW_PENDING_INSPECTIONS} component={PendingPreviewInspection} />
                        <Route exact path={[routes.PERFORM_INSPECTION, routes.EDIT_INSPECTION]} component={PerformInspection} />
                        <Route exact path={routes.PREVIEW_INSPECTION} component={PreviewInspection} />
                        <Route exact path={[routes.NEW_QUOTES, routes.MANUALL_QUOTE, routes.EDIT_QUOTES]} component={NewQuotes} />
                        <Route exact path={[routes.QUOTES, routes.VIEW_FILTERED_QUOTES]} component={Quotes} />
                        <Route exact path={routes.DASHBORD} component={AdminDashbord} />
                        <Route exact path={routes.SETTINGS_PREFERENCES} component={SettingsPreferences} />
                        <Route exact path={routes.SEARCH_HISTORY_NEW} component={NewSearchHistory} />
                        <Route exact path={routes.SEARCH_LOGBOOK_HISTORY} component={SearchLogbookHistoryContainer} />
                        <Route exact path={routes.SCAN_SEARCH_RESULT} component={ScanSearchResult} />
                        <Route exact path={routes.SCAN_SEARCH_SCHEDULE} component={SchedulePage} />
                        <Route exact path={routes.SCHEDULE_TAB} component={SchedulesContainer} />
                        <Route exact path={routes.SCHEDULE_TIME_PREVIEW} component={AccountsCalenderTableContainer} />
                        <Route path={routes.THANKYOU} component={Thankyou} />
                        <Route exact path={routes.NEW_VERION} component={NewVersionContainer} />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.INVENTORY_MAIN_LIST : '/'}
                            component={InventoryListContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? [routes.NEW_INVENTORY_ITEM, routes.EDIT_INVENTORY_ITEM] : '/'}
                            component={NewInventoryItemContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.PREVIEW_INVENTORY_ITEM : '/'}
                            component={previewInventoryContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.BOX : '/'}
                            component={boxContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? [routes.ADD_BOX, routes.EDIT_BOX] : '/'}
                            component={newBoxContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.ASSETS_LIST : '/'}
                            component={assetsListContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.ASSETS_PREVIEW : '/'}
                            component={assetsPreviewContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? [routes.NEW_ASSET, routes.EDIT_ASSET] : '/'}
                            component={NewAssetsContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.UNAVAILABLE_ITEMS : '/'}
                            component={UnavailableItem}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? routes.ASSETS_STOCK : '/'}
                            component={assetStockContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'fulfillmentManager') ? [routes.ADD_ASSET_STOCK, routes.UPDATE_ASSET_STOCK] : '/'}
                            component={newAssetStockContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_SCAN_CHECK_OUT_QR : '/'}
                            component={AssetCheckOutQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_SCAN_CHECK_IN_QR : '/'}
                            component={AssetCheckInQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_CHECK_OUT_FORM : '/'}
                            component={AssetCheckInFormContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.ASSET_CHECK_IN_FORM : '/'}
                            component={AssetCheckOutFormContainer}
                        />
                        <Route exact
                            path={
                                (inventoryAccessRole == 'manager') || (inventoryAccessRole == 'fulfillmentManager') ||
                                    (inventoryAccessRole == 'approver') ?
                                    [routes.PICKING_TICKETS, routes.PICKING_TICKETS_WITH_FILTERED_ID]
                                    : '/'
                            }
                            component={PickingTicketContainer}
                        />
                        <Route exact
                            path={
                                (inventoryAccessRole == 'manager') || (inventoryAccessRole == 'fulfillmentManager') ||
                                    (inventoryAccessRole == 'approver') ?
                                    routes.FULFILLMENT_MANAGER : '/'
                            }
                            component={FulfillmentManagerFormContainer}
                        />
                        <Route exact
                            path={
                                (inventoryAccessRole == 'manager') || (inventoryAccessRole == 'fulfillmentManager') ||
                                    (inventoryAccessRole == 'approver') ?
                                    routes.INVENTORY_SCAN_READY_FOR_PICKUP_QR : '/'
                            }
                            component={ReadyForPickupQRScan}
                        />
                        <Route exact
                            path={
                                (inventoryAccessRole == 'approver') ?
                                    [routes.EMERGENCY_PICKING_TICKETS, routes.EMERGENCY_PICKING_TICKETS_WITH_FILTERED_ID]
                                    : '/'
                            }
                            component={emergencyPickupContainer}
                        />
                        <Route exact path={routes.AUDIT_LOGS} component={auditLogsContainer} />
                        <Route exact
                            path={(inventoryAccessRole == 'manager') ? routes.NEW_INVENTORY_PULL_REQUEST_TICKET : '/'}
                            component={InventoryPullRequestContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.PICKUPS_TICKETS : '/'}
                            component={PickupsTicketContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.PICKUP_DETAILS : '/'}
                            component={PickupDetailsFormContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.INVENTORY_SCAN_READY_FOR_DELIVERY_QR : '/'}
                            component={ReadyForDeliveryQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.INVENTORY_SCAN_FOR_PICKED_DELIVERY_QR : '/'}
                            component={ForPickedDeliveryQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.SCAN_FOR_DELIVERY : '/'}
                            component={ScanForDeliveryFormContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.LISTED_ITEMS_COMING_BACK : '/'}
                            component={ListedItemsComingBackFormContainer}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.SCAN_FOR_DELIVERY_RETURN : '/'}
                            component={DeliveryReturnWarehouseQRScan}
                        />
                        <Route exact
                            path={(inventoryAccessRole == 'supervisor') ? routes.INVENTORY_THANKYOU : '/'}
                            component={InventoryThankYou}
                        />
                        <Route exact path={routes.SKILLSET} component={SkillSetContainer} />
                        <Route exact path={routes.DEPARTMENT} component={DepartmentContainer} />
                        <Route exact path={routes.LEAVE_LIST} component={LeaveListContainer} />
                        <Route exact path={routes.PREVIEW_LEAVE_REQUEST} component={PreviewLeaveRequestContainer} />
                        <Route exact path={routes.SCHEDULED_TASKS} component={ScheduledTaskContainer} />
                        <Route exact path={routes.SUB_SCHEDULED_TASK} component={SubScheduledTaskContainer} />
                        <Route exact path={routes.PREVIEW_SUB_SCHEDULED_TASK} component={PreviewSubScheduledTaskContainer} />
                        <Route exact path={routes.NEW_SCHEDULED_TASK} component={NewScheduledTaskContainer} />
                        {/* Form Audits Routes */}
                        {/* Team Management Module */}
                        <Route exact path={formAccess?.team_management?.checklist_log?.view ? routes.TEAM_CHECKLIST_LOG : '/'} component={teamChecklistLogContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_log?.create ? [routes.NEW_TEAM_CHECKLIST_LOG, routes.EDIT_TEAM_CHECKLIST_LOG] : '/'} component={NewTeamChecklistLogFormContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_log?.view ? routes.VIEW_TEAM_CHECKLIST_LOG : '/'} component={NewTeamChecklistLogFormContainer} />

                        <Route exact path={formAccess?.team_management?.checklist?.view ? routes.TEAM_CHECKLIST : '/'} component={teamChecklistContainer} />
                        <Route exact path={formAccess?.team_management?.checklist?.create ? [routes.NEW_TEAM_CHECKLIST, routes.EDIT_TEAM_CHECKLIST] : '/'} component={NewTeamChecklistFormContainer} />
                        <Route exact path={formAccess?.team_management?.checklist?.view ? routes.VIEW_TEAM_CHECKLIST : '/'} component={NewTeamChecklistFormContainer} />

                        <Route exact path={formAccess?.team_management?.checklist_manager?.view ? routes.TEAM_CHECKLIST_MANAGER_SUMMARY : '/'} component={checklistManagerContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_manager?.create ? [routes.TEAM_CHECKLIST_MANAGER_SUMMARY_NEW, routes, routes.TEAM_CHECKLIST_MANAGER_SUMMARY_EDIT] : '/'} component={NewCheckListManagerSummaryContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_manager?.view ? routes.TEAM_CHECKLIST_MANAGER_SUMMARY_VIEW : '/'} component={NewCheckListManagerSummaryContainer} />

                        <Route exact path={formAccess?.team_management?.checklist_supervisor_summary?.view ? routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY : '/'} component={checklistSupervisiorContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_supervisor_summary?.create ? [routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_NEW, routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_EDIT] : '/'} component={NewChecklistSupervisiorContainer} />
                        <Route exact path={formAccess?.team_management?.checklist_supervisor_summary?.view ? routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_VIEW : '/'} component={NewChecklistSupervisiorContainer} />

                        <Route exact path={formAccess?.team_management?.training_log?.view ? routes.TRAINING_LOG : '/'} component={trainingLogContainer} />
                        <Route exact path={formAccess?.team_management?.training_log?.create ? [routes.TRAINING_LOG_NEW, routes.TRAINING_LOG_EDIT] : '/'} component={TrainingLogContainer} />
                        <Route exact path={formAccess?.team_management?.training_log?.view ? routes.TRAINING_LOG_VIEW : '/'} component={TrainingLogContainer} />
                        {/* Logbook Module */}
                        <Route exact path={formAccess?.logbook?.porter_log?.create ? [routes.NEW_LOG_RESTROOM_PORTERING, routes.EDIT_RESTROOM_PORTERING] : '/'} component={NewRestroomPorteringContainer} />
                        <Route exact path={formAccess?.logbook?.porter_log?.view ? routes.VIEW_RESTROOM_PORTERING : '/'} component={NewRestroomPorteringContainer} />
                        <Route exact path={formAccess?.logbook?.porter_log?.view ? routes.RESTROOM_PORTERING : '/'} component={restroomPorteringContainer} />
                        {/* Tag Module */}
                        <Route exact path={formAccess?.tag?.request_form?.create ? [routes.NEW_REQUSET_FORM, routes.EDIT_REQUSET_FORM] : '/'} component={NewRequestFormContainer} />
                        <Route exact path={formAccess?.tag?.request_form?.view ? routes.VIEW_REQUSET_FORM : '/'} component={NewRequestFormContainer} />
                        <Route exact path={formAccess?.tag?.request_form?.view ? routes.REQUSET_FORM : '/'} component={tagModuleContainer} />
                        {/* Project Module */}
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.DEEP_CLEAN_CHART : '/'} component={projectModuleContainer} />
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.create ? [routes.NEW_PROJECT_MODULE] : '/'} component={NewProjectModuleContainer} />
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.PROJECT_MODULE : '/'} component={projectModuleListContainer} />
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.VIEW_PROJECT_MODULE : '/'} component={PreviewPMListContainer} />
                        <Route exact path={formAccess?.projects?.deep_clean_chart?.view ? routes.PREVIEW_SINGLE_PROJECT_MODULE : '/'} component={singlePMTicketContainer} />
                        {/* Building Profile */}
                        <Route exact path={formAccess?.building_profiles?.building_profile?.create ? [routes.NEW_BUILDING_PROFILE, routes.EDIT_BUILDING_PROFILE] : '/'} component={NewBuildingProfileFormContainer} />
                        <Route exact path={formAccess?.building_profiles?.building_profile?.view ? [routes.VIEW_BUILDING_PROFILE] : '/'} component={NewBuildingProfileFormContainer} />
                        <Route exact path={formAccess?.building_profiles?.job_cards?.create ? [routes.NEW_JOB_CORE_MAP_CARD, routes.EDIT_JOB_CORE_MAP_CARD] : '/'} component={NewJobCardsContainer} />
                        <Route exact path={formAccess?.building_profiles?.job_cards?.view ? [routes.VIEW_JOB_CORE_MAP_CARD] : '/'} component={NewJobCardsContainer} />
                        <Route exact path={formAccess?.building_profiles?.job_cards?.view ? routes.JOB_CORE_MAP_CARD : '/'} component={JobCardsContainer} />
                        <Route exact path={formAccess?.building_profiles?.building_profile?.view ? routes.BUILDING_PROFILE : '/'} component={buildingProfileContainer} />
                        {/* Inventory Module */}
                        <Route exact path={formAccess?.inventory?.daily_kitting_safety?.create ? [routes.NEW_DAILY_KITTING, routes.EDIT_DAILY_KITTING] : '/'} component={NewDailyKittingContainer} />
                        <Route exact path={formAccess?.inventory?.daily_kitting_safety.view ? [routes.VIEW_DAILY_KITTING] : '/'} component={NewDailyKittingContainer} />
                        <Route exact path={formAccess?.inventory?.daily_kitting_safety.view ? routes.DAILY_KITTING : '/'} component={dailyKittingContainer} />
                        <Route exact path={formAccess?.inventory?.monthly_usage_report.view ? routes.MONTHLY_USAGE_REPORT : '/'} component={monthlyUsageReportContainer} />
                        <Route exact path={formAccess?.inventory?.monthly_cost_summary.view ? [routes.MONTHLY_COST_SUMMARY_ANALYSIS] : '/'} component={MonthlyCostSummaryAnalysisContainer} />
                        {/* Audits */}
                        <Route exact path={formAccess?.audit?.self_audit?.create ? [routes.NEW_SELF_AUDIT, routes.EDIT_SELF_AUDIT] : '/'} component={SelfAuditFormContainer} />
                        <Route exact path={formAccess?.audit?.self_audit?.view ? [routes.VIEW_SELF_AUDIT] : '/'} component={SelfAuditFormContainer} />
                        <Route exact path={formAccess?.audit?.self_audit?.view ? routes.SELF_AUDIT : '/'} component={SelfAuditContainer} />
                        <Route exact path={formAccess?.audit?.performance_audit?.create ? [routes.NEW_PERFORMANCE_AUDIT, routes.EDIT_PERFORMANCE_AUDIT] : '/'} component={performanceAuditPage} />
                        <Route exact path={formAccess?.audit?.performance_audit?.view ? [routes.VIEW_PERFORMANCE_AUDIT] : '/'} component={performanceAuditPage} />
                        <Route exact path={formAccess?.audit?.performance_audit?.view ? routes.PERFORMANCE_AUDIT : '/'} component={performanceAuditContainer} />

                        <Route exact
                            path={formAccess?.inventory?.bulk_storage_supplies_usage?.create ? routes.NEW_INVENTORY_PULL_REQUEST_TICKET : '/'}
                            component={InventoryPullRequestContainer}
                        />
                        <Route exact path={routes.PICKING_TICKETS} component={PickingTicketContainer} />

                        <Route path='*' render={(props) => <Redirect to={routes.DASHBORD} />} />
                    </Switch>
                )
            }
        }
        else if (props.user.role === userRoles.FRONTLINE) {
            const externalRoute = storage.get('externalRoute')
            if (externalRoute) {
                storage.remove('externalRoute')
                return <Switch> <Route path='*' render={(props) => <Redirect to={externalRoute} />} /> </Switch>
            } else {
                return (
                    <Switch>
                        <Route path="/reload" component={null} key="reload" />
                        {/* <Route exact path={routes.HOME_OPTION} component={HomeOptionContainer} /> */}
                        <Route exact path={routes.LOGBOOK_QR_BUTTON} component={LogBookQrButton} />
                        <Route exact path={routes.PREVIEW_SINGLE_TICKET} component={PreviewSingleTicket} />
                        <Route exact path={routes.HOMEPAGE} component={HomePageContainer} />
                        <Route path={routes.CHI_REVIEW} component={CHIReviewContianer} />
                        <Route exact path={routes.PREVIEW_LOGBOOK_LOG_AREA} component={PreviewLogBookLogsArea} />
                        <Route exact path={routes.LOGBOOK} component={LogBookAreaContainer} />
                        <Route exact path={[routes.LOGBOOK_FEEDBACK, routes.UPDATE_LOGBOOK_FEEDBACK]} component={LogBookFeedbackContainer} />
                        <Route exact path={routes.LOGBOOK_THANKYOU} component={LogBookThankYou} />
                        <Route exact path={routes.SCAN_LOGBOOK_QR} component={LogBookQrReader} />
                        <Route exact path={[routes.PRIORITY_ESCALATION_TICKETS, routes.VIEW_FILTERED_TICKETS]} component={PriorityEscTicketContainer} />
                        <Route exact path={routes.VIEW_PRIORITY_ESCALATION_TICKET} component={PriorPreviewTicket} />
                        <Route exact path={routes.USER_PROFILE} component={UserProfileComponent} />
                        {/* <Route path={routes.COMPANY_USERS} component={CompanyUsersContainer} />
                    <Route path={[routes.NEW_COMPANY, routes.EDIT_COMPANY]} component={NewCompany} />
                    <Route path={routes.COMPANIES} component={Companies} /> */}
                        <Route exact path={routes.SCAN_QR} component={ScanQr} />
                        <Route exact path={routes.SEARCH_HISTORY} component={SearchHistory} />
                        <Route exact path={routes.SCAN_SEARCH_RESULT} component={ScanSearchResult} />
                        <Route path={routes.THANKYOU} component={Thankyou} />
                        <Route exact path={routes.UPCOMING_PAST_SCHEDULE} component={Schedule} />
                        <Route exact path={routes.LEAVE_LIST} component={LeaveListContainer} />
                        <Route exact path={[routes.NEW_LEAVE, routes.EDIT_LEAVE]} component={NewLeaveContainer} />
                        <Route exact path={routes.PREVIEW_LEAVE_REQUEST} component={PreviewLeaveRequestContainer} />
                        <Route exact path={routes.SCHEDULED_TASKS} component={ScheduledTaskContainer} />
                        <Route exact path={routes.SUB_SCHEDULED_TASK} component={SubScheduledTaskContainer} />
                        <Route exact path={routes.PREVIEW_SUB_SCHEDULED_TASK} component={PreviewSubScheduledTaskContainer} />
                        <Route exact path={routes.PREVIEW_SUB_SCHEDULED_TASK_TASK_ID} component={PreviewSubScheduledTaskContainer} />
                        <Route exact path={routes.ADD_AVAILABILITY} component={addAvailabilityContainer} />
                        <Route exact path={routes.OVERRIDE_AVAILABILITY} component={overrideAvailabilityContainer} />
                        <Route exact path={[routes.CALENDAR_AVAILABILITY, routes.FLUSER_CALENDAR_AVAILABILITY]} component={AvailabilityCalendarContainer} />
                        <Route exact path={[routes.CALENDAR_SHIFT, routes.FLUSER_CALENDAR_SHIFT]} component={ShiftCalendarContainer} />
                        <Route exact path={routes.SHIFT} component={ShiftContainer} />
                        <Route exact path={routes.PREVIEW_SHIFT_SCHEDULE} component={PreviewShiftScheduleContainer} />
                        <Route exact path={routes.SWAP_LIST} component={SwapListContainer} />
                        <Route exact path={routes.SHIFT_CREATE_SWAP} component={NewShiftSwapContainer} />
                        <Route exact path={[routes.PREVIEW_SHIFT_SWAP_REQUEST_INFO, routes.PREVIEW_SHIFT_SWAPPED_SPECIFICS]} component={PreviewShiftSwapInfoContainer} />
                        <Route exact path={routes.OVERRIDE_AVAILABILITY_REQUEST_LIST} component={OverrideAvailabilityRequestListContainer} />
                        {/* Form Audits Routes */}
                        <Route exact path={formAccess?.team_management?.training_log?.view ? routes.TRAINING_LOG : routes.HOMEPAGE} component={trainingLogContainer} />
                        <Route exact path={formAccess?.team_management?.training_log?.create ? [routes.TRAINING_LOG_NEW, routes.TRAINING_LOG_EDIT] : routes.HOMEPAGE} component={TrainingLogContainer} />
                        <Route exact path={formAccess?.team_management?.training_log?.view ? routes.TRAINING_LOG_VIEW : routes.HOMEPAGE} component={TrainingLogContainer} />
                        {/* Logbook Module */}
                        <Route exact path={formAccess?.logbook?.porter_log?.create ? [routes.NEW_LOG_RESTROOM_PORTERING, routes.EDIT_RESTROOM_PORTERING] : routes.HOMEPAGE} component={NewRestroomPorteringContainer} />
                        <Route exact path={formAccess?.logbook?.porter_log?.view ? routes.VIEW_RESTROOM_PORTERING : routes.HOMEPAGE} component={NewRestroomPorteringContainer} />
                        <Route exact path={formAccess?.logbook?.porter_log?.view ? routes.RESTROOM_PORTERING : routes.HOMEPAGE} component={restroomPorteringContainer} />
                        {/* Tag Module */}
                        <Route exact path={formAccess?.tag?.request_form?.create ? [routes.NEW_REQUSET_FORM, routes.EDIT_REQUSET_FORM] : routes.HOMEPAGE} component={NewRequestFormContainer} />
                        <Route exact path={formAccess?.tag?.request_form?.view ? routes.VIEW_REQUSET_FORM : routes.HOMEPAGE} component={NewRequestFormContainer} />
                        <Route exact path={formAccess?.tag?.request_form?.view ? routes.REQUSET_FORM : routes.HOMEPAGE} component={tagModuleContainer} />
                        {/* Building Profile */}
                        <Route exact path={formAccess?.building_profiles?.building_profile?.create ? [routes.NEW_BUILDING_PROFILE, routes.EDIT_BUILDING_PROFILE] : routes.HOMEPAGE} component={NewBuildingProfileFormContainer} />
                        <Route exact path={formAccess?.building_profiles?.building_profile?.view ? [routes.VIEW_BUILDING_PROFILE] : routes.HOMEPAGE} component={NewBuildingProfileFormContainer} />
                        <Route exact path={formAccess?.building_profiles?.job_cards?.create ? [routes.NEW_JOB_CORE_MAP_CARD, routes.EDIT_JOB_CORE_MAP_CARD] : routes.HOMEPAGE} component={NewJobCardsContainer} />
                        <Route exact path={formAccess?.building_profiles?.job_cards?.view ? [routes.VIEW_JOB_CORE_MAP_CARD] : routes.HOMEPAGE} component={NewJobCardsContainer} />
                        <Route exact path={formAccess?.building_profiles?.job_cards?.view ? routes.JOB_CORE_MAP_CARD : routes.HOMEPAGE} component={JobCardsContainer} />
                        <Route exact path={formAccess?.building_profiles?.building_profile?.view ? routes.BUILDING_PROFILE : routes.HOMEPAGE} component={buildingProfileContainer} />
                        {/* Audits */}
                        <Route exact path={formAccess?.audit?.self_audit?.create ? [routes.NEW_SELF_AUDIT, routes.EDIT_SELF_AUDIT] : routes.HOMEPAGE} component={SelfAuditFormContainer} />
                        <Route exact path={formAccess?.audit?.self_audit?.view ? [routes.VIEW_SELF_AUDIT] : routes.HOMEPAGE} component={SelfAuditFormContainer} />
                        <Route exact path={formAccess?.audit?.self_audit?.view ? routes.SELF_AUDIT : routes.HOMEPAGE} component={SelfAuditContainer} />
                        <Route exact path={formAccess?.audit?.performance_audit?.create ? [routes.NEW_PERFORMANCE_AUDIT, routes.EDIT_PERFORMANCE_AUDIT] : routes.HOMEPAGE} component={performanceAuditPage} />
                        <Route exact path={formAccess?.audit?.performance_audit?.view ? [routes.VIEW_PERFORMANCE_AUDIT] : routes.HOMEPAGE} component={performanceAuditPage} />
                        <Route exact path={formAccess?.audit?.performance_audit?.view ? routes.PERFORMANCE_AUDIT : routes.HOMEPAGE} component={performanceAuditContainer} />
                        <Route exact path={routes.CLKIN_CLKOUT_SCREEN} component={ClockInClockOutScreenContainer} />
                        <Route exact path={routes.CLKIN_CLKOUT_FEEDBACK} component={ClockOutFeedbackContainer} />
                        <Route exact path={routes.CLKIN_CLKOUT_SHIFT_RECAP} component={ClkInClkOutShiftRecapContainer} />
                        <Route exact path={routes.FLUSER_MYTICKET} component={ScheduledTaskMyTicketContainer} />
                        <Route exact path={[routes.TIMECARD_CORRECTION_MODULE_V1, routes.TIMECARD_CORRECTION_MODULE_V3]} component={TimeCardCorrectionModuleContainer} />
                        <Route exact path={routes.FL_TIMECARD_REVIEWED_LIST} component={TimeCardReviewListContainer} />
                        <Route exact path={[routes.TIMECARD_CORRECTION_MODULE_V2, routes.PREVIEW_TIMECARD_CORRECTION]} component={TimecardCorrectionReviewerContainer} />
                        <Route exact path={[routes.TIME_SHEET_LIST, routes.FL_TIME_SHEET_LIST]} component={TimeSheetListScreenContainer} />
                        <Route exact path={routes.MEALPENALTY_GENERATOR} component={MealPenaltyGenerateContainer} />
                        {/* Pegassure Playbook */}
                        <Route exact path={routes.PREVIEW_PEGASSURE_PLAYBOOK_PDF} component={PreviewPegassurePlaybookPdfContainer} />
                        <Route exact path={routes.FRONTLINE_FACE_VERIFICATION} component={ClockInClockOutFaceRecognitionContainer} />
                        <Route path='*' render={(props) => <Redirect to={routes.HOMEPAGE} />} />
                    </Switch>
                )
            }
        }

    } else {
        storage.set('externalRoute', props.location.state && props.location.state.prevPath ? props.location.state.prevPath : '')
        return (
            <Switch>
                <Route path="/reload" component={null} key="reload" />
                <Route exact path={routes.HOME} component={SignInContainer} />
                <Route path={routes.SIGNIN} component={SignInContainer} />
                <Route path={routes.FORGET_PASSWORD} component={ForgetPassword} />
                <Route path={routes.RESET_PASSWORD} component={ResetPassword} />
                <Route exact path={routes.SCAN_QR} component={ScanQr} />
                <Route exact path={routes.SCAN_GLOBAL_QR} component={ScanQr} />
                <Route exact path={routes.SEARCH_HISTORY_NEW} component={NewSearchHistory} />
                <Route exact path={routes.SEARCH_LOGBOOK_HISTORY} component={SearchLogbookHistoryContainer} />
                <Route exact path={routes.SEARCH_HISTORY} component={SearchHistory} />
                <Route exact path={routes.SCAN_SEARCH_RESULT} component={ScanSearchResult} />
                <Route exact path={routes.SCAN_SEARCH_SCHEDULE} component={SchedulePage} />
                <Route exact path={routes.PEGASSURE_FEEDBACK} component={FeedbackContainer} />
                <Route exact path={routes.PEGASSURE_COMMENTS} component={CommentsContainer} />
                <Route exact path={routes.PEGASSURE_THANKYOU} component={ThankyouContainer} />
                <Route path={routes.CHI_REVIEW} component={CHIReviewContianer} />
                <Route exact path={routes.FRONTLINE_FACE_DETECTOR} component={FaceRecognitionContainer} />
                <Route path={routes.THANKYOU} component={Thankyou} />
                <Route path='*' render={(props) => <Redirect to={{ pathname: routes.SIGNIN, state: { prevPath: props.location } }} />} />
            </Switch>
        )
    }
}

const Router = (props) => {

    return (
        <Layout>
            {route(props)}
        </Layout>
    )
}

export default Router;

export const NotFound = () => {
    return (
        <h1 className="text-center" style={{ margin: '100px' }}>404. Page not found.</h1>
    );
};
