import React from 'react';
import $ from "jquery";
import storage from '../../utility/storage';
import { Status, userRoles, TimeZones, TimezoneMonths, routes } from '../constants/constants';
import { convertDateFormatV4, convertTo12HourFormat, getUser } from '../utility';
// import { DateTime, Duration } from 'luxon';

var dateFormat = require('dateformat');
var moment = require('moment');
var momentTZ = require('moment-timezone');
const cloneDeep = require('clone-deep');
const queryString = require('query-string');
const { DateTime, Duration } = require('luxon');

export const customTime = (date) => {

    let fetchedDate = date;
    fetchedDate.setHours(0, 0, 0, 0);

    return fetchedDate;

}

export const toFindPDTAvailability = (date) => {
    // debugger
    let currentUser = getUser();

    let gettingDateofTimezone = currentUser?.timezone ? momentTZ(date).tz(currentUser?.timezone) : null;
    let result = momentTZ(gettingDateofTimezone && gettingDateofTimezone._d);

    let isPacificDaylightTime = result.isDST();

    return isPacificDaylightTime;

}

export const tzStartOfWeek = (date) => {

    // Get the current date
    const currentDate = moment(date);
    const startOfWeek = currentDate.startOf('week');

    // Format the dates as strings
    const startOfWeekString = startOfWeek.format('YYYY-MM-DD');

    return startOfWeekString;

}

export const tzEndOfWeek = (date) => {

    // Get the current date
    const currentDate = moment(date);
    const endOfWeek = currentDate.endOf('week');

    // Format the dates as strings
    const endOfWeekString = endOfWeek.format('YYYY-MM-DD');

    return endOfWeekString;

}

export const tzTimezoneMarking = (timezone) => {

    let zoneMark = null

    if (timezone) {
        if (timezone == TimeZones.PST) {
            zoneMark = 'Pacific Standard Time (PST)'
        } else if (timezone == TimeZones.CST) {
            zoneMark = 'Central Standard Time (CST)'
        }
    }

    return zoneMark

}

/* For availability and shift calendar display date && time timezone functionalities */
export const tzCalendarDateTimeConverterTool = (date, time) => {

    let formatedUTCDateString = new Date(`${date} ${time}`);

    let systemTimeZone = momentTZ.tz.guess(); // Identify the current system time zone
    let currentUser = getUser();
    let isPacificDaylightTime = toFindPDTAvailability(formatedUTCDateString);

    let utcToCurrentTimeoneDateString = null;
    let finalFormattedDate = null;

    // utcToCurrentTimeoneDateString = momentTZ(formatedUTCDateString).tz(currentUser.timezone);
    // let result = momentTZ(utcToCurrentTimeoneDateString._d);

    /* Condition for comparing syatem and user timezone */
    if (systemTimeZone == currentUser.timezone) {

        /* Condition for Current Timezone Conversion */
        if (currentUser.timezone == TimeZones.PST) {
            utcToCurrentTimeoneDateString = momentTZ(formatedUTCDateString).subtract(7, 'hours');
        } else if (currentUser.timezone == TimeZones.CST) {
            utcToCurrentTimeoneDateString = momentTZ(formatedUTCDateString).subtract(5, 'hours');
        }

    } else {

        let currentTimezoneDateObject = momentTZ(formatedUTCDateString).tz(currentUser.timezone);
        /* Condition for Current Timezone Conversion */
        if (currentUser.timezone == TimeZones.PST) {
            utcToCurrentTimeoneDateString = currentTimezoneDateObject.subtract(7, 'hours');
        } else if (currentUser.timezone == TimeZones.CST) {
            utcToCurrentTimeoneDateString = currentTimezoneDateObject.subtract(5, 'hours');
        }

    }

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime == true) {
        finalFormattedDate = utcToCurrentTimeoneDateString.subtract(0, 'hour').format('YYYY-MM-DDTHH:mm:ss');
    } else if (isPacificDaylightTime == false) {
        finalFormattedDate = utcToCurrentTimeoneDateString.subtract(1, 'hour').format('YYYY-MM-DDTHH:mm:ss');
    }

    return finalFormattedDate;

}

/* For Payroll - POST - PUT Calls - API Functionalities start_date/end_date converter by combining its start_time and end_time */
export const tzUTCDateTimeConverterTool_V1 = (date, time, type) => {

    let systemTimeZone = momentTZ.tz.guess(); // Identify the current system time zone
    let currentUser = getUser();
    let startEndOfDayFormatedDate = null;
    let utcDateString = null;

    if (type === 'from') {
        startEndOfDayFormatedDate = momentTZ(`${convertDateFormatV4(date)} ${time}`).startOf('day'); //'T00:00:00'
    } else if (type == 'to') {
        startEndOfDayFormatedDate = momentTZ(`${convertDateFormatV4(date)} ${time}`).endOf('day'); //'T23:59:59'
    } else if (type == 'none') {
        startEndOfDayFormatedDate = momentTZ(`${convertDateFormatV4(date)} ${time}`);
    }

    /* Condition for comparing syatem and user timezone */
    if (systemTimeZone == currentUser.timezone) {

        /* Condition for Current Timezone Conversion */
        if (currentUser.timezone == TimeZones.PST) {
            utcDateString = startEndOfDayFormatedDate.add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss');
        } else if (currentUser.timezone == TimeZones.CST) {
            utcDateString = startEndOfDayFormatedDate.add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss');
        }

    } else {

        let currentTimezoneDateObject = startEndOfDayFormatedDate.tz(currentUser.timezone);
        /* Condition for Current Timezone Conversion */
        if (currentUser.timezone == TimeZones.PST) {
            utcDateString = currentTimezoneDateObject.add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss');
        } else if (currentUser.timezone == TimeZones.CST) {
            utcDateString = currentTimezoneDateObject.add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss');
        }

    }

    return utcDateString;

}

/* For Payroll - GET Calls - API Functionalities start_date/end_date converter */
export const tzUTCDateConverterTool_V2 = (date, type) => {debugger

    let systemTimeZone = momentTZ.tz.guess(); // Identify the current system time zone
    let currentUser = getUser();
    let startEndOfDayFormatedDate = null;
    let utcTimezoneDateTimeObject = null;
    let utcDateString = null;

    if (type === 'from') {
        startEndOfDayFormatedDate = momentTZ(date).startOf('day'); //'T00:00:00'
    } else if (type == 'to') {
        startEndOfDayFormatedDate = momentTZ(date).endOf('day'); //'T23:59:59'
    } else if (type == 'none') {
        startEndOfDayFormatedDate = momentTZ(date); /* NONE */
    }

    if (systemTimeZone == currentUser.timezone) {

        /* Condition for Current Timezone Conversion */
        if (currentUser.timezone == TimeZones.PST) {
            utcTimezoneDateTimeObject = startEndOfDayFormatedDate.add(7, 'hours');
        } else if (currentUser.timezone == TimeZones.CST) {
            utcTimezoneDateTimeObject = startEndOfDayFormatedDate.add(5, 'hours');
        }

        utcDateString = momentTZ(utcTimezoneDateTimeObject).format('YYYY-MM-DDTHH:mm:ss');

    } else {

        let currentTimezoneDateObject = startEndOfDayFormatedDate.tz(currentUser.timezone);

        /* Condition for Current Timezone Conversion */
        if (currentUser.timezone == TimeZones.PST) {
            utcTimezoneDateTimeObject = currentTimezoneDateObject.add(7, 'hours');
        } else if (currentUser.timezone == TimeZones.CST) {
            utcTimezoneDateTimeObject = currentTimezoneDateObject.add(5, 'hours');
        }

        utcDateString = momentTZ(utcTimezoneDateTimeObject).format('YYYY-MM-DDTHH:mm:ss');

    }

    return utcDateString;

}

/* For Payroll - POST - PUT Calls - API Functionalities start_time/end_time converter by combining its start_date and end_date */
export const tzUTCTimeConverterTool_V1 = (date, time) => {

    let systemTimeZone = moment.tz.guess(); // Identify the current system time zone
    let currentUser = getUser();
    let utcTimeObjectString = null;

    let combinedDateTimeObject = momentTZ(`${date} ${time}`);

    /* Condition for comparing syatem and user timezone */
    if (systemTimeZone == currentUser.timezone) {

        /* Condition for Current Timezone Conversion */
        if (currentUser.timezone == TimeZones.PST) {
            utcTimeObjectString = combinedDateTimeObject.add(7, 'hours').format('HH:mm:ss');
        } else if (currentUser.timezone == TimeZones.CST) {
            utcTimeObjectString = combinedDateTimeObject.add(5, 'hours').format('HH:mm:ss');
        }

    } else {

        let currentTimezoneTimeObject = combinedDateTimeObject.tz(currentUser.timezone);
        /* Condition for Current Timezone Conversion */
        if (currentUser.timezone == TimeZones.PST) {
            utcTimeObjectString = currentTimezoneTimeObject.add(7, 'hours').format('HH:mm:ss');
        } else if (currentUser.timezone == TimeZones.CST) {
            utcTimeObjectString = currentTimezoneTimeObject.add(5, 'hours').format('HH:mm:ss');
        }

    }

    return utcTimeObjectString;

}

// Using this functionality for display the start_date and end_date with start_time and end_time
export const tzDateConverterTool_V1 = (date) => {

    let currentUser = getUser();
    let zone = "";
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let currentTimezoneDateObject = null;
    let finalFormatedDate = null;

    if (currentUser) {
        if (currentUser.timezone && currentUser.timezone == TimeZones.PST) {
            zone = '(PST)'
        } else if (currentUser.timezone && currentUser.timezone == TimeZones.CST) {
            zone = '(CST)'
        }
    }

    const localDate = moment.utc(date);

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        currentTimezoneDateObject = localDate.add(0, 'hour');
    } else {
        currentTimezoneDateObject = localDate.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedDate = momentTZ(currentTimezoneDateObject).tz(currentUser.timezone);
    let convertedTimezoneDate = momentTZ(finalFormatedDate).format('MMM DD, YYYY') + ' ' + zone; //'YYYY-MM-DD HH:mm:ss (z)'

    return convertedTimezoneDate;

}

export const tzDateConverterTool_V2 = (date) => {

    let currentUser = getUser();
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let currentTimezoneDateObject = null;
    let finalFormatedDate = null;

    const localDate = momentTZ(date);

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        currentTimezoneDateObject = localDate.add(0, 'hour');
    } else {
        currentTimezoneDateObject = localDate.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedDate = momentTZ(currentTimezoneDateObject).tz(currentUser.timezone);
    let convertedTimezoneDayName = momentTZ(finalFormatedDate).format('dddd'); //'YYYY-MM-DD HH:mm:ss (z)'

    return convertedTimezoneDayName;

}

export const tzDateConverterTool_V3 = (date) => {

    let currentUser = getUser();
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let currentTimezoneDateObject = null;
    let finalFormatedDate = null;

    const localDate = momentTZ(date);

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        currentTimezoneDateObject = localDate.add(0, 'hour');
    } else {
        currentTimezoneDateObject = localDate.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedDate = momentTZ(currentTimezoneDateObject).tz(currentUser?.timezone);
    let convertedTimezoneDate = new Date(finalFormatedDate);

    return convertedTimezoneDate;

}

// Using this functionality for display the start_date and end_date without start_time and end_time
export const tzDateConverterTool_V4 = (date) => {

    let currentUser = getUser();
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let zone = null;
    let localDate = null;
    let currentTimezoneDateObject = null;

    if (currentUser) {
        if (currentUser.timezone && currentUser.timezone == TimeZones.PST) {
            zone = '(PST)'
        } else if (currentUser.timezone && currentUser.timezone == TimeZones.CST) {
            zone = '(CST)'
        }
    }

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        localDate = momentTZ(date).add(0, 'hour');
    } else {
        localDate = momentTZ(date).add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    currentTimezoneDateObject = momentTZ(localDate).tz(currentUser.timezone);
    let convertedTimezoneDate = momentTZ(currentTimezoneDateObject).format('MMM DD, YYYY') + ' ' + zone; //'YYYY-MM-DD HH:mm:ss (z)'

    return convertedTimezoneDate;

}

// Using this functionality for display the start_date and end_date without start_time and end_time
export const tzDateConverterTool_V5 = (date) => {

    let currentUser = getUser();
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let localDate = null;
    let currentTimezoneDateObject = null;

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        localDate = momentTZ(date).add(0, 'hour');
    } else {
        localDate = momentTZ(date).add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    currentTimezoneDateObject = momentTZ(localDate).tz(currentUser.timezone);
    let convertedTimezoneDate = momentTZ(currentTimezoneDateObject).format('YYYY-MM-DDTHH:mm:ss'); //'YYYY-MM-DD HH:mm:ss (z)'

    return convertedTimezoneDate;

}

// Using this functionality for display the start_date and end_date with start_time and end_time
export const tzDateConverterTool_V6 = (date) => {

    let currentUser = getUser();
    let zone = null;
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let currentTimezoneDateObject = null;
    let finalFormatedDate = null;

    if (currentUser) {
        if (currentUser.timezone && currentUser.timezone == TimeZones.PST) {
            zone = '(PST)'
        } else if (currentUser.timezone && currentUser.timezone == TimeZones.CST) {
            zone = '(CST)'
        }
    }

    const localDate = moment.utc(date);

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        currentTimezoneDateObject = localDate.add(0, 'hour');
    } else {
        currentTimezoneDateObject = localDate.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedDate = momentTZ(currentTimezoneDateObject).tz(currentUser.timezone);
    let convertedTimezoneDate = momentTZ(finalFormatedDate).format('YYYY-MM-DDThh:mm A').split('T');

    let capturedDate = convertedTimezoneDate[0];
    let capturedTime = convertedTimezoneDate[1];

    let finalFormattedResult = capturedDate + ', ' + capturedTime + ' ' + zone; //'YYYY-MM-DD HH:mm:ss (z)'

    return finalFormattedResult;

}

export const tzDateConverterTool_V6_Dateonly = (date) => {

    let currentUser = getUser();
    let zone = null;
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let currentTimezoneDateObject = null;
    let finalFormatedDate = null;

    if (currentUser) {
        if (currentUser.timezone && currentUser.timezone == TimeZones.PST) {
            zone = '(PST)'
        } else if (currentUser.timezone && currentUser.timezone == TimeZones.CST) {
            zone = '(CST)'
        }
    }

    const localDate = moment.utc(date);

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        currentTimezoneDateObject = localDate.add(0, 'hour');
    } else {
        currentTimezoneDateObject = localDate.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedDate = momentTZ(currentTimezoneDateObject).tz(currentUser.timezone);
    let convertedTimezoneDate = momentTZ(finalFormatedDate).format('YYYY-MM-DDThh:mm A').split('T');

    let capturedDate = convertedTimezoneDate[0];
    // let capturedTime = convertedTimezoneDate[1];

    let finalFormattedResult = capturedDate 

    return finalFormattedResult;

}

/* Using this functionality for display the date with time */
export const tzDateConverterTool_V7 = (date) => {

    let currentUser = getUser();
    let zone = null;
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let currentTimezoneDateObject = null;
    let finalFormatedDate = null;

    if (currentUser) {
        if (currentUser.timezone && currentUser.timezone == TimeZones.PST) {
            zone = '(PST)'
        } else if (currentUser.timezone && currentUser.timezone == TimeZones.CST) {
            zone = '(CST)'
        }
    }

    const localDate = moment.utc(date);

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        currentTimezoneDateObject = localDate.add(0, 'hour');
    } else {
        currentTimezoneDateObject = localDate.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedDate = momentTZ(currentTimezoneDateObject).tz(currentUser.timezone);

    let fetchedDate = momentTZ(finalFormatedDate).format('MMM DD, YYYY'); //'YYYY-MM-DD HH:mm:ss (z)'
    let fetchedTime = momentTZ(finalFormatedDate).format('hh:mm A'); //'YYYY-MM-DD HH:mm:ss (z)'

    let convertedTimezoneDate = fetchedDate + ' ' + `[${fetchedTime}]` + ' ' + zone;

    return convertedTimezoneDate;

}

/* Converting 24hours format into 12hours format */
export const tzTimeConversionTool_V1 = (time, date) => {

    let currentUser = getUser();
    let zone = "";
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let localDate = null;
    let finalFormatedTime = null;

    if (currentUser) {
        if (currentUser.timezone && currentUser.timezone == TimeZones.PST) {
            zone = '(PST)'
        } else if (currentUser.timezone && currentUser.timezone == TimeZones.CST) {
            zone = '(CST)'
        }
    }

    let convertedDateString = moment(date).format('YYYY-MM-DD');
    let combinedDateTimeObject = momentTZ(`${convertedDateString} ${time}`, 'YYYY-MM-DD HH:mm:ss'); // Combine date and time

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        localDate = combinedDateTimeObject.add(0, 'hour');
    } else {
        localDate = combinedDateTimeObject.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedTime = momentTZ(localDate).tz(currentUser.timezone);
    let result = momentTZ(finalFormatedTime._d).format('hh:mm A') + ' ' + zone;

    return result;

}

/* Converting 24hours format into 12hours format in Update Forms */
export const tzTimeConversionTool_V2 = (time, date) => {

    let currentUser = getUser();
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let localDate = null;
    let finalFormatedTime = null;

    let convertedDateString = date.split('T')[0];
    let combinedDateTimeObject = momentTZ(`${convertedDateString} ${time}`, 'YYYY-MM-DD HH:mm:ss'); // Combine date and time
   
    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        localDate = combinedDateTimeObject.add(0, 'hour');
    } else {
        localDate = combinedDateTimeObject.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedTime = momentTZ(localDate).tz(currentUser.timezone);
    let result = momentTZ(finalFormatedTime._d).format('hh:mm A')
    return result;

}

/* Converting Date format into 12hours format */
export const tzTimeConverterTool_V3 = (date) => {

    let currentUser = getUser();
    let zone = null;
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let localDate = null;
    let finalFormatedTime = null;

    if (currentUser) {
        if (currentUser.timezone && currentUser.timezone == TimeZones.PST) {
            zone = '(PST)'
        } else if (currentUser.timezone && currentUser.timezone == TimeZones.CST) {
            zone = '(CST)'
        }
    }

    let convertedDateString = momentTZ(date);

    /* Condition for Pacific Daylight Time */
    if (isPacificDaylightTime) {
        localDate = moment(convertedDateString).add(0, 'hour')

    } else {
        localDate = moment(convertedDateString).add(1, 'hour')
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedTime = momentTZ(localDate).tz(currentUser.timezone);
    let result = momentTZ(finalFormatedTime).format('hh:mm A') + ' ' + zone;

    return result;

}

/* Converting 24hours format into 24hours format from utc to current timezone */
export const tzTimeConversionTool_V4 = (time, date) => {

    let currentUser = getUser();
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let localDate = null;
    let finalFormatedTime = null;

    let convertedDateString = date.split('T')[0];
    let combinedDateTimeObject = momentTZ(`${convertedDateString} ${time}`, 'YYYY-MM-DD HH:mm:ss'); // Combine date and time

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        localDate = combinedDateTimeObject.add(0, 'hour');
    } else {
        localDate = combinedDateTimeObject.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedTime = momentTZ(localDate).tz(currentUser.timezone);
    let result = momentTZ(finalFormatedTime._d).format('HH:mm:ss');

    return result;

}

/* Taking the start and end value from GET api call */
export const tzInbetweenDaysFinder_V1 = (start, end) => {

    let currentUser = getUser();

    const startDate = customTime(new Date(DateTime.fromISO(start.split('T')[0]).setZone(currentUser.timezone).toFormat('y-MM-d')));
    const endDate = customTime(new Date(DateTime.fromISO(end.split('T')[0]).setZone(currentUser.timezone).toFormat('y-MM-d')));

    const startDateTime = DateTime.fromJSDate(startDate);
    const endDateTime = DateTime.fromJSDate(endDate);

    let daysBetween = [];

    let currentDate = startDateTime;
    while (currentDate <= endDateTime) {
        daysBetween.push(currentDate.toJSDate());
        currentDate = currentDate.plus({ days: 1 });
    }

    return daysBetween && daysBetween.length == 1 ? `${daysBetween.length} Day` : `${daysBetween.length} Days`

}

/* Taking the start and end value from Form calendar */
export const tzInbetweenDaysFinder_V2 = (start, end) => {

    let currentUser = getUser();

    const startDate = customTime(new Date(DateTime.fromJSDate(start).setZone(currentUser.timezone).toFormat('y-MM-d')));
    const endDate = customTime(new Date(DateTime.fromJSDate(end).setZone(currentUser.timezone).toFormat('y-MM-d')));

    const startDateTime = DateTime.fromJSDate(startDate);
    const endDateTime = DateTime.fromJSDate(endDate);

    let daysBetween = [];

    let currentDate = startDateTime;
    while (currentDate <= endDateTime) {
        daysBetween.push(currentDate.toJSDate());
        currentDate = currentDate.plus({ days: 1 });
    }

    return daysBetween && daysBetween.length == 1 ? `${daysBetween.length} Day` : `${daysBetween.length} Days`

}



export const tzTimeConversionTool_V2alpha = (timestring,date) => {

    let currentUser = getUser();
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let localDate = null;
    let finalFormatedTime = null;

    let convertedDateString = date.split('T')[0];
    let combinedDateTimeObject = momentTZ(timestring, 'YYYY-MM-DD HH:mm:ss'); // Combine date and time
   
    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        localDate = combinedDateTimeObject.add(0, 'hour');
    } else {
        localDate = combinedDateTimeObject.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedTime=combinedDateTimeObject.format("hh:mm A")
    
    
    return finalFormatedTime;

}


export const tzDateConverterTool_V6R = (date,format="MM-DD-YYThh:mm A") => {

    let currentUser = getUser();
    let zone = null;
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let currentTimezoneDateObject = null;
    let finalFormatedDate = null;

    if (currentUser) {
        if (currentUser.timezone && currentUser.timezone == TimeZones.PST) {
            zone = '(PST)'
        } else if (currentUser.timezone && currentUser.timezone == TimeZones.CST) {
            zone = '(CST)'
        }
    }

    const localDate = moment.utc(date);

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        currentTimezoneDateObject = localDate.add(0, 'hour');
    } else {
        currentTimezoneDateObject = localDate.add(1, 'hour');
    }
//'YYYY-MM-DDThh:mm A'
    /* Condition for Current Timezone Conversion */
    finalFormatedDate = momentTZ(currentTimezoneDateObject).tz(currentUser.timezone);
    let convertedTimezoneDate = momentTZ(finalFormatedDate).format(format).split('T');

    let capturedDate = convertedTimezoneDate[0];
    let capturedTime = convertedTimezoneDate[1];

    let finalFormattedResult = capturedDate + ', ' + capturedTime + ' ' + zone; //'YYYY-MM-DD HH:mm:ss (z)'

    return finalFormattedResult;

}


export const tzDateConverterTool_V2PreviewEvent = (date) => {

    let currentUser = getUser();
    let isPacificDaylightTime = toFindPDTAvailability(date);
    let currentTimezoneDateObject = null;
    let finalFormatedDate = null;

    const localDate = momentTZ(date);

    /* Condition for Pacific Daylight Time Conversion */
    if (isPacificDaylightTime) {
        currentTimezoneDateObject = localDate.add(0, 'hour');
    } else {
        currentTimezoneDateObject = localDate.add(1, 'hour');
    }

    /* Condition for Current Timezone Conversion */
    finalFormatedDate = momentTZ(currentTimezoneDateObject).tz(currentUser.timezone);
    let convertedTimezoneDayName = momentTZ(finalFormatedDate).format('hh:mm a'); //'YYYY-MM-DD HH:mm:ss (z)'
   
    return convertedTimezoneDayName;

}

//////////////////////////////


export const tzUTCDateTimeConverterTool_V1_Prod = (date, time, type) => {

    // let systemTimeZone = momentTZ.tz.guess(); // Identify the current system time zone
    let currentUser = getUser();
    // let startEndOfDayFormatedDate = null;
    let utcDateString = null;

    if (type === 'from') {
        // startEndOfDayFormatedDate = momentTZ(`${convertDateFormatV4(date)} ${time}`).startOf('day'); //'T00:00:00'
        utcDateString = momentTZ.tz(`${convertDateFormatV4(date)} ${time}`, currentUser.timezone).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss'); //'T00:00:00'
    } else if (type == 'to') {
        // startEndOfDayFormatedDate = momentTZ(`${convertDateFormatV4(date)} ${time}`).endOf('day'); //'T23:59:59'
        utcDateString = momentTZ.tz(`${convertDateFormatV4(date)} ${time}`, currentUser.timezone).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
    } else if (type == 'none') {
        utcDateString = momentTZ.tz(`${convertDateFormatV4(date)} ${time}`, currentUser.timezone).utc().format('YYYY-MM-DDTHH:mm:ss');
    }

    /* Condition for comparing syatem and user timezone */
    // if (systemTimeZone == currentUser.timezone) {

    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcDateString = startEndOfDayFormatedDate.add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcDateString = startEndOfDayFormatedDate.add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     }

    // } else {

    //     let currentTimezoneDateObject = startEndOfDayFormatedDate.tz(currentUser.timezone);
    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcDateString = currentTimezoneDateObject.add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcDateString = currentTimezoneDateObject.add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //     }

    // }

    return utcDateString;

}


export const tzUTCTimeConverterTool_V1_Prod = (date, time) => {

    // let systemTimeZone = moment.tz.guess(); // Identify the current system time zone
    let currentUser = getUser();
    let utcTimeObjectString = null;

    let combinedDateTimeObject = `${date}T${time}`;
    
    utcTimeObjectString = momentTZ.tz(combinedDateTimeObject, currentUser.timezone).utc().format('HH:mm:ss');
    
    /* Condition for comparing syatem and user timezone */
    // if (systemTimeZone == currentUser.timezone) {

    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcTimeObjectString = combinedDateTimeObject.add(7, 'hours').format('HH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcTimeObjectString = combinedDateTimeObject.add(5, 'hours').format('HH:mm:ss');
    //     }

    // } else {

    //     let currentTimezoneTimeObject = combinedDateTimeObject.tz(currentUser.timezone);
    //     /* Condition for Current Timezone Conversion */
    //     if (currentUser.timezone == TimeZones.PST) {
    //         utcTimeObjectString = currentTimezoneTimeObject.add(7, 'hours').format('HH:mm:ss');
    //     } else if (currentUser.timezone == TimeZones.CST) {
    //         utcTimeObjectString = currentTimezoneTimeObject.add(5, 'hours').format('HH:mm:ss');
    //     }

    // }

    return utcTimeObjectString;

}