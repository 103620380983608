import React, { useState } from 'react';
import TimesheetListTableScreen from './TimesheetListTableScreen';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Input } from '../../UI/InputElement/InputElement';
import { ConvertTimesheetListToCSVType, convertDateToPickerFormat, getOrdinalNumber } from '../../../../utility/utility';
import { Dialog, DialogContent } from '@material-ui/core';
import { tzTimeConversionTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
import { CSVLink } from "react-csv";
import ShowTimeSheetHoursType from './TimesheetHoursType/TimesheetHoursType';
import '../../../../styles/custom.css';
import TimesheetHoildayType from './TimesheetHolidayType/TimesheetHoildayType';

var moment = require('moment');
var feedGap = null;

let TimesheetListScreenLayout = (props) => {
    const { handleSubmit, resetClicked, handlingTimeUnits, timeSheetList } = props;
    const [openMealBreakModal, setMealBreakModalOpen] = useState(false);
    const [currentMealBreak, setCurrentMealBreak] = useState(null);
    const [currentTimeUnit, setCurrentTimeUnit] = useState('');
    const [isHoursType,setHoursType] = useState(false);
    const [isHolidayPay, setHolidayPay] = useState(false);
    const [selectedSift,setSelectedShifts]=useState(0)
    /* This functionality for meal breaks Ascending order */
    if (currentMealBreak) {
        feedGap = currentMealBreak?.sort((a) => {
            return a.id;
        })
    };

    const handleShowHoursType = (index) => {
        setHoursType(true);
        setSelectedShifts(index)
    }

    const handleShowHoildayType = (index) => {
        setSelectedShifts(index)
        setHolidayPay(true)
    }
    console.log("ST",props?.timeSheetList[selectedSift]?.shiftDetailJobs);
    return (
        <>

            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner frontlines_filter">
                    <form onSubmit={handleSubmit}>
                        <div className="row mt-2">
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <label>
                                    <Input name="fromDate" dateFormat="MM-dd-yyyy" placeholder="Start Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div class="input-group-append cst_group_append" style={{ height: "37px" }}>
                                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </label>
                            </div>
                            <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <label>
                                    <Input name="toDate" minDate={props.selectedValues.fromDate ? props.selectedValues.fromDate : null} dateFormat="MM-dd-yyyy" placeholder="End Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div class="input-group-append cst_group_append" style={{ height: "37px" }}>
                                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </label>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <button data-test="filter_go_btn" style={{ minWidth: '90px' }} onClick={() => { setCurrentTimeUnit(''); }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                                <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={() => { setCurrentTimeUnit(''); resetClicked(); }} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"}</button>
                            </div>
                        </div>
                    </form>
                </div>

                <section className="account_sec inspection_tab_content appcontent_Inner">
                    <div className="export_csv_block">
                        <div className="row d-flex justify-content-end">
                            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12" style={{ maxWidth: '360px' }}>
                                <div className="filter_block">
                                    <div className="list_filter">
                                        <a className={currentTimeUnit == 'week' ? props.isLoading ? "list_link active btn-wait" : 'list_link active' : 'list_link'} href="javascript:void(0)" onClick={() => { setCurrentTimeUnit('week'); handlingTimeUnits('week'); }}> {props.isLoading ? '' : 'Week'} </a>
                                        <a className={currentTimeUnit == 'month' ? props.isLoading ? "list_link active btn-wait" : 'list_link active' : 'list_link'} href="javascript:void(0)" onClick={() => { setCurrentTimeUnit('month'); handlingTimeUnits('month'); }}> {props.isLoading ? '' : 'Month'} </a>
                                    </div>
                                </div>
                                {timeSheetList?.length === 0 ? null : (
                                    // <CSVLink filename="Payroll.csv" className="btn cst_btn btn_danger " data={ConvertTimesheetListToCSVType(timeSheetList)} > Download Payroll CSV </CSVLink>
                                    <button className={props.isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"} onClick={() => { props.handleDownloadPayrollCsvClick(props, currentTimeUnit); }}> Download Payroll CSV </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="quotes_table_content accounts_table_contnet table-responsive">
                        <div className="table quotes_table user_react_table">
                            <TimesheetListTableScreen
                                {...props}
                                setMealBreakModalOpen={setMealBreakModalOpen}
                                setCurrentMealBreak={setCurrentMealBreak}
                                handleShowHoursType={handleShowHoursType}
                                handleShowHoildayType={handleShowHoildayType}
                            />
                        </div>
                    </div>
                </section>
            </main>

            <Dialog
                open={openMealBreakModal}
                aria-labelledby="form-dialog-title"
            >
                <div className="row p-2 m-0">
                    <div className="col-10">
                        <h4 className="md_title" style={{ color: '#4a4a4a', fontWeight: '500', fontSize: '35px' }}>Meal Break</h4>
                    </div>
                    <div className="col-2">
                        <span onClick={() => setMealBreakModalOpen(false)} className="float-right text-right mt-0 cursor-pointer"><i className="fa fa-times"></i></span>
                    </div>
                </div>
                <DialogContent>
                    <>
                        <div className="p-3">
                            <div className="mt-1 d-flex">
                                {
                                    feedGap?.map((item, index) => {
                                        let startMeal = item?.start !== null ? item.start : null;
                                        let endMeal = item?.end !== null ? item.end : null;
                                        let startDate = startMeal?.split('T')[0];
                                        let startTime = startMeal?.split('T')[1]?.split('.')[0];
                                        let endDate = endMeal?.split('T')[0];
                                        let endTime = endMeal?.split('T')[1]?.split('.')[0];

                                        let formattedStartMeal = startMeal == null ? '____' : tzTimeConversionTool_V1(startTime, startDate);
                                        let formattedEndMeal = endMeal == null ? '____' : tzTimeConversionTool_V1(endTime, endDate);
                                        return (
                                            <>
                                                <div className='d-flex flex-xl-column align-items-stretch'>
                                                    <label className="m-1" style={{ color: '#727272', fontWeight: 'bold' }}> {getOrdinalNumber(index + 1)} meal </label>
                                                    <label key={index} className="m-1 timeslot-btn">{formattedStartMeal + ' − ' + formattedEndMeal} </label>
                                                </div>
                                            </>
                                        );
                                    }
                                    )
                                }
                            </div>
                        </div>
                    </>
                </DialogContent>
                <div className=" p-2">
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setMealBreakModalOpen(false)} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Close</button>
                </div>
            </Dialog>

            {isHoursType && (
                 <Dialog
                 open={isHoursType}
                 aria-labelledby="form-dialog-title"
                 PaperProps={{
                    style: {
                      width: '700px', // Set desired width
                      height: '400px', // Set desired height
                    },
                  }}
             >
                 <div className="row p-2 m-0" style={{backgroundColor:'#141A22'}}>
                    <div className="col-10">
                        <h4 className="md_title" style={{ color: '#fff', fontSize: '22px' }}>Hours Type</h4>
                    </div>
                    <div className="col-2">
                        <span onClick={() => setHoursType(false)} className="float-right text-right mt-0 cursor-pointer"><i className="fa fa-times" style={{color:'white',fontSize:'25px', cursor:'pointer' }}></i></span>
                    </div>
                </div>
                <DialogContent className='grid-container_time_hoilday'>
                    <>
                        <ShowTimeSheetHoursType 
                            HoursJobs={props?.timeSheetList[selectedSift]?.shiftDetailJobs}
                        />
                    </>
                </DialogContent>
                <div className=" p-2">
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setHoursType(false)} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Close</button>
                </div>
             </Dialog>
 
            )}

            {isHolidayPay && (
                 <Dialog
                 open={isHolidayPay}
                 aria-labelledby="form-dialog-title"
                 PaperProps={{
                    style: {
                      width: '600px', // Set desired width
                      height: '400px', // Set desired height
                    },
                  }}
             >
                 <div className="row p-2 m-0" style={{backgroundColor:'#141A22'}}>
                    <div className="col-10">
                        <h4 className="md_title" style={{ color: '#fff', fontSize: '22px' }}>Pay</h4>
                    </div>
                    <div className="col-2">
                        <span onClick={() => setHolidayPay(false)} className="float-right text-right mt-0 cursor-pointer"><i className="fa fa-times" style={{color:'white',fontSize:'25px', cursor:'pointer' }}></i></span>
                    </div>
                </div>
                <DialogContent className='grid-container_time_hoilday'>
                    <>
                        <TimesheetHoildayType 
                            HoursJobs={props?.timeSheetList[selectedSift]?.shiftDetailJobs}
                        />
                    </>
                </DialogContent>
                <div className=" p-2">
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setHolidayPay(false)} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Close</button>
                </div>
             </Dialog>
 
            )}

        </>
    );
};

const mapStateToProps = (state) => {
    let fromDate = state?.TimeTrackerModReducer?.timeSheetList_searchValues?.fromDate ? convertDateToPickerFormat(state.TimeTrackerModReducer.timeSheetList_searchValues.fromDate) : "";
    let toDate = state?.TimeTrackerModReducer?.timeSheetList_searchValues?.toDate ? convertDateToPickerFormat(state.TimeTrackerModReducer.timeSheetList_searchValues.toDate) : "";

    return { initialValues: { fromDate, toDate }, formStates: getFormValues('searchTimesheetListForm')(state) }
}

TimesheetListScreenLayout = reduxForm({
    form: 'searchTimesheetListForm',
    enableReinitialize: true
})(TimesheetListScreenLayout);

const selector = formValueSelector('searchTimesheetListForm')
TimesheetListScreenLayout = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
})(TimesheetListScreenLayout)

TimesheetListScreenLayout = connect(mapStateToProps)(TimesheetListScreenLayout)

export default TimesheetListScreenLayout;