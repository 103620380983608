import { AdminOrSuperAdminActionTypes } from '../../actions/AdminOrSuperAdminActions/AdminOrSuperAdminActionType';
import storage from '../../../utility/storage';
import actions from 'redux-form/lib/actions';
const cloneDeep = require('clone-deep');

export const initialState = {

    dataGenerated: false,
    toggleSideBar: false,
    toggleSettings: false,
    inspectionToggleSettings: false,
    ticketToggle: false,
    pegAssureTabToggle: false,
    quoteApproved: false,
    quoteErrorMessage: null,
    toggleKpi: false,
    dashboardData: null,
    inspectionDashboardData: null,
    ticketDashboardData: null,
    quoteDashboardData: null,
    logbookDashboardData: null,
    kpiInspectionData: [],
    kpiInspectionCount: [],
    kpiInspectionQQvScore: [],
    kpiInspectionRootcaseData: [],
    kpiInspectionDeficencyData: [],
    kpiInspectionAverageScore: [],
    kpiInspectionDateData: [],
    kpiTopInspectionData: [],
    kpiTopInspector: [],
    kpiInspectionAccountData: [],
    kpiInspectionPieChartData: [],
    kpiQualityInspectionData: [],
    kpiAverageSectionQualityData: [],
    kpiAverageSectionSafetyData: [],
    avgModalData: [],
    kpiInspectionCustomerData: [],
    kpiInspectionQQvData: [],

    kpiTicketCountData: [],
    kpiTicketGraphData: [],
    kpiTicketDaysOpenData: [],
    kpiTicketHightUsersData: [],
    kpiTicketJobData: [],

    currentDate: new Date(),
    isWeek: true,
    isMonth: false,
    isYear: false,
    is90Days: false,
    isCustomDate: false,
    isEdit: false,
    data_list: null,
    fields: null,
    userInspectionIndex: null,
    inspectionIndex: null,
    isNewUser: false,
    isLoading: false,
    popupLoading: false,

    ratingList: null,
    hideRating: true,
    totalRatings: "",
    searchedRatingList: null,
    rating: {},
    isRating: false,

    inspectionFormsList: null,
    inspectionFormTableList: [],
    totalInspectionForms: "",
    totalCategories: "",
    isInspectionFormPending: false,
    isInspectionForm: false,
    searchedInspectionFormsList: null,
    inspectionForm: {},

    positionsList: null,
    totalPositions: '',
    totalRegions: '',
    totalHolidays: '',
    totalFloors: '',
    totalAreaTypes: '',
    totalDeficiencies: '',
    position: {},
    accountData: null,
    ticketIndex: null,
    scheduleTicketIndex: null,
    previewScheduleTicketIndex: null,
    prior_ticketIndex: null,

    usersList: null,
    totalUsers: null,
    dropDownUsersList: null,
    isUserLoading: false,
    SearchOrResetUserClicked: false,
    SearchOrResetPegassureClicked: false,
    searchedUsersList: null,
    users_page: 1,
    sort_users_column: '',
    users_order: '',
    users_no_of_rows: 10,
    requestor: {},
    inspectionCurPage: 0,
    ratingCurPage: 0,
    inspectionFormCurPage: 0,
    accountCurPage: 0,
    logbookAreaCurPage: 0,
    userCurPage: 0,
    frontlineUserCurPage: 0,
    ticketCurPage: 0,
    scheduleTicketCurPage: 0,
    previewScheduleTicketCurPage: 0,
    quoteCurPage: 0,
    company_cur_page: 0,
    companySearchValues: {},

    accountsList: null,
    modeAccountsList: null,
    companyAccounts: null,
    totalAccounts: null,
    dropDownAccountsList: null,
    dropDownCustomerList: null,
    isAccountLoading: false,
    accounts_rows: 10,
    logbook_rows: 10,
    account_page: 1,
    account: {},
    accountHolidaysList: [],
    accountJobsList: [],
    SearchOrResetAccountClicked: false,
    userAccountLists: null,
    searchedAccountList: null,
    accountUsersList: null,
    SearchOrResetAccountUsersClicked: false,
    account_users_page: 1,
    account_users_row: 10,
    searchedAccountUsersList: null,
    filterAccountUsers: null,
    openAccountUserModal: false,
    openPasswordModal: false,
    accountDuplicateModal: false,
    userDuplicateModal: false,
    inspectionDuplicateModal: false,
    openAccountHolidaysModal: false,
    openAccountJobsModal: false,
    isFilterAccountUsersPending: false,

    ticketsList: [],
    scheduleTicketList: null,
    previewScheduleTicketList: null,
    prior_ticketsList: null,
    totalTickets: null,
    scheduleTotalTickets: null,
    previewScheduleTotalTickets: null,
    prior_totalTickets: null,
    searchTicketValues: null,
    scheduleSearchTicketValues: null,
    previewScheduleSearchTicketValues: null,
    searchQuoteValues: null,
    ticket_rows: null,
    schedule_ticket_rows: null,
    preview_schedule_ticket_rows: null,
    feedbacks_rows: null,
    quote_rows: null,
    ins_form_rows: null,
    job_rows: null,
    fl_user_rows: null,
    user_rows: null,
    searchTicketsList: null,
    openTicketModal: false,
    ticket_page: 1,
    schedule_ticket_page: 1,
    preview_schedule_ticket_page: 1,
    ticket_no_of_rows: 10,
    schedule_ticket_no_of_rows: 10,
    preview_schedule_ticket_no_of_rows: 10,
    rating_no_of_rows: 10,
    dashboardTicketCount: null,
    SearchOrResetTicektClicked: false,
    SearchOrResetScheduleClicked: false,
    searchUserValues: {},
    ticket: {},
    openPasswordModal: false,
    dashboardQuoteCount: null,
    chiChartData: [],
    chi_total_no_of_pages: null,
    chi_cur_page: null,
    inspectionsList: null,
    totalInspections: null,
    searchInspectionValues: null,
    searchLogbookValues: null,
    inspection_rows: null,
    inspection_no_of_rows: 10,
    inspection_page: 1,
    insform_no_of_rows: 10,
    insform_page: 1,
    sort_inspection_column: '',
    inspection_order: '',
    isInspectionLoading: false,
    searchedInspectionList: null,
    searchOrResetInspectionClicked: false,
    SearchOrResetInsFormClicked: false,
    inspection: {},
    prformInspectionLoading: false,
    openSignaturePad: false,
    signatureData: {},
    inspectionReports: null,
    overall_inspection_score: '',
    lowest_performing_account_name: '',
    priorTicket: {},
    viewPriorTicket: false,
    openTicketCount: null,
    acceptedTicketCount: null,
    resolvedTicketCount: null,

    quotesList: null,
    totalQuotes: null,
    searchedQuoteList: null,
    quotes_rows: 10,
    quotes_page: 1,
    quote: {},
    totalSalesArray: [],
    openRejectQuoteModel: false,
    filteredQuotesList: null,
    openQuotesModal: false,
    sort_quotes_column: '',
    quotes_order: '',
    searchOrResetQuoteClicked: false,
    resendEmailLoading: false,
    getQuoteLoading: false,
    latestInspectionId: '',
    companiesList: null,
    companyName: null,
    totalCompanies: null,
    companyDropdownList: null,
    searchedCompanyList: null,
    company: {},
    company_page: 1,
    company_rows: 10,
    SearchOrResetCompanyClicked: false,
    filterCompanyUsers: null,
    non_associated_company_accounts: null,
    openAssociateUserCompanyModal: false,
    companyUsersList: null,
    company_users_page: 1,
    company_users_row: 10,
    searchedCompanyUsersList: null,
    total_company_users: null,
    total_no_of_pages: null,
    SearchOrResetCompanyUsersClicked: false,
    SearchOrResetLogbookClicked: false,
    isFilterCompanyUsersPending: false,
    // openAssociateCompanyModal: false,
    // filteredCompanyUsers: null,
    quotesReport: null,
    an_account_with_zero_tags_sold: 0,
    quotes_created_vs_quotes_approved: 0,
    quotes_tag_created: 0,
    quotes_vs_approved_not_approved_by_the_client: 0,
    quotes_vs_quotes_rejected_by_admin: 0,
    tags_confirmed_by_client: 0,
    quotasForQuotes: [],

    categoriesList: null,
    searchedCategoriesList: null,
    category: {},
    isCategories: false,
    isPositions: false,
    isRegions: false,
    isCriteria: false,
    totalAccountUsers: "",
    totalAccountAreas: "",
    totalLogbookAreas: "",
    logbookLogs: [],
    totalLogbookLogs: "",
    isLogbookLogs: false,
    totalAccountHolidays: "",

    openDeleteModel: false,
    openConfirmDeleteModel: false,
    openScheduleConfirmDeleteModel: false,
    openApproveModal: false,

    deleteObject: null,

    regionList: null,
    region: {},
    quoteLog: null,
    isQuotesLogLoading: null,

    isOpenModel: false,
    accountAreas: [],
    logbookAreas: [],
    accountArea: null,
    areaTypes: [],
    deficiencies: [],
    accountAreaTypes: [],
    areaSearchResult: null,
    searchHistory: null,

    frontlineList: [],
    totalfrontline: '',
    frontlineUser: null,
    floorList: [],
    frontLineRows: 10,
    SearchOrResetFrontLineUsersClicked: false,
    accountFloorList: [],
    areaNames: [],
    floor: null,
    areaType: null,
    deficiency: null,
    isAccountAreaTab: false,
    isAccountHolidayTab: false,
    total_no_of_pages: 1,
    scanAnalyticsData: null,
    allAreaData: [],
    nonLoggedInFrontlineData: [],
    areaOfAccount: {},
    upcommingAreaScheduleList: [],

    holidaysList: null,
    holiday: {},
    logbookLog: {},
    isHolidaysLoading: false,

    chi_servyLoading: false,
    chi_review_loading: false,
    chiServyList: [],
    chi_total_count: null,
    chi_page: 1,
    chi_no_of_rows: 10,
    allQRCodes: null,
    accountExportData: null,
    userExportData: null,
    activeJobs: true,
    activeUsers: true,
    viewQuoteStatus: false,
    chi: {},
    accountAdminList: null,
    dataItems: [],
    fields: [],
    convertedData: [],
    inspectionPdfUrl: null,
    activeCompanies: true,
    activeCompanyAccess: true,
    searchRatingValues: '',
    kpiSearchValues: '',
    kpiSectionValues: '',
    companyList: null,
    qualityModalData: [],
    averageGraphInspections: [],
    inspection_mode: null,
    schedule: {},
    getScheduleList: [],
    getScheduleEventsList: [],
    scheduleId: [],
    scheduleWithAccountId: [],
    account_id_info: null,
    account_name_info: null,
    currentAccount: null,
    getScheduleEmailEventsList: [],
    getScheduleDashboardData: [],
    schedule_page: 1,
    schedule_no_of_rows: 10,
    totalschedules: null,
    scheduleCurPage: 0,
    schedule_rows: null,
    isSchedulePending: false,
    scheduleCheckValues: null,
    openScheduleOccurrenceModal: false,
    openScheduleOutlookConfirmModal: false,
    isScheduleHistoryLoading: false,
    scheduleHistory_logList: [],
    scheduleHistory_totalListCount: null,
    scheduleHistoryCurPage: 0,
    isAccountOwner: false,
    completeApporveModal: false,

    latestInspectionList: '',
    isLogbookAreas: false,
    logbookAreasList: [],
    total_logbookArea_pages: null,
    viewLogbookQrScan: false,
    isLogbookLogsAreas: false,

    logbookNames: [],
    searchLogHistory: [],
    openCameraModal: false,
    openDeficiencyModal: true,
    pegassureFeedbacks: [],
    searchfeedbacksValues: null,
    feedback: {},
    feedbacks_no_of_rows: 10,
    logbookHistoryDashboard: null,
    total_reviewed: 0,
    total_pending: 0,
    areaImportErrorLogs: [],
    logbookImportErrorLogs: [],
    isErrorAvailable: false,
    scheduleTicket: {},
    getScheduleTicketEventsList: [],
    scheduleTicketOneEvent: null,
    deletingScheduleTicketId: null,
    openScheduleTicketDeleteModal: false,
    deleteScheduleTicketEventAction: false,
    deleteEntireScheduleTicketEventsAction: false,

    isScheduleTicketHistoryLoading: false,
    scheduleTicketHistory_logList: [],
    scheduleTicketHistory_totalListCount: null,
    scheduleTicketHistoryCurPage: 0,
    scheduleTicketId: null,
    scheduleTitle: null,
    toggleCustomer: false,

    companyAccessList: null,
    company_access_page: 1,
    company_access_rows: 10,
    company_access_cur_page: 0,
    companyAccessSearchValues: {},
    total_company_access: null,
    companyAccess: {},
    editCompanyAccessId: null,

    logbookPreviewCurPage: 0,

    chiSurveyCheck: null,
    ticketImportErrorLogs: [],
    frontlineToggle: false,

    qqvQuestion: [],

    projectModuleTicketCurPage: 0,
    previewProjectModuleTicketCurPage: 0,
    project_module_ticket_no_of_rows: 10,

    flShift_attestationQQV: [],
    weekly_limit_modal: false,
    logbookLogQuestions : [],
    language : false,
    frontline_active_user_name:null
};

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps
    }
}

export const adminOrSuperAdminReducer = (state = initialState, action) => {
    switch (action.type) {

        case AdminOrSuperAdminActionTypes.SIDE_BAR_CLICKED:
            return updateObject(state, { toggleSideBar: !state.toggleSideBar })

        case AdminOrSuperAdminActionTypes.SETTINGS_CLICKED:
            return updateObject(state, { toggleSettings: !state.toggleSettings })
        case AdminOrSuperAdminActionTypes.KPI_CLICKED:
            return updateObject(state, { toggleKpi: !state.toggleKpi })
        case AdminOrSuperAdminActionTypes.OPEN_DELETE_MODEL:
            return updateObject(state, { deleteObject: action.payload, openDeleteModel: true })
        case AdminOrSuperAdminActionTypes.OPEN_CONFIRM_DELETE_MODEL:
            return updateObject(state, { deleteObject: action.payload, openConfirmDeleteModel: true })
        case AdminOrSuperAdminActionTypes.OPEN_CONFIRM_SCHEDULE_DELETE_MODEL:
            return updateObject(state, { deleteObject: action.payload, openScheduleConfirmDeleteModel: true })
        case AdminOrSuperAdminActionTypes.CLOSE_DELETE_CLOSE:
            return updateObject(state, { openDeleteModel: false, openConfirmDeleteModel: false, openScheduleConfirmDeleteModel: false, openScheduleOccurrenceModal: false, openScheduleOutlookConfirmModal: false, openScheduleTicketDeleteModal: false, deleteScheduleTicketEventAction: false, deleteEntireScheduleTicketEventsAction: false })
        case AdminOrSuperAdminActionTypes.OPEN_APPROVE_MODEL:
            return updateObject(state, { deleteObject: action.payload, openApproveModal: true })
        case AdminOrSuperAdminActionTypes.CLOSE_APPROVE_CLOSE:
            return updateObject(state, { openApproveModal: false })

        case AdminOrSuperAdminActionTypes.GET_DASHBOARD_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { isLoading: false, dashboardData: action.payload })

        case AdminOrSuperAdminActionTypes.GET_INSPECTION_DASHBOARD_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_INSPECTION_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { isLoading: false, inspectionDashboardData: action.payload })

        case AdminOrSuperAdminActionTypes.GET_TICKET_DASHBOARD_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_TICKET_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { isLoading: false, ticketDashboardData: action.payload })

        case AdminOrSuperAdminActionTypes.GET_QUOTE_DASHBOARD_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_QUOTE_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { isLoading: false, quoteDashboardData: action.payload })

        case AdminOrSuperAdminActionTypes.GET_LOGBOOK_DASHBOARD_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_LOGBOOK_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { isLoading: false, logbookDashboardData: action.payload })

        case AdminOrSuperAdminActionTypes.GET_LOGBOOK_HISTORY_DASHBOARD_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_LOGBOOK_HISTORY_DASHBOARD_FULFILLED:
            return updateObject(state, { isLoading: false, logbookHistoryDashboard: action.payload })

        case AdminOrSuperAdminActionTypes.GET_UPDATED_DATA:
            return updateObject(state, { dataGenerated: true })

        case AdminOrSuperAdminActionTypes.RESET_SEARCH_OR_RESET:
            return updateObject(state, {
                searchOrResetInspectionClicked: false, SearchOrResetInsFormClicked: false,
                searchOrResetQuoteClicked: false, SearchOrResetUserClicked: false,
                SearchOrResetTicektClicked: false, SearchOrResetAccountClicked: false,
                SearchOrResetCompanyClicked: false, SearchOrResetAccountUsersClicked: false,
                SearchOrResetFrontLineUsersClicked: false, SearchOrResetCompanyUsersClicked: false,
                SearchOrResetScheduleClicked: false, isScheduleHistoryLoading: false,
                SearchOrResetPegassureClicked: false, SearchOrResetLogbookClicked: false,
                isScheduleTicketHistoryLoading: false,
            })

        case AdminOrSuperAdminActionTypes.UPDATE_CURRENT_DATE:
            return updateObject(state, { currentDate: action.payload })

        case AdminOrSuperAdminActionTypes.UPDATE_WEEK_MONTH_YEAR:
            return updateObject(state, { isWeek: action.payload.isWeek, isMonth: action.payload.isMonth, isYear: action.payload.isYear, is90Days: action.payload.is90Days, isCustomDate: action.payload.isCustomDate })

        case AdminOrSuperAdminActionTypes.GET_TICKETS_PENDING:
            return updateObject(state, { isLoading: true, isTicketPending: true })
        case AdminOrSuperAdminActionTypes.GET_TICKETS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isTicketPending: false,
                dataGenerated: false,
                ticketsList: action.payload && action.payload.tickets ? action.payload.tickets : null,
                ticketIndex: action.payload && action.payload.ticketIds ? action.payload.ticketIds : null,
                ticket_page: action.payload && action.payload.ticket_page ? action.payload.ticket_page : null,
                ticket_no_of_rows: action.payload && action.payload.ticket_no_of_rows ? action.payload.ticket_no_of_rows : state.ticket_no_of_rows,
                totalTickets: action.payload && action.payload.totalTickets ? action.payload.totalTickets : null,
                ticket_rows: action.payload && action.payload.ticket_rows ? action.payload.ticket_rows : null,
                searchTicketValues: action.payload && action.payload.searchTicketValues ? action.payload.searchTicketValues : null,
                dashboardTicketCount: action.payload && action.payload.dashboardTicketCount ? action.payload.dashboardTicketCount : null,
                scheduleSearchTicketValues: null,
                previewScheduleSearchTicketValues: null,
                searchInspectionValues: null,
                searchQuoteValues: null,
                searchaccountValues: null,
                searchUserValues: {},
                // sort_users_column: action.payload.sort_users_column,
                // users_order: action.payload.users_order,
            })


        case AdminOrSuperAdminActionTypes.SEARCH_TICKETS_PENDING:
            return updateObject(state, { isLoading: true, isTicketPending: true, SearchOrResetTicektClicked: true })
        case AdminOrSuperAdminActionTypes.SEARCH_TICKETS_FULFILLED:
            return updateObject(state, {
                isTicketPending: false,
                isLoading: false,
                ticketsList: action.payload.tickets,
                ticketIndex: action.payload.ticketIds,
                ticket_page: action.payload.ticket_page,
                ticket_no_of_rows: action.payload.ticket_no_of_rows ? action.payload.ticket_no_of_rows : state.ticket_no_of_rows,
                totalTickets: action.payload.totalTickets,
                searchTicketValues: action.payload.searchTicketValues.value,
                ticket_rows: action.payload.ticket_rows ? action.payload.ticket_rows : null
                // sort_users_column: action.payload.sort_users_column,
                // users_order: action.payload.users_order,
            })

        case AdminOrSuperAdminActionTypes.SEARCH_PRIOR_ESCALATION_TICKETS_PENDING:
            return updateObject(state, { isLoading: true, isPriorTicketPending: true, SearchOrResetTicektClicked: true })
        case AdminOrSuperAdminActionTypes.SEARCH_PRIOR_ESCALATION_TICKETS_FULFILLED:
            return updateObject(state, {
                isPriorTicketPending: false,
                isLoading: false,
                prior_ticketsList: action.payload.tickets,
                prior_ticketIndex: action.payload.ticketIds,
                ticket_page: action.payload.ticket_page,
                ticket_no_of_rows: action.payload.ticket_no_of_rows ? action.payload.ticket_no_of_rows : state.ticket_no_of_rows,
                prior_totalTickets: action.payload.totalTickets,
                searchTicketValues: action.payload.searchTicketValues.value,
                ticket_rows: action.payload.ticket_rows ? action.payload.ticket_rows : null
            })

        case AdminOrSuperAdminActionTypes.ADD_NEW_TICKET_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_NEW_TICKET_FULFILLED:
            return updateObject(state, { isLoading: false, ticketsList: action.payload && action.payload.ticketsList ? action.payload.ticketsList : state.ticketsList, inspection: action.payload && action.payload.inspection ? action.payload.inspection : state.inspection, ticket: {} })

        case AdminOrSuperAdminActionTypes.EDIT_TICKET_CLICKED:
            return updateObject(state, { ticket: action.payload })

        case AdminOrSuperAdminActionTypes.GET_EDIT_TICKET_PENDING:
            return updateObject(state, { isLoading: true, ticket: {} })
        case AdminOrSuperAdminActionTypes.GET_EDIT_TICKET_FULFILLED:
            return updateObject(state, { isLoading: false, ticket: action.payload })

        case AdminOrSuperAdminActionTypes.GET_TICKET_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })

        case AdminOrSuperAdminActionTypes.GET_QUOTE_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })


        case AdminOrSuperAdminActionTypes.UPDATE_TICKET_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_TICKET_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                ticketsList: action.payload && action.payload.TicketsList ? action.payload.TicketsList : [],
                searchTicketsList: action.payload && action.payload.TicketsList ? action.payload.TicketsList : null,
                inspection: action.payload && action.payload.inspection ? action.payload.inspection : {},
                chiServyList: action.payload && action.payload.serveyList ? action.payload.serveyList : state.chiServyList,
                // serveyList: action.payload && action.payload.serveyList ? action.payload.serveyList : state.serveyList,              
                ticket: action.payload && action.payload.Ticket ? action.payload.Ticket : {},
                chi: action.payload && action.payload.chi ? action.payload.chi : null,
                searchTicketValues: action.payload && action.payload.searchTicketValues ? action.payload.searchTicketValues : null,
            })

        case AdminOrSuperAdminActionTypes.PREVIEW_TICKET:
            return updateObject(state, {})

        case AdminOrSuperAdminActionTypes.PREVIEW_SINGLE_TICKET:
            return updateObject(state, { scheduleTitle: action.payload.title, scheduleTicketId: action.payload.id })

        case AdminOrSuperAdminActionTypes.DELETE_PORTFORLIO_IMAGES_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_PORTFORLIO_IMAGES_FULFILLED:
            return updateObject(state, { isLoading: true })

        case AdminOrSuperAdminActionTypes.DELETE_TICKET_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_TICKET_FULFILLED:
            return updateObject(state, {
                isLoading: false, ticketsList: action.payload.TicketsList ? action.payload.TicketsList : state.ticketsList,
                searchTicketsList: action.payload.SearchedTicketList ? action.payload.SearchedTicketList : state.searchTicketsList,
                openDeleteModel: false
            })

        case AdminOrSuperAdminActionTypes.RESOLVE_TICKET_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.RESOLVE_TICKET_FULFILLED:
            return updateObject(state, { ticketsList: action.payload && action.payload.TicketsList ? action.payload.TicketsList : [], ticket: action.payload.Ticket ? action.payload.Ticket : null, searchTicketValues: action.payload.searchTicketValues, chi: action.payload.Ticket ? action.payload.Ticket : null, isLoading: action.payload.IsLoading ? action.payload.IsLoading : false, chiServyList: action.payload.serveyList ? action.payload.serveyList : state.chiServyList })

        case AdminOrSuperAdminActionTypes.CANCEL_TICKET_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.CANCEL_TICKET_FULFILLED:
            return updateObject(state, { isLoading: false })

        case AdminOrSuperAdminActionTypes.GET_INSPECTIONS_LIST_PENDING:
            return updateObject(state, { isLoading: true, isInspectionLoading: true, convertedData: [] })
        case AdminOrSuperAdminActionTypes.GET_INSPECTIONS_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false, isInspectionLoading: false,
                dataGenerated: false,
                inspectionsList: action.payload.inspectionsList,
                inspectionIndex: action.payload.inspectionIndex,
                inspection_page: action.payload.inspection_page,
                inspection_no_of_rows: action.payload.inspection_no_of_rows ? action.payload.inspection_no_of_rows : state.inspection_no_of_rows,
                sort_inspection_column: action.payload.sort_inspection_column,
                inspection_order: action.payload.inspection_order,
                totalInspections: action.payload.totalInspections,
                inspection_rows: action.payload.inspection_rows ? action.payload.inspection_rows : null,
                searchInspectionValues: action.payload.searchInspectionValues,
                searchTicketValues: null,
                scheduleSearchTicketValues: null,
                previewScheduleSearchTicketValues: null,
                searchQuoteValues: null,
                searchaccountValues: null,
                searchUserValues: {},
                inspectionCurPage: action.payload.inspectionCurPage,
                convertedData: [],
                inspection_mode: action.payload.inspection_mode
            })
        case AdminOrSuperAdminActionTypes.GET_PENDING_INSPECTIONS_LIST_PENDING:
            return updateObject(state, { isLoading: true, isInspectionLoading: true, convertedData: [] })
        case AdminOrSuperAdminActionTypes.GET_PENDING_INSPECTIONS_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false, isInspectionLoading: false,
                dataGenerated: false,
                inspectionsList: action.payload.inspectionsList,
                inspectionIndex: action.payload.inspectionIndex,
                inspection_page: action.payload.inspection_page,
                inspection_no_of_rows: action.payload.inspection_no_of_rows ? action.payload.inspection_no_of_rows : state.inspection_no_of_rows,
                sort_inspection_column: action.payload.sort_inspection_column,
                inspection_order: action.payload.inspection_order,
                totalInspections: action.payload.totalInspections,
                inspection_rows: action.payload.inspection_rows ? action.payload.inspection_rows : null,
                searchInspectionValues: action.payload.searchInspectionValues,
                searchTicketValues: null,
                scheduleSearchTicketValues: null,
                previewScheduleSearchTicketValues: null,
                searchQuoteValues: null,
                searchaccountValues: null,
                searchUserValues: {},
                inspectionCurPage: action.payload.inspectionCurPage,
                convertedData: [],
                inspection_mode: action.payload.inspection_mode
            })
        case AdminOrSuperAdminActionTypes.GET_MYINSPECTIONS_LIST_PENDING:
            return updateObject(state, { isLoading: true, isInspectionLoading: true, convertedData: [] })
        case AdminOrSuperAdminActionTypes.GET_MYINSPECTIONS_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false, isInspectionLoading: false,
                dataGenerated: false,
                inspectionsList: action.payload.inspectionsList,
                inspectionIndex: action.payload.inspectionIndex,
                inspection_page: action.payload.inspection_page,
                inspection_no_of_rows: action.payload.inspection_no_of_rows ? action.payload.inspection_no_of_rows : state.inspection_no_of_rows,
                sort_inspection_column: action.payload.sort_inspection_column,
                inspection_order: action.payload.inspection_order,
                totalInspections: action.payload.totalInspections,
                inspection_rows: action.payload.inspection_rows ? action.payload.inspection_rows : null,
                searchInspectionValues: action.payload.searchInspectionValues,
                searchTicketValues: null,
                scheduleSearchTicketValues: null,
                previewScheduleSearchTicketValues: null,
                searchQuoteValues: null,
                searchaccountValues: null,
                searchUserValues: {},
                inspectionCurPage: action.payload.inspectionCurPage,
                convertedData: [],
                inspection_mode: action.payload.inspection_mode
            })
        case AdminOrSuperAdminActionTypes.GET_DASHBOARD_INSPECTIONS_LIST_PENDING:
            return updateObject(state, { isLoading: true, isInspectionLoading: true })
        case AdminOrSuperAdminActionTypes.GET_DASHBOARD_INSPECTIONS_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false, isInspectionLoading: false,
                dataGenerated: false,
                inspectionsList: action.payload.inspectionsList,
                inspection_page: action.payload.inspection_page,
                inspection_no_of_rows: action.payload.inspection_no_of_rows ? action.payload.inspection_no_of_rows : state.inspection_no_of_rows,
                sort_inspection_column: action.payload.sort_inspection_column,
                inspection_order: action.payload.inspection_order,
                totalInspections: action.payload.totalInspections
            })

        case AdminOrSuperAdminActionTypes.GET_INSPECTION_FORM_LIST_PENDING:
            return updateObject(state, { isLoading: true, isInspectionForm: true, inspectionFormTableList: [] })
        case AdminOrSuperAdminActionTypes.GET_INSPECTION_FORM_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false, isInspectionForm: false,
                dataGenerated: false,
                convertedData: [],
                inspectionFormTableList: action.payload.inspectionFormsList,
                insform_page: action.payload.insform_page,
                insform_no_of_rows: action.payload.insform_no_of_rows,
                totalInspectionForms: action.payload.totanInsForms,
                searchTicketValues: null,
                scheduleSearchTicketValues: null,
                previewScheduleSearchTicketValues: null,
                searchInspectionValues: null,
                searchQuoteValues: null,
                searchUserValues: {},
                searchaccountValues: null,
                ins_form_rows: action.payload.ins_form_rows ? action.payload.ins_form_rows : null
            })

        // case AdminOrSuperAdminActionTypes.GET_INSPECTION_FORMS_PENDING:
        //     return updateObject(state, { isLoading: true, isInspectionForm: true, inspectionFormsList: [] })
        // case AdminOrSuperAdminActionTypes.GET_INSPECTION_FORMS_FULFILLED:
        //     return updateObject(state, { isLoading: false, isInspectionForm: false, totalInspectionForms: action.payload.count, inspectionFormsList: action.payload.inspection_forms })


        case AdminOrSuperAdminActionTypes.GET_SEARCHED_INSPECTIONS_LIST_PENDING:
            return updateObject(state, { isLoading: true, isInspectionLoading: true, searchOrResetInspectionClicked: true })
        case AdminOrSuperAdminActionTypes.GET_SEARCHED_INSPECTIONS_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false, isInspectionLoading: false,
                inspectionsList: action.payload.inspectionsList,
                inspectionIndex: action.payload.inspectionIndex,
                inspection_page: action.payload.inspection_page,
                inspection_no_of_rows: action.payload.inspection_no_of_rows ? action.payload.inspection_no_of_rows : state.inspection_no_of_rows,
                sort_inspection_column: action.payload.sort_inspection_column,
                inspection_order: action.payload.inspection_order,
                totalInspections: action.payload.totalInspections,
                searchInspectionValues: action.payload.searchInspectionValues,
                inspection_rows: action.payload.inspection_rows
            })

        case AdminOrSuperAdminActionTypes.NEW_INSPECTION_QUOTE_CLICKED:
            return updateObject(state, { openQuotesModal: true, lineItemId: action.payload })

        case AdminOrSuperAdminActionTypes.NEW_INSPECTION_TICKET_CLICKED:
            return updateObject(state, { openTicketModal: true, lineItemId: action.payload })

        case AdminOrSuperAdminActionTypes.SIGNATURE_PAD_CLICKED:
            return updateObject(state, { openSignaturePad: true, signatureData: action.payload })

        case AdminOrSuperAdminActionTypes.UPLOAD_LINE_ITEMS_IMAGE_TO_S3_PENDING:
            return updateObject(state, {})
        case AdminOrSuperAdminActionTypes.UPLOAD_LINE_ITEMS_IMAGE_TO_S3_FULFILLED:
            if (action.payload) {
                return updateObject(state, {
                    inspection: action.payload ? cloneDeep(action.payload.Inspection) : cloneDeep(state.inspection),
                    prformInspectionLoading: action.payload ? action.payload.isLoading : true,
                    openSignaturePad: false
                })
            }


        case AdminOrSuperAdminActionTypes.ADD_PERFORM_INSPECTION_PENDING:
            return updateObject(state, { isLoading: true, isInspectionLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_PERFORM_INSPECTION_FULFILLED:
            return updateObject(state, { isInspectionLoading: false })

        case AdminOrSuperAdminActionTypes.SET_INSPECTION_LOADING_FALSE:
            return updateObject(state, { isInspectionLoading: false })

        case AdminOrSuperAdminActionTypes.UPDATE_INSPECTION_PENDING:
            return updateObject(state, { prformInspectionLoading: true, isLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_INSPECTION_FULFILLED:
            return updateObject(state, {
                isInspectionLoading: action.payload && action.payload.isInspectionLoading ? action.payload.isInspectionLoading : false,
                inspectionsList: action.payload && action.payload.InspectionsList ? action.payload.InspectionsList : null,
                prformInspectionLoading: action.payload && action.payload.isLoading ? action.payload.isLoading : false,
                isLoading: false,
                completeApporveModal: false
            })

        case AdminOrSuperAdminActionTypes.SET_INSPECTION:
            return updateObject(state, { inspection: action.payload, convertedData: [] })

        case AdminOrSuperAdminActionTypes.EDIT_INSPECTION_CLICKED:
            return updateObject(state, { convertedData: [] })

        case AdminOrSuperAdminActionTypes.INSPECTION_PAGE_CLICKED:
            return updateObject(state, { inspectionCurPage: action.payload, convertedData: [] })

        case AdminOrSuperAdminActionTypes.RATING_PAGE_CLICKED:
            return updateObject(state, { ratingCurPage: action.payload.page, rating_no_of_rows: action.payload.rows })

        case AdminOrSuperAdminActionTypes.INSPECTION_FORM_PAGE_CLICKED:
            return updateObject(state, { inspectionFormCurPage: action.payload })

        case AdminOrSuperAdminActionTypes.ACCOUNT_PAGE_CLICKED:
            return updateObject(state, { accountCurPage: action.payload })

        case AdminOrSuperAdminActionTypes.USER_PAGE_CLICKED:
            return updateObject(state, { userCurPage: action.payload })

        case AdminOrSuperAdminActionTypes.FRONTLINE_USER_PAGE_CLICKED:
            return updateObject(state, { frontlineUserCurPage: action.payload })

        // case AdminOrSuperAdminActionTypes.SET_INSPECTION_FORM:
        //     return updateObject(state, { inspectionForm: action.payload })

        case AdminOrSuperAdminActionTypes.TICKET_PAGE_CLICKED:
            return updateObject(state, { ticketCurPage: action.payload })

        case AdminOrSuperAdminActionTypes.SCHEDULE_TICKET_PAGE_CLICKED:
            return updateObject(state, { scheduleTicketCurPage: action.payload })

        case AdminOrSuperAdminActionTypes.PREVIEW_SCHEDULE_TICKET_PAGE_CLICKED:
            return updateObject(state, { previewScheduleTicketCurPage: action.payload })

        case AdminOrSuperAdminActionTypes.CHI_PAGE_CLICKED:
            return updateObject(state, { chi_cur_page: action.payload })

        case AdminOrSuperAdminActionTypes.COMPANY_PAGE_CLICKED:
            return updateObject(state, { company_cur_page: action.payload })

        case AdminOrSuperAdminActionTypes.QUOTE_PAGE_CLICKED:
            return updateObject(state, { quoteCurPage: action.payload })

        case AdminOrSuperAdminActionTypes.GET_INSPECTION_PENDING:
            return updateObject(state, { prformInspectionLoading: true, isLoading: true, inspection: {} })
        case AdminOrSuperAdminActionTypes.GET_INSPECTION_FULFILLED:
            return updateObject(state, { prformInspectionLoading: false, isLoading: false, inspection: action.payload })

        case AdminOrSuperAdminActionTypes.DELETE_INSPECTION_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_INSPECTION_FULFILLED:
            return updateObject(state, { isLoading: false, openDeleteModel: false, inspectionsList: action.payload.InspectionsList, searchedInspectionList: action.payload.SearchedInspectionsList })

        case AdminOrSuperAdminActionTypes.GET_QUOTES_CSV_REPORTS_PENDING:
            return updateObject(state, { isQuotesReportLoading: true })
        case AdminOrSuperAdminActionTypes.GET_QUOTES_CSV_REPORTS_FULFILLED:

            return updateObject(state, {
                isQuotesReportLoading: false, quotesReport: action.payload.quotesReport,
                an_account_with_zero_tags_sold: action.payload.an_account_with_zero_tags_sold,
                quotes_created_vs_quotes_approved: action.payload.quotes_created_vs_quotes_approved,
                quotes_tag_created: action.payload.quotes_tag_created,
                quotes_vs_approved_not_approved_by_the_client: action.payload.quotes_vs_approved_not_approved_by_the_client,
                quotes_vs_quotes_rejected_by_admin: action.payload.quotes_vs_quotes_rejected_by_admin,
                tags_confirmed_by_client: action.payload.tags_confirmed_by_client
            })

        case AdminOrSuperAdminActionTypes.GET_INSPECTION_REPORTS_PENDING:
            return updateObject(state, { isInspectionReportsLoading: true })
        case AdminOrSuperAdminActionTypes.GET_INSPECTION_REPORTS_FULFILLED:
            return updateObject(state, {
                isInspectionReportsLoading: false,
                inspectionReports: action.payload.results,
                overall_inspection_score: action.payload.overall_inspection_score,
                lowest_performing_account_name: action.payload.lowest_performing_account_name
            })

        case AdminOrSuperAdminActionTypes.GET_INSPECTION_REPORTS_CSV_PENDING:
            return updateObject(state)
        case AdminOrSuperAdminActionTypes.GET_INSPECTION_REPORTS_CSV_FULFILLED:
            return updateObject(state)

        case AdminOrSuperAdminActionTypes.GET_INSPECTION_FORMS_PENDING:
            return updateObject(state, { isLoading: true, isInspectionForm: true, inspectionFormsList: [] })
        case AdminOrSuperAdminActionTypes.GET_INSPECTION_FORMS_FULFILLED:
            return updateObject(state, { isLoading: false, isInspectionForm: false, totalInspectionForms: action.payload.count, inspectionFormsList: action.payload.inspection_forms })

        case AdminOrSuperAdminActionTypes.GET_INSPECTION_FORMS_FOR_JOB_PENDING:
            return updateObject(state, { isLoading: true, isInspectionForm: true, inspectionFormsList: [] })
        case AdminOrSuperAdminActionTypes.GET_INSPECTION_FORMS_FOR_JOB_FULFILLED:
            return updateObject(state, { isLoading: false, isInspectionForm: false, totalInspectionForms: action.payload.count, inspectionFormsList: action.payload.inspection_forms })

        case AdminOrSuperAdminActionTypes.ADD_NEW_INSPECTION_FORM_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_NEW_INSPECTION_FORM_FULFILLED:
            return updateObject(state, { isLoading: false, inspectionFormsList: action.payload ? action.payload.inspectionFormsList : state.inspectionFormsList })

        case AdminOrSuperAdminActionTypes.EDIT_INSPECTION_FORM_CLICKED:
            return updateObject(state, { inspectionForm: action.payload })

        case AdminOrSuperAdminActionTypes.UPDATE_INSPECTION_FORM_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_INSPECTION_FORM_FULFILLED:
            return updateObject(state, { isLoading: action.payload.isLoading, inspectionFormsList: action.payload.InspectionFormsList ? action.payload.InspectionFormsList : state.inspectionFormsList })

        case AdminOrSuperAdminActionTypes.UPDATE_INSPECTION_FORM_IMAGES:
            return updateObject(state, { inspection: action.payload })
        case AdminOrSuperAdminActionTypes.UPDATE_INSPECTION_FORM_IMAGES_PENDING:
            return updateObject(state, { prformInspectionLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_INSPECTION_FORM_IMAGES_FULFILLED:
            return updateObject(state, { prformInspectionLoading: false, inspectionForm: action.payload })

        case AdminOrSuperAdminActionTypes.GET_EDIT_INSPECTION_FORM_PENDING:
            return updateObject(state, { isLoading: true, isInspectionFromLoading: true })
        case AdminOrSuperAdminActionTypes.GET_EDIT_INSPECTION_FORM_FULFILLED:
            return updateObject(state, { isLoading: false, isInspectionFromLoading: false, inspectionForm: action.payload })

        case AdminOrSuperAdminActionTypes.DELETE_INSPECTION_FORM_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_INSPECTION_FORM_FULFILLED:
            return updateObject(state, { isLoading: false, inspectionFormTableList: action.payload.inspectionFormsList, searchedInspectionFormsList: action.payload.SearchedInspectionFormsList, openDeleteModel: false })

        case AdminOrSuperAdminActionTypes.REORDER_INSPECTION_FORM:
            return updateObject(state, { inspectionForm: action.payload })

        case AdminOrSuperAdminActionTypes.GET_RATINGS_PENDING:
            return updateObject(state, { isLoading: true, isRating: true })
        case AdminOrSuperAdminActionTypes.GET_RATINGS_FULFILLED:
            return updateObject(state, {
                isLoading: false, isRating: false,
                searchTicketValues: null,
                scheduleSearchTicketValues: null,
                previewScheduleSearchTicketValues: null,
                searchInspectionValues: null,
                searchQuoteValues: null,
                searchaccountValues: null,
                searchUserValues: {},
                searchedRatingList: null,
                hideRating: action.payload.hideRating,
                totalRatings: action.payload.ratingList.count, ratingList: action.payload.ratingList.rating_type
            })

        case AdminOrSuperAdminActionTypes.ADD_NEW_RATING_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_NEW_RATING_FULFILLED:
            return updateObject(state, { isLoading: false, ratingList: action.payload.ratingList, totalRatings: action.payload.count })

        case AdminOrSuperAdminActionTypes.EDIT_RATING_CLICKED:
            return updateObject(state, { rating: action.payload })

        case AdminOrSuperAdminActionTypes.UPDATE_RATING_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_RATING_FULFILLED:
            return updateObject(state, { isLoading: false, ratingList: action.payload.RatingList, hideRating: action.payload.hideRating, searchedRatingList: null, searchRatingValues: null })

        case AdminOrSuperAdminActionTypes.GET_EDIT_RATING_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_EDIT_RATING_FULFILLED:
            return updateObject(state, { isLoading: false, rating: action.payload })

        case AdminOrSuperAdminActionTypes.DELETE_RATING_LINE_ITEM_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_RATING_LINE_ITEM_FULFILLED:
            return updateObject(state, { ratingList: action.payload })

        case AdminOrSuperAdminActionTypes.DELETE_RATING_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_RATING_FULFILLED:
            return updateObject(state, { isLoading: false, ratingList: action.payload.ratingList, totalRatings: action.payload.count, searchedRatingList: action.payload.SearchedRatingList, openDeleteModel: false })

        case AdminOrSuperAdminActionTypes.GET_ALL_POSITIONS_PENDING:
            return updateObject(state, { isLoading: true, isPositions: true })
        case AdminOrSuperAdminActionTypes.GET_ALL_POSITIONS_FULFILLED:
            return updateObject(state, { isLoading: false, isPositions: false, totalRegions: action.payload?.region_count, totalPositions: action.payload?.positions_count, positionsList: action.payload?.positions })

        case AdminOrSuperAdminActionTypes.NEW_USER_CLICKED:
            return updateObject(state, { modeAccountsList: null })

        case AdminOrSuperAdminActionTypes.GET_USERS_PENDING:
            return updateObject(state, { isUserLoading: true, isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_USERS_FULFILLED:
            return updateObject(state, {
                usersList: action.payload.users,
                users_page: action.payload.users_page,
                users_no_of_rows: action.payload.users_no_of_rows ? action.payload.users_no_of_rows : state.users_no_of_rows,
                sort_users_column: action.payload.sort_users_column,
                users_order: action.payload.users_order,
                totalUsers: action.payload.totalUsers,
                searchUserValues: action.payload.searchUserValues,
                isUserLoading: false,
                isLoading: false,
                dataGenerated: false,
                searchTicketValues: null,
                scheduleSearchTicketValues: null,
                previewScheduleSearchTicketValues: null,
                searchInspectionValues: null,
                searchQuoteValues: null,
                searchaccountValues: null,
                user_rows: action.payload.user_rows ? action.payload.user_rows : null
            })

        case AdminOrSuperAdminActionTypes.GET_DROPDOWN_USERS_LIST_PENDING:
            return updateObject(state, { isDropDownUsersLoading: true })
        case AdminOrSuperAdminActionTypes.GET_DROPDOWN_USERS_LIST_FULFILLED:
            return updateObject(state, { isDropDownUsersLoading: false, dropDownUsersList: action.payload })

        case AdminOrSuperAdminActionTypes.SEARCH_USERS_PENDING:
            return updateObject(state, { isUserLoading: true, isLoading: true, SearchOrResetUserClicked: true })
        case AdminOrSuperAdminActionTypes.SEARCH_USERS_FULFILLED:
            return updateObject(state, {
                usersList: action.payload.users,
                users_page: action.payload.users_page,
                users_no_of_rows: action.payload.users_no_of_rows ? action.payload.users_no_of_rows : state.users_no_of_rows,
                sort_users_column: action.payload.sort_users_column,
                users_order: action.payload.users_order,
                totalUsers: action.payload.totalUsers,
                isUserLoading: false,
                isLoading: false,
                user_rows: action.payload.user_rows,
                searchUserValues: action.payload.searchUserValues
            })

        case AdminOrSuperAdminActionTypes.GET_USER_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })

        case AdminOrSuperAdminActionTypes.ADD_NEW_USER_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_NEW_USER_FULFILLED:
            return updateObject(state, { usersList: action.payload ? action.payload : state.usersList, isLoading: false })


        case AdminOrSuperAdminActionTypes.EDIT_USER_CLICKED:

            return updateObject(state, { requestor: action.payload })
        case AdminOrSuperAdminActionTypes.UPDATE_USER_PENDING:
            return updateObject(state, { isLoading: true, isUserLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_USER_FULFILLED:
            return updateObject(state, { isLoading: false, isUserLoading: false, usersList: action.payload, searchedUsersList: action.payload })

        case AdminOrSuperAdminActionTypes.EMAIL_PREFERENCES_CLICKED:
            return updateObject(state, { requestor: action.payload })

        case AdminOrSuperAdminActionTypes.GET_EDIT_USER_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_EDIT_USER_FULFILLED:
            return updateObject(state, { isLoading: false, requestor: action.payload })

        case AdminOrSuperAdminActionTypes.UPDATE_USER_PREFERENCES_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_USER_PREFERENCES_FULFILLED:
            return updateObject(state, { isLoading: false })
        // case AdminOrSuperAdminActionTypes.SEARCH_USERS_PENDING:
        //     return updateObject(state, { isLoading: true })
        // case AdminOrSuperAdminActionTypes.SEARCH_USERS_FULFILLED:
        //     return updateObject(state, { isLoading: false, usersList: action.payload })

        case AdminOrSuperAdminActionTypes.DELETE_USER_PENDING:
            return updateObject(state, { isLoading: true, SearchOrResetUserClicked: true, })
        case AdminOrSuperAdminActionTypes.DELETE_USER_FULFILLED:
            return updateObject(state, { usersList: action.payload.userList, searchedUsersList: action.payload.SearchUserList, isLoading: false, openDeleteModel: false })

        case AdminOrSuperAdminActionTypes.UPDATE_USER_PAGE_SIZE_CAHANGE_PENDING:
            return updateObject(state)
        case AdminOrSuperAdminActionTypes.UPDATE_USER_PAGE_SIZE_CAHANGE_FULFILLED:
            return updateObject(state, { user_no_of_rows: action.payload })

        case AdminOrSuperAdminActionTypes.GET_ACCOUNTS_PENDING:
            return updateObject(state, { isLoading: true, isAccountLoading: true })
        case AdminOrSuperAdminActionTypes.GET_ACCOUNTS_FULFILLED:
            return updateObject(state, {
                accountsList: action.payload.accounts,
                searchaccountValues: action.payload.searchaccountValues,
                account_page: action.payload.account_page,
                accounts_rows: action.payload.accounts_rows ? action.payload.accounts_rows : state.accounts_rows,
                totalAccounts: action.payload.totalAccounts,
                searchedInspectionFormsList: null,
                inspectionFormsList: null,
                holidaysList: null,
                isLoading: false,
                dataGenerated: false,
                isAccountLoading: false,
                searchTicketValues: null,
                scheduleSearchTicketValues: null,
                previewScheduleSearchTicketValues: null,
                searchInspectionValues: null,
                searchQuoteValues: null,
                searchUserValues: {},
                job_rows: action.payload.job_rows ? action.payload.job_rows : null
            })

        case AdminOrSuperAdminActionTypes.GET_MODE_ACCOUNTS_PENDING:
            return updateObject(state, {
                modeAccountsList: null
            })
        case AdminOrSuperAdminActionTypes.GET_MODE_ACCOUNTS_FULFILLED:
            return updateObject(state, {
                modeAccountsList: action.payload.accounts
            })

        case AdminOrSuperAdminActionTypes.GET_COMPANY_ACCOUNTS_PENDING:
            return updateObject(state, {
                companyAccounts: null
            })
        case AdminOrSuperAdminActionTypes.GET_COMPANY_ACCOUNTS_FULFILLED:
            return updateObject(state, {
                companyAccounts: action.payload.companyAccounts
            })

        case AdminOrSuperAdminActionTypes.SEARCH_ACCOUNTS_PENDING:
            return updateObject(state, { isLoading: true, isAccountLoading: true, SearchOrResetAccountClicked: true })
        case AdminOrSuperAdminActionTypes.SEARCH_ACCOUNTS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isAccountLoading: false,
                accountsList: action.payload.accounts,
                account_page: action.payload.account_page,
                totalAccounts: action.payload.totalAccounts,
                accounts_rows: action.payload.accounts_rows ? action.payload.accounts_rows : state.accounts_rows,
                job_rows: action.payload.job_rows,
                searchaccountValues: action.payload.searchaccountValues
            })

        case AdminOrSuperAdminActionTypes.GET_DROPDOWN_ACCOUNTS_PENDING:
            return updateObject(state, { isLoading: true, isAccountLoading: true })
        case AdminOrSuperAdminActionTypes.GET_DROPDOWN_ACCOUNTS_FULFILLED:
            return updateObject(state, { isLoading: false, isAccountLoading: false, userAccountLists: action.payload.accounts })

        case AdminOrSuperAdminActionTypes.UPDATE_QUOTE_CLINET_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_QUOTE_CLINET_FULFILLED:
            return updateObject(state, { isLoading: false, quoteApproved: action.payload.quoteApproved, quoteErrorMessage: action.payload.errorMessage })

        case AdminOrSuperAdminActionTypes.SEARCH_ACCOUNT_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.SEARCH_ACCOUNT_FULFILLED:
            return updateObject(state, { isLoading: false, accountsList: action.payload })

        case AdminOrSuperAdminActionTypes.ADD_NEW_ACCOUNT_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_NEW_ACCOUNT_FULFILLED:
            return updateObject(state, {
                accountsList: action.payload ? action.payload.AccountsList : state.accountsList,
                modeAccountsList: action.payload ? action.payload.AccountsList : state.accountsList,
                isLoading: action.payload && action.payload.isLoading ? action.payload.isLoading : false
            })

        case AdminOrSuperAdminActionTypes.GET_ACCOUNTS_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPLOAD_IMAGE_TO_S3_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPLOAD_IMAGE_TO_S3_FULFILLED:
            return updateObject(state, { isLoading: true })

        case AdminOrSuperAdminActionTypes.EDIT_ACCOUNT_CLICKED:
            return updateObject(state, { account: action.payload })
        case AdminOrSuperAdminActionTypes.Add_JOB_USERS_CLICKED:
            return updateObject(state, { requestor: action.payload })
        case AdminOrSuperAdminActionTypes.UPDATE_ACCOUNT_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_ACCOUNT_FULFILLED:
            return updateObject(state, { isLoading: false, accountsList: action.payload, searchedAccountList: action.payload })

        case AdminOrSuperAdminActionTypes.GET_EDIT_ACCOUNT_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_EDIT_ACCOUNT_FULFILLED:
            return updateObject(state, { isLoading: false, account: action.payload })

        case AdminOrSuperAdminActionTypes.UPDATE_ACCOUNTS_PAGE_SIZE_CHANGE_PENDING:
            return updateObject(state)
        case AdminOrSuperAdminActionTypes.UPDATE_ACCOUNTS_PAGE_SIZE_CHANGE_FULFILLED:
            return updateObject(state, { accounts_rows: action.payload })

        case AdminOrSuperAdminActionTypes.PREVIEW_ACCOUNT_USERS:
            return updateObject(state, { account: action.payload })

        case AdminOrSuperAdminActionTypes.SET_CURRENT_ACCOUNT:
            return updateObject(state, { currentAccount: action.payload })

        case AdminOrSuperAdminActionTypes.UPDATE_PASSWORD_CLICKED:
            return updateObject(state, { openPasswordModal: true })

        case AdminOrSuperAdminActionTypes.ACCOUNT_DUPLICATE_MODAL_CLICKED:
            return updateObject(state, { accountDuplicateModal: true })

        case AdminOrSuperAdminActionTypes.USER_DUPLICATE_MODAL_CLICKED:
            return updateObject(state, { userDuplicateModal: true })

        case AdminOrSuperAdminActionTypes.INSPECTION_DUPLICATE_MODAL_CLICKED:
            return updateObject(state, { inspectionDuplicateModal: true })

        case AdminOrSuperAdminActionTypes.CLOSE_PASSWORD_MODAL_CLICKED:
            return updateObject(state, { openPasswordModal: false })

        case AdminOrSuperAdminActionTypes.OPEN_ASSOCIATE_ACCOUNT_USER_CLICKED:
            return updateObject(state, { openAccountUserModal: true })

        case AdminOrSuperAdminActionTypes.OPEN_ASSOCIATE_ACCOUNT_HOLIDAYS_CLICKED:
            return updateObject(state, { openAccountHolidaysModal: true })

        case AdminOrSuperAdminActionTypes.CLOSE_ACCOUNT_MODAL:
            return updateObject(state, { openAccountUserModal: false, openAccountHolidaysModal: false, openAccountJobsModal: false })

        case AdminOrSuperAdminActionTypes.OPEN_ASSOCIATE_ACCOUNT_JOBS_CLICKED:
            return updateObject(state, { openAccountJobsModal: true })
        case AdminOrSuperAdminActionTypes.UPDATE_FILTERED_ACCOUNTS_USERS:
            return updateObject(state, { filterAccountUsers: action.payload.users })

        case AdminOrSuperAdminActionTypes.GET_FILTERED_ACCOUNTS_USERS_PENDING:
            return updateObject(state, { isFilterAccountUsersPending: true })
        case AdminOrSuperAdminActionTypes.GET_FILTERED_ACCOUNTS_USERS_FULFILLED:
            return updateObject(state, { isFilterAccountUsersPending: false, filterAccountUsers: action.payload.users })

        case AdminOrSuperAdminActionTypes.GET_ALL_MODE_ACCOUNT_USERS_PENDING:
            return updateObject(state, { isFilterAccountUsersPending: true, filterAccountUsers: [] })
        case AdminOrSuperAdminActionTypes.GET_ALL_MODE_ACCOUNT_USERS_FULFILLED:
            return updateObject(state, { isFilterAccountUsersPending: false, filterAccountUsers: action.payload.users })

        case AdminOrSuperAdminActionTypes.GET_INSPECTION_FORM_FOR_ACCOUNT_PENDING:
            return updateObject(state, { isLoading: true, isInspectionForm: true, inspectionFormsList: [] })
        case AdminOrSuperAdminActionTypes.GET_INSPECTION_FORM_FOR_ACCOUNT_FULFILLED:
            return updateObject(state, { isLoading: false, isInspectionForm: false, totalInspectionForms: action.payload.count, inspectionFormsList: action.payload.inspection_form })

        case AdminOrSuperAdminActionTypes.CHECK_SCHEDULE_PENDING:
            return updateObject(state, { isLoading: true, })
        case AdminOrSuperAdminActionTypes.CHECK_SCHEDULE_FULFILLED:
            return updateObject(state, { isLoading: false, scheduleCheckValues: action.payload })

        case AdminOrSuperAdminActionTypes.GET_ALL_ACCOUNT_USERS:
            return updateObject(state, { accountUsersList: action.payload.accountUsers, searchedAccountUsersList: action.payload.accountUsers })
        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_USERS_PENDING:

            return updateObject(state, { isLoading: true, isAccountUsers: true })
        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_USERS_FULFILLED:

            return updateObject(state, {
                // isLoading: false, accountUsersList: action.payload.users, searchedAccountUsersList: action.payload.users, account: { user: action.payload }
                isLoading: false,
                isAccountUsers: false,
                dataGenerated: false,
                account: action.payload.account,
                accountUsersList: action.payload.accountUsersList,
                account_users_page: action.payload.account_users_page,
                totalAccountUsers: action.payload.account_users_tot_count,
                account_users_row: action.payload.account_users_row ? action.payload.account_users_row : state.account_users_row,
            })

        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_USERS_INFO_PENDING:

            return updateObject(state, { isLoading: true, isAccountUsers: true })
        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_USERS_INFO_FULFILLED:
            return updateObject(state, {
                // isLoading: false, accountUsersList: action.payload.users, searchedAccountUsersList: action.payload.users, account: { user: action.payload }
                isLoading: false,
                isAccountUsers: false,
                dataGenerated: false,
                accountUsersList: action.payload.accountUsersList,
            })

        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_USERS_INFO_PAGE_PENDING:

            return updateObject(state, { isLoading: true, isAccountUsers: true })
        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_USERS_INFO_PAGE_FULFILLED:
            return updateObject(state, {
                // isLoading: false, accountUsersList: action.payload.users, searchedAccountUsersList: action.payload.users, account: { user: action.payload }
                isLoading: false,
                isAccountUsers: false,
                account: action.payload.account,
                accountUsersList: action.payload.accountUsersList,
                account_users_page: action.payload.account_users_page,
                totalAccountUsers: action.payload.account_users_tot_count,
                account_users_row: action.payload.account_users_row ? action.payload.account_users_row : state.account_users_row,
                currentAccount: action.payload.account
            })

        case AdminOrSuperAdminActionTypes.SEARCH_ACCOUNT_USERS_PENDING:
            return updateObject(state, { isLoading: true, isAccountUsers: true, SearchOrResetAccountUsersClicked: true })
        case AdminOrSuperAdminActionTypes.SEARCH_ACCOUNT_USERS_FULFILLED:
            return updateObject(state, {
                isLoading: false, isAccountUsers: false,
                account: action.payload.account,
                accountUsersList: action.payload.accountUsersList,
                account_users_page: action.payload.account_users_page,
                account_users_row: action.payload.account_users_row ? action.payload.account_users_row : state.account_users_row,
            })

        case AdminOrSuperAdminActionTypes.ADD_ACCOUNT_USERS_PENDING:
            return updateObject(state, { isLoading: true, popupLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_ACCOUNT_USERS_FULFILLED:
            return updateObject(state, { isLoading: action.payload.isLoading ? action.payload.isLoading : false, popupLoading: false, accountUsersList: action.payload.accountUsersList, searchedAccountUsersList: action.payload.accountUsersList, usersList: action.payload.usersList })

        case AdminOrSuperAdminActionTypes.SUBMIT_ASSOCUATE_ACCOUNT_HOLIDAYS_PENDING:
            return updateObject(state, { popupLoading: true })
        case AdminOrSuperAdminActionTypes.SUBMIT_ASSOCUATE_ACCOUNT_HOLIDAYS_FULFILLED:
            return updateObject(state, { popupLoading: false, openAccountHolidaysModal: false, account: action.payload, accountHolidaysList: action.payload.holidays })

        case AdminOrSuperAdminActionTypes.SUBMIT_ASSOCIATE_ACCOUNT_JOBS_PENDING:
            return updateObject(state, { popupLoading: true })
        case AdminOrSuperAdminActionTypes.SUBMIT_ASSOCIATE_ACCOUNT_JOBS_FULFILLED:
            return updateObject(state, { popupLoading: false, openAccountJobsModal: false, account: action.payload, accountJobsList: action.payload.jobs })

        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_HOLIDAYS_PENDING:
            return updateObject(state, { accountHolidaysLoading: true })
        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_HOLIDAYS_FULFILLED:
            return updateObject(state, { accountHolidaysLoading: false, accountHolidaysList: action.payload.holidays, totalAccountHolidays: action.payload.count })

        case AdminOrSuperAdminActionTypes.GET_QUOTA_FOR_QUOTES:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_QUOTA_FOR_QUOTES_FULFILLED:
            return updateObject(state, { isLoading: false, quotasForQuotes: action.payload })

        case AdminOrSuperAdminActionTypes.DELETE_ACCOUNT_USERS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_ACCOUNT_USERS_FULFILLED:
            return updateObject(state, {
                isLoading: false, openDeleteModel: false,
                accountUsersList: action.payload.AccountUsersList,
                searchedAccountUsersList: action.payload.SearchedAccountUsersList,
                usersList: action.payload.UsersList
            })

        case AdminOrSuperAdminActionTypes.GET_REGION_PENDING:
            return updateObject(state, { isLoading: true, isRegions: true })
        case AdminOrSuperAdminActionTypes.GET_REGION_FULFILLED:
            return updateObject(state, { isLoading: false, isRegions: false, totalRegions: action.payload.region_count, regionList: action.payload.regions })

        case AdminOrSuperAdminActionTypes.DELETE_ACCOUNT_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_ACCOUNT_FULFILLED:
            return updateObject(state, {
                accountsList: action.payload.AccountsList,
                searchedAccountList: action.payload.SearchedAccountList,
                isLoading: false, openDeleteModel: false,
                usersList: action.payload.UsersList
            })

        case AdminOrSuperAdminActionTypes.GET_REFRESH_QUOTE_PENDING:
            return updateObject(state, { isLoading: true, getQuoteLoading: true, quote: [] })
        case AdminOrSuperAdminActionTypes.GET_REFRESH_QUOTE_FULFILLED:
            return updateObject(state, { isLoading: false, getQuoteLoading: false, quote: action.payload })

        case AdminOrSuperAdminActionTypes.GET_QUOTES_PENDING:
            return updateObject(state, { isLoading: true, isQuotesLoading: true })
        case AdminOrSuperAdminActionTypes.GET_QUOTES_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isQuotesLoading: false,
                dataGenerated: false,
                searchTicketValues: null,
                scheduleSearchTicketValues: null,
                previewScheduleSearchTicketValues: null,
                searchInspectionValues: null,
                searchaccountValues: null,
                searchUserValues: {},
                quotesList: action.payload.quotes,
                quotes_page: action.payload.quotes_page,
                quotes_rows: action.payload.quotes_rows,
                sort_quotes_column: action.payload.sort_inspection_column,
                quotes_order: action.payload.quotes_order,
                totalQuotes: action.payload.totalQuotes,
                quote_page_rows: action.payload.quote_page_rows ? action.payload.quote_page_rows : null,
                dashboardQuoteCount: action.payload.dashboardQuoteCount
            })

        case AdminOrSuperAdminActionTypes.GET_QUOTE_LOG_PENDING:
            return updateObject(state, { isQuotesLogLoading: true })
        case AdminOrSuperAdminActionTypes.GET_QUOTE_LOG_FULFILLED:
            return updateObject(state, {
                isQuotesLogLoading: false,
                quoteLog: action.payload.data
            })

        case AdminOrSuperAdminActionTypes.SEARCH_QUOTES_PENDING:
            return updateObject(state, { isLoading: true, isQuotesLoading: true, searchOrResetQuoteClicked: true })
        case AdminOrSuperAdminActionTypes.SEARCH_QUOTES_FULFILLED:

            return updateObject(state, {
                isLoading: false,
                isQuotesLoading: false,
                quotesList: action.payload.quotes,
                quotes_page: action.payload.quotes_page,
                quotes_rows: action.payload.quotes_rows,
                sort_quotes_column: action.payload.sort_inspection_column,
                quotes_order: action.payload.quotes_order,
                totalQuotes: action.payload.totalQuotes,
                quote_page_rows: action.payload.quote_page_rows,
                searchQuoteValues: action.payload.searchQuoteValues
            })

        case AdminOrSuperAdminActionTypes.UPDATE_TOTAL_SALE_ARRAY:
            return updateObject(state, { totalSalesArray: action.payload })

        case AdminOrSuperAdminActionTypes.SEARCH_QUOTES_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.SEARCH_QUOTES_FULFILLED:
            return updateObject(state, { isLoading: false, quotesList: action.payload })

        case AdminOrSuperAdminActionTypes.RESEND_QUOTE_EMAIL_CLICKED:
            return updateObject(state, { quote: action.payload })
        case AdminOrSuperAdminActionTypes.RESEND_QUOTE_EMAIL_PENDING:
            return updateObject(state, { resendEmailLoading: true })
        case AdminOrSuperAdminActionTypes.RESEND_QUOTE_EMAIL_FULFILLED:
            return updateObject(state, { resendEmailLoading: false })

        case AdminOrSuperAdminActionTypes.GET_ACCOUNTS_DROP_DOWN_LIST_PENDING:
            return updateObject(state, { isLoading: true, isAccountLoading: true })
        case AdminOrSuperAdminActionTypes.GET_ACCOUNTS_DROP_DOWN_LIST_FULFILLED:
            return updateObject(state, { isLoading: false, isAccountLoading: false, dropDownAccountsList: action.payload })

        case AdminOrSuperAdminActionTypes.GET_CUSTOMERS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_CUSTOMERS_FULFILLED:
            return updateObject(state, { isLoading: false, dropDownCustomerList: action.payload })

        case AdminOrSuperAdminActionTypes.ADD_NEW_QUOTE_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_NEW_QUOTE_FULFILLED:
            return updateObject(state, { isLoading: action.payload.QuotesList ? true : false, quotesList: action.payload.QuotesList, openQuotesModal: action.payload.openQuotesModal, inspection: action.payload.inspection ? action.payload.inspection : state.inspection })

        case AdminOrSuperAdminActionTypes.EDIT_QUOTE_CLICKED:
            return updateObject(state, { quote: action.payload })

        case AdminOrSuperAdminActionTypes.UPDATE_QUOTE_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_QUOTE_FULFILLED:
            return updateObject(state, { isLoading: true, quotesList: action.payload.QuotesList, openQuotesModal: action.payload.openQuotesModal, inspection: action.payload.inspection ? action.payload.inspection : state.inspection })

        case AdminOrSuperAdminActionTypes.DELETE_QUOTE_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_QUOTE_FULFILLED:
            return updateObject(state, { openDeleteModel: false, isLoading: false, quotesList: action.payload.QuotesList, searchedQuoteList: action.payload.SearchQuotesList })

        case AdminOrSuperAdminActionTypes.UPDATE_QUOTES_PAGE_SIZE_PENDING:
            return updateObject(state)
        case AdminOrSuperAdminActionTypes.UPDATE_QUOTES_PAGE_SIZE_FULFILLED:
            return updateObject(state, { quotes_rows: action.payload })

        case AdminOrSuperAdminActionTypes.UPDATE_TOTAL_SALE_LISTS:
            return updateObject(state, { quotesList: action.payload })

        case AdminOrSuperAdminActionTypes.OPEN_REJECT_MODEL:
            return updateObject(state, { openRejectQuoteModel: true })
        case AdminOrSuperAdminActionTypes.CLOSE_REJECT_MODEL:
            return updateObject(state, { openRejectQuoteModel: false })

        case AdminOrSuperAdminActionTypes.APPROVE_OR_REJECT_QUOTE_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.APPROVE_OR_REJECT_QUOTE_FULFILLED:
            return updateObject(state, { openRejectQuoteModel: false, isLoading: !action.payload, quotesList: action.payload, searchedQuoteList: action.payload })

        case AdminOrSuperAdminActionTypes.GOTO_APPROVE_QUOTE:
            return updateObject(state, { quote: action.payload })

        case AdminOrSuperAdminActionTypes.VIEW_FILTERED_QUOTES:
            return updateObject(state, { quotesList: action.payload.Quotes, filteredQuotesList: action.payload.filteredQuotesList })
        case AdminOrSuperAdminActionTypes.VIEW_FILTERED_QUOTES_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.VIEW_FILTERED_QUOTES_FULFILLED:
            return updateObject(state, { isLoading: false, quotesList: action.payload.Quotes, filteredQuotesList: action.payload.filteredQuotesList })

        case AdminOrSuperAdminActionTypes.GET_COMPANIES_PENDING:
            return updateObject(state, { isLoading: true, isCompanyPending: true })
        case AdminOrSuperAdminActionTypes.GET_COMPANIES_FULFILLED:
            return updateObject(state, {
                companiesList: action.payload.companies ? action.payload.companies : '',
                company_page: action.payload.company_page,
                company_rows: action.payload.company_rows ? action.payload.company_rows : state.company_rows,
                totalCompanies: action.payload.totalCompanies,
                company_cur_page: action.payload.company_cur_page,
                companySearchValues: action.payload.companySearchValues,
                // searchedCompanyList: action.payload.companies, companies_rows: action.payload.companies_rows,
                isLoading: false,
                isCompanyPending: false,
                dataGenerated: false,
            })

        case AdminOrSuperAdminActionTypes.GET_DROPDOWN_COMPANIES_PENDING:
            return updateObject(state, { isCompanyPending: true, isCompanyAccessPending: true })
        case AdminOrSuperAdminActionTypes.GET_DROPDOWN_COMPANIES_FULFILLED:
            return updateObject(state, { isCompanyPending: false, isCompanyAccessPending: false, companyDropdownList: action.payload })

        case AdminOrSuperAdminActionTypes.SEARCH_COMPANIE_PENDING:
            return updateObject(state, { isLoading: true, isCompanyPending: true, SearchOrResetCompanyClicked: true })
        case AdminOrSuperAdminActionTypes.SEARCH_COMPANIE_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isCompanyPending: false,
                companiesList: action.payload.companies ? action.payload.companies : '',
                company_page: action.payload.company_page,
                company_rows: action.payload.company_rows ? action.payload.company_rows : state.company_rows,
                totalCompanies: action.payload.totalCompanies,
                companySearchValues: action.payload.companySearchValues,
            })

        case AdminOrSuperAdminActionTypes.GET_ALL_NON_ASSOCIATED_COMPANY_ACCOUNTS:
            return updateObject(state, { non_associated_company_accounts: action.payload })
        case AdminOrSuperAdminActionTypes.GET_NON_ASSOCIATED_COMPANY_ACCOUNTS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_NON_ASSOCIATED_COMPANY_ACCOUNTS_FULFILLED:
            return updateObject(state, { isLoading: false, non_associated_company_accounts: action.payload })

        case AdminOrSuperAdminActionTypes.ADD_NEW_COMPANY_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_NEW_COMPANY_FULFILLED:
            return updateObject(state, {
                companiesList: action.payload && action.payload.CompaniesList ? action.payload.CompaniesList : state.companiesList,
                searchedCompanyList: action.payload && action.payload.CompaniesList ? action.payload.CompaniesList : state.companiesList,
                isLoading: action.payload.isLoading
                //action.payload ? action.payload.isLoading ? 
            })

        case AdminOrSuperAdminActionTypes.EDIT_COMPANY_CLICKED:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.UPDATE_COMPANY_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_COMPANY_FULFILLED:
            return updateObject(state, { isLoading: action.payload && action.payload.isLoading ? action.payload.isLoading : false, searchedCompanyList: action.payload && action.payload.CompaniesList ? action.payload.CompaniesList : state.companiesList })

        case AdminOrSuperAdminActionTypes.GET_EDIT_COMPANY_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_EDIT_COMPANY_FULFILLED:
            return updateObject(state, { isLoading: false, company: action.payload })

        case AdminOrSuperAdminActionTypes.DELETE_COMPANY_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_COMPANY_FULFILLED:
            return updateObject(state, {
                companiesList: action.payload.CompaniesList,
                searchedCompanyList: action.payload.SearchedCompaniesList,
                isLoading: false, openDeleteModel: false,
                usersList: action.payload.UsersList
            })

        case AdminOrSuperAdminActionTypes.PREVIEW_COMPANY_USERS_CLICKED:
            return updateObject(state, { company: action.payload })
        // case AdminOrSuperAdminActionTypes.GET_ALL_COMPANY_USERS:
        //     return updateObject(state, { companyUsersList: action.payload.companyUsers, searchedCompanyUsersList: action.payload.companyUsers })
        case AdminOrSuperAdminActionTypes.GET_COMPANY_USERS_PENDING:
            return updateObject(state, { isLoading: true, isCompanyUsersPending: true })
        case AdminOrSuperAdminActionTypes.GET_COMPANY_USERS_FULFILLED:
            return updateObject(state, {
                // isLoading: false, companyUsersList: action.payload.users, searchedCompanyUsersList: action.payload.user, company: action.payload 
                isLoading: false,
                isCompanyUsersPending: false,
                dataGenerated: false,
                companyName: action.payload.companyName,
                companyUsersList: action.payload.companyUsersList,
                total_company_users: action.payload.total_company_users,
                total_no_of_pages: action.payload.total_no_of_pages,
            })

        case AdminOrSuperAdminActionTypes.SEARCH_COMPANY_USERS_PENDING:
            return updateObject(state, { isLoading: true, isCompanyUsersPending: true, SearchOrResetCompanyUsersClicked: true })
        case AdminOrSuperAdminActionTypes.SEARCH_COMPANY_USERS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isCompanyUsersPending: false,
                companyUsersList: action.payload.companyUsersList,
                total_company_users: action.payload.total_company_users,
                total_no_of_pages: action.payload.total_no_of_pages
            })

        case AdminOrSuperAdminActionTypes.OPEN_ASSOCIATE_COMPANY_USER_CLICKED:
            return updateObject(state, { openAssociateUserCompanyModal: true })
        case AdminOrSuperAdminActionTypes.CLOSE_COMPANY_MODAL:
            return updateObject(state, { openAssociateUserCompanyModal: false })

        case AdminOrSuperAdminActionTypes.UPDATE_FILTERED_COMPANY_USERS:
            return updateObject(state, { filterCompanyUsers: action.payload.users })

        case AdminOrSuperAdminActionTypes.GET_FILTERED_COMPANY_USERS_PENDING:
            return updateObject(state, { isFilterCompanyUsersPending: true })
        case AdminOrSuperAdminActionTypes.GET_FILTERED_COMPANY_USERS_FULFILLED:
            return updateObject(state, { isFilterCompanyUsersPending: false, filterCompanyUsers: action.payload.users })

        case AdminOrSuperAdminActionTypes.ADD_COMPANY_USERS_PENDING:
            return updateObject(state, { isLoading: true, popupLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_COMPANY_USERS_FULFILLED:
            return updateObject(state, {
                isLoading: action.payload.isLoading ? action.payload.isLoading : false, popupLoading: false, openAssociateUserCompanyModal: false,
                companyUsersList: action.payload.companyUsersList, searchedCompanyUsersList: action.payload.companyUsersList, usersList: action.payload.usersList
            })

        case AdminOrSuperAdminActionTypes.DELETE_COMPANY_USER_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_COMPANY_USER_FULFILLED:
            return updateObject(state, {
                isLoading: false, openDeleteModel: false, companyUsersList: action.payload.CompanyUsersList,
                searchedCompanyUsersList: action.payload.SearchedCompanyUsersList,
                usersList: action.payload.UsersList
            })

        // case AdminOrSuperAdminActionTypes.CLOSE_ASSOCIATE_COMPANY_MODAL:
        //     return updateObject(state, { openAssociateUserModal: false })

        // case AdminOrSuperAdminActionTypes.GET_FILTERED_COMPANY_USERS_PENDING:
        //     return updateObject(state, { popupLoading: true })
        // case AdminOrSuperAdminActionTypes.GET_FILTERED_COMPANY_USERS_FULFILLED:
        //     return updateObject(state, { popupLoading: true, filteredCompanyUsers: action.payload })


        case AdminOrSuperAdminActionTypes.OPEN_MODEL:
            return updateObject(state, { isOpenModel: true, isEdit: false, position: {}, region: {}, category: {}, areaType: null, floor: null, holiday: {} })
        case AdminOrSuperAdminActionTypes.CLOSE_MODEL:
            return updateObject(state, { isOpenModel: false, openQuotesModal: false, openSignaturePad: false, openTicketModal: false })

        case AdminOrSuperAdminActionTypes.ADD_NEW_POSITION_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_NEW_POSITION_FULFILLED:
            return updateObject(state, { isLoading: false, positionsList: action.payload.Positions, totalPositions: action.payload.positions_count })

        case AdminOrSuperAdminActionTypes.EDIT_POSITION_CLICKED:
            return updateObject(state, { isOpenModel: true, isEdit: true, position: action.payload })

        case AdminOrSuperAdminActionTypes.UPDATE_POSITION_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.UPDATE_POSITION_FULFILLED:
            return updateObject(state, { isLoading: false, isOpenModel: action.payload.openModal, isEdit: false, positionsList: action.payload.Positions });

        case AdminOrSuperAdminActionTypes.DELETE_POSITION_PENDING:
            return updateObject(state, { isLoading: true, openDeleteModel: true })
        case AdminOrSuperAdminActionTypes.DELETE_POSITION_FULFILLED:
            return updateObject(state, { isLoading: false, openDeleteModel: false, positionsList: action.payload.positions, totalRegions: action.payload.region_count, totalPositions: action.payload.positions_count })

        case AdminOrSuperAdminActionTypes.ADD_NEW_REGION_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_NEW_REGION_FULFILLED:
            return updateObject(state, { isLoading: false, regionList: action.payload })

        case AdminOrSuperAdminActionTypes.EDIT_REGION_CLICKED:
            return updateObject(state, { isOpenModel: true, isEdit: true, region: action.payload })

        case AdminOrSuperAdminActionTypes.UPDATE_REGION_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_REGION_FULFILLED:
            return updateObject(state, { isLoading: false, isOpenModel: action.payload.openModal, isEdit: false, regionList: action.payload.Regions })

        case AdminOrSuperAdminActionTypes.DELETE_REGION_PENDING:
            return updateObject(state, { isLoading: true, openDeleteModel: true })
        case AdminOrSuperAdminActionTypes.DELETE_REGION_FULFILLED:
            return updateObject(state, { isLoading: false, openDeleteModel: false, totalRegions: action.payload.region_count, regionList: action.payload.regions })

        case AdminOrSuperAdminActionTypes.RESET_OBJECTS:
            return updateObject(state, {
                quote: {}, account: {}, requestor: {}, company: {}, ticket: {},
                filteredQuotesList: null, inspectionForm: {}, inspection: {}, rating: {},
                companyUsersList: null, searchedCompanyUsersList: null,
                accountUsersList: null, searchedAccountUsersList: null,
                non_associated_company_accounts: null, feedback: {}, frontlineUser: {}
            })

        case AdminOrSuperAdminActionTypes.GET_CATEGORIES_PENDING:
            return updateObject(state, { isLoading: true, isCategories: true })
        case AdminOrSuperAdminActionTypes.GET_CATEGORIES_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isCategories: false,
                totalCategories: action.payload && action.payload.count ? action.payload.count : null,
                categoriesList: action.payload && action.payload.categories ? action.payload.categories : null,
            })

        case AdminOrSuperAdminActionTypes.ADD_NEW_CATEGORY_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_NEW_CATEGORY_FULFILLED:
            return updateObject(state, { isLoading: false, categoriesList: action.payload.CategoriesList, totalCategories: action.payload.count, isOpenModel: false })

        case AdminOrSuperAdminActionTypes.EDIT_CATEGORY_CLICKED:
            return updateObject(state, { category: action.payload, isOpenModel: true, isEdit: true })

        case AdminOrSuperAdminActionTypes.VIEW_PRIOR_TICKET_STATUS_CLICKED:
            return updateObject(state, { viewPriorTicket: true })

        case AdminOrSuperAdminActionTypes.DISPLAY_PRIOR_TICKET_STATUS_CLICK_FULFILLED:
            return updateObject(state, { priorTicket: action.payload.ticketStatusInfo })

        case AdminOrSuperAdminActionTypes.UPDATE_CATEGORY_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_CATEGORY_FULFILLED:
            return updateObject(state, { isLoading: false, categoriesList: action.payload, isOpenModel: false, isEdit: false })

        case AdminOrSuperAdminActionTypes.DELETE_CATEGORY_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_CATEGORY_FULFILLED:
            return updateObject(state, { isLoading: false, openDeleteModel: false, totalCategories: action.payload.count, categoriesList: action.payload.CategoriesList })

        case AdminOrSuperAdminActionTypes.SEARCH_QUOTES:
            return updateObject(state, { searchedQuoteList: action.payload })
        case AdminOrSuperAdminActionTypes.SEARCH_ACCOUNTS:
            return updateObject(state, { searchedAccountList: action.payload })
        case AdminOrSuperAdminActionTypes.SEARCH_USERS:
            return updateObject(state, { searchedUsersList: action.payload })

        case AdminOrSuperAdminActionTypes.SEARCH_COMPANY:
            return updateObject(state, { searchedCompanyList: action.payload })
        case AdminOrSuperAdminActionTypes.SEARCH_COMPANY_USERS:
            return updateObject(state, { searchedCompanyUsersList: action.payload })
        case AdminOrSuperAdminActionTypes.SEARCH_ACCOUNT_USERS:
            return updateObject(state, { searchedAccountUsersList: action.payload })
        case AdminOrSuperAdminActionTypes.SEARCH_INSPECTION_FORM:
            return updateObject(state, { searchedInspectionFormsList: action.payload })
        case AdminOrSuperAdminActionTypes.SEARCH_RATING:
            return updateObject(state, { searchedRatingList: action.payload.SearchedRatingList, totalRatings: action.payload.totalRatings, searchRatingValues: action.payload.searchRatingValues, ratingCurPage: action.payload.curPage, rating_no_of_rows: action.payload.rows })
        case AdminOrSuperAdminActionTypes.SEARCH_INSPECTION:
            return updateObject(state, { searchedInspectionList: action.payload })
        case AdminOrSuperAdminActionTypes.SEARCH_TICKET:
            return updateObject(state, { searchTicketsList: action.payload })
        // case AdminOrSuperAdminActionTypes.SEARCH_CATEGORY:
        //     return updateObject(state, { seachCa })
        case AdminOrSuperAdminActionTypes.GET_AREAS_PENDING:
            return updateObject(state, { isAccountAreas: true });
        case AdminOrSuperAdminActionTypes.GET_AREAS_FULFILLED:
            return updateObject(state, { total_no_of_pages: action.payload.data.total_no_of_pages, accountAreas: action.payload.data.areas, totalAccountAreas: action.payload.data.count, isAccountAreas: false });

        case AdminOrSuperAdminActionTypes.GET_ALL_LOGBOOK_AREA_PENDING:
            return updateObject(state, { isLogbookAreas: true });
        case AdminOrSuperAdminActionTypes.GET_ALL_LOGBOOK_AREA_FULFILLED:
            return updateObject(state, { total_no_of_pages: action.payload.total_no_of_pages, logbookAreas: action.payload.logbookLogs, totalLogbookAreas: action.payload.totalLogbookLogs, isLogbookAreas: false, logsAreaUrl: action.payload.logsAreaUrl, logbook_rows: action.payload.rows, logbookAreaCurPage: action.payload.logbookAreaCurPage });

        case AdminOrSuperAdminActionTypes.GET_LOGBOOK_LOGS_AREA_PREVIEW_PENDING:
            return updateObject(state, { isLogbookLogsAreas: true });
        case AdminOrSuperAdminActionTypes.GET_LOGBOOK_LOGS_AREA_PREVIEW_FULFILLED:
            return updateObject(state, { logbookLog: action.payload.logbookLog, isLogbookLogsAreas: false });

        case AdminOrSuperAdminActionTypes.GET_ALL_LOGBOOK_PENDING:
            return updateObject(state, { isLogbookLogs: true, SearchOrResetLogbookClicked: true });
        case AdminOrSuperAdminActionTypes.GET_ALL_LOGBOOK_FULFILLED:
            return updateObject(state, { total_no_of_pages: action.payload.total_no_of_pages, logbookLogs: action.payload.logbookLogs, totalLogbookLogs: action.payload.totalLogbookLogs, isLogbookLogs: false, searchLogbookValues: action.payload.searchValues, logbook_rows: action.payload.rows, total_pending: action.payload.total_pending, total_reviewed: action.payload.total_reviewed });

        case AdminOrSuperAdminActionTypes.LOGBOOK_AREA_PAGE_CLICKED:
            return updateObject(state, { logbookAreaCurPage: action.payload })

        case AdminOrSuperAdminActionTypes.GET_AREA_PENDING:
            return updateObject(state, { isLoading: true, isAccountAreas: true, accountArea: null });
        case AdminOrSuperAdminActionTypes.GET_AREA_FULFILLED:
            return updateObject(state, { isLoading: false, accountArea: action.payload.data.area, isAccountAreas: false, isAccountAreaTab: true });
        case AdminOrSuperAdminActionTypes.CLEAR_AREA:
            return updateObject(state, { accountArea: null })
        case AdminOrSuperAdminActionTypes.UPDATE_AREA_PENDING:
            return updateObject(state, { isLoading: true, isAccountAreas: true, isAccountAreaTab: true });
        case AdminOrSuperAdminActionTypes.UPDATE_AREA_FULFILLED:
            if (action.payload && action.payload.data?.area) return updateObject(state, { isLoading: false, isAccountAreas: false, isAccountAreaTab: true, accountArea: null });
            else return updateObject(state, { isLoading: false, isAccountAreas: false, isAccountAreaTab: true });
        case AdminOrSuperAdminActionTypes.DELETE_ACCOUNT_PENDING:
            return updateObject(state, { isLoading: true });

        case AdminOrSuperAdminActionTypes.DELETE_AREA_FULFILLED:
            const accountAreas = [...state.accountAreas];
            accountAreas.splice(accountAreas.findIndex(e => e.id === action.payload.area_id), 1);
            return updateObject(state, { accountAreas, isAccountAreas: false, isLoading: false });

        case AdminOrSuperAdminActionTypes.DELETE_LOGBOOK_AREA_FULFILLED:
            const logbookAreas = [...state.logbookAreas];
            logbookAreas.splice(logbookAreas.findIndex(e => e.id === action.payload.logbook_id), 1);
            return updateObject(state, { logbookAreas, isLogbookAreas: false, isLoading: false });

        case AdminOrSuperAdminActionTypes.DELETE_SCHEDULED_TICKET_FULFILLED:
            const scheduledTickets = [...state.logbookAreas];
            logbookAreas.splice(logbookAreas.findIndex(e => e.id === action.payload.logbook_id), 1);
            return updateObject(state, { logbookAreas, isLogbookAreas: false, isLoading: false });


        case AdminOrSuperAdminActionTypes.GET_AREA_TYPES_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.GET_AREA_TYPES_FULFILLED:
            return updateObject(state, { areaTypes: action.payload.data.area_types, totalAreaTypes: action.payload.data.area_count, totalFloors: action.payload.data.floor_count, totalDeficiencies: action.payload.data.deficiency_count - 1, totalHolidays: action.payload.data.holiday_count, isLoading: false });
        case AdminOrSuperAdminActionTypes.GET_ALL_DEFICIENCIES_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.GET_ALL_DEFICIENCIES_FULFILLED:
            return updateObject(state, { deficiencies: action.payload.data, totalDeficiencies: action.payload.Deficiency_list - 1, totalAreaTypes: action.payload.area_count, totalFloors: action.payload.floor_count, totalHolidays: action.payload.holiday_count, isLoading: false });
        case AdminOrSuperAdminActionTypes.SEARCH_HISTORY_PENDING:
            return updateObject(state, { isLoading: true, searchHistory: null, areaSearchResult: null });
        case AdminOrSuperAdminActionTypes.SEARCH_HISTORY_REJECTED:
            return updateObject(state, { isLoading: false, searchHistory: null });
        case AdminOrSuperAdminActionTypes.SEARCH_HISTORY_FULFILLED:
            return updateObject(state, { isLoading: false, searchHistory: { last_sync: action.payload.data.last_sync, frontline_tasks: action.payload.data.frontline_tasks, area: action.payload.data.areas[0], timezone: action.payload.data.timezone, total_no_of_pages: action.payload.data.total_no_of_pages }, total_no_of_pages: action.payload.data.total_no_of_pages });
        case AdminOrSuperAdminActionTypes.GET_AREA_BY_QR_PENDING:
            return updateObject(state, { isLoading: true, areaSearchResult: null, searchHistory: null });
        case AdminOrSuperAdminActionTypes.GET_AREA_BY_QR_REJECTED:
            return updateObject(state, { isLoading: false });
        case AdminOrSuperAdminActionTypes.GET_AREA_BY_QR_FULFILLED:
            return updateObject(state, { isLoading: false, areaSearchResult: action.payload.data.area });
        case AdminOrSuperAdminActionTypes.GET_FRONTLINE_LIST_PENDING:
            return updateObject(state, { isLoading: true, isFrontline: true });
        case AdminOrSuperAdminActionTypes.GET_FRONTLINE_LIST_FULFILLED:
            return updateObject(state, {
                total_no_of_pages: action.payload.data.total_no_of_pages, isLoading: false,
                totalfrontline: action.payload.data.users_count,
                searchTicketValues: null,
                scheduleSearchTicketValues: null,
                previewScheduleSearchTicketValues: null,
                searchInspectionValues: null,
                searchQuoteValues: null,
                searchaccountValues: null,
                searchUserValues: {},
                frontlineList: action.payload.data.frontlines, fl_user_rows: action.payload.fl_user_rows ? action.payload.fl_user_rows : null, isFrontline: false
            });

        case AdminOrSuperAdminActionTypes.SEARCH_FRONTLINE_USERS_PENDING:
            return updateObject(state, { isLoading: true, isFrontline: true, SearchOrResetFrontLineUsersClicked: true });
        case AdminOrSuperAdminActionTypes.SEARCH_FRONTLINE_USERS_FULFILLED:
            return updateObject(state, { total_no_of_pages: action.payload.total_no_of_pages, isLoading: false, frontlineList: action.payload.frontlines, fl_user_rows: action.payload.fl_user_rows, frontlineUserCurPage: action.payload.frontlineUserCurPage, isFrontline: false });

        case AdminOrSuperAdminActionTypes.GET_FRONTLINE_PENDING:
            return updateObject(state, { isLoading: true, isFrontline: true, frontlineUser: null });
        case AdminOrSuperAdminActionTypes.GET_FRONTLINE_FULFILLED:
            return updateObject(state, { isLoading: false, frontlineUser: action.payload.data.user, isFrontline: false });
        case AdminOrSuperAdminActionTypes.IMPORT_FRONTLINE_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.IMPORT_FRONTLINE_REJECTED:
            return updateObject(state, { isLoading: false });
        case AdminOrSuperAdminActionTypes.IMPORT_FRONTLINE_FULFILLED:
            return updateObject(state, { isLoading: false, frontlineList: action.payload.data.users });

        case AdminOrSuperAdminActionTypes.CREATE_FRONTLINE_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.CREATE_FRONTLINE_REJECTED:
            return updateObject(state, { isLoading: false });
        case AdminOrSuperAdminActionTypes.CREATE_FRONTLINE_FULFILLED:
            return updateObject(state, { isLoading: false, frontlineUser: null });

        case AdminOrSuperAdminActionTypes.UPDATE_FRONTLINE_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.CREATE_FRONTLINE_REJECTED:
            return updateObject(state, { isLoading: false });
        case AdminOrSuperAdminActionTypes.UPDATE_FRONTLINE_FULFILLED:
            return updateObject(state, {
                isLoading: true,
                frontlineList: [],
                frontlineUser: null
            });
        case AdminOrSuperAdminActionTypes.GET_FLOORS_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.GET_FLOORS_FULFILLED:
            return updateObject(state, { isLoading: false, totalFloors: action.payload.data.floor_count, totalDeficiencies: action.payload.data.deficiency_count - 1, floorList: action.payload.data.floors });
        case AdminOrSuperAdminActionTypes.ADD_FLOOR_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.ADD_FLOOR_REJECTED:
            return updateObject(state, { isLoading: false });
        case AdminOrSuperAdminActionTypes.ADD_FLOOR_FULFILLED:
            let list = state.floorList;
            if (action.payload.data && action.payload.data.floor) list = [action.payload.data.floor, ...state.floorList]
            return updateObject(state, { isLoading: false, floorList: list, totalFloors: action.payload.Floors_list, });
        case AdminOrSuperAdminActionTypes.ADD_AREA_TYPE_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.ADD_AREA_TYPE_REJECTED:
            return updateObject(state, { isLoading: false });
        case AdminOrSuperAdminActionTypes.ADD_AREA_TYPE_FULFILLED:
            return updateObject(state, { isLoading: false, areaTypes: action.payload.AreaTypes, totalAreaTypes: action.payload.area_count });
        case AdminOrSuperAdminActionTypes.ADD_DEFICIENCY_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.ADD_DEFICIENCY_REJECTED:
            return updateObject(state, { isLoading: false });
        case AdminOrSuperAdminActionTypes.ADD_DEFICIENCY_FULFILLED:
            return updateObject(state, { isLoading: false, deficiencies: action.payload.Deficiency_list, totalDeficiencies: action.payload.Deficiency_count });
        case AdminOrSuperAdminActionTypes.EDIT_AREA_TYPE_CLICKED:
            return updateObject(state, { isOpenModel: true, isEdit: true, areaType: action.payload });
        case AdminOrSuperAdminActionTypes.EDIT_DEFICIENCY_CLICKED:
            return updateObject(state, { isOpenModel: true, isEdit: true, deficiency: action.payload });
        case AdminOrSuperAdminActionTypes.EDIT_FLOOR_CLICKED:
            return updateObject(state, { isOpenModel: true, isEdit: true, floor: action.payload });
        case AdminOrSuperAdminActionTypes.DELETE_AREA_TYPE_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.DELETE_FLOOR_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.UPDATE_FLOOR_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.UPDATE_FLOOR_REJECTED:
            return updateObject(state, { isLoading: false });
        case AdminOrSuperAdminActionTypes.UPDATE_FLOOR_FULFILLED:
            let updatedfloorList = [...state.floorList];
            if (action.payload.data && action.payload.data.floor) updatedfloorList[updatedfloorList.findIndex(e => e.id === action.payload.data.floor.id)] = action.payload.data.floor
            return updateObject(state, { isOpenModel: false, floor: null, isLoading: false, floorList: updatedfloorList });
        case AdminOrSuperAdminActionTypes.UPDATE_AREA_TYPE_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.UPDATE_AREA_TYPE_REJECTED:
            return updateObject(state, { isLoading: false });
        case AdminOrSuperAdminActionTypes.UPDATE_AREA_TYPE_FULFILLED:
            let updatedAreaTypes = [...state.areaTypes];
            updatedAreaTypes[updatedAreaTypes.findIndex(e => e.id === action.payload.data.area_type.id)] = action.payload.data.area_type
            return updateObject(state, { isOpenModel: false, areaType: null, isLoading: false, areaTypes: updatedAreaTypes });
        case AdminOrSuperAdminActionTypes.UPDATE_DEFICIENCY_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.UPDATE_DEFICIENCY_REJECTED:
            return updateObject(state, { isLoading: false });
        case AdminOrSuperAdminActionTypes.UPDATE_DEFICIENCY_FULFILLED:
            return updateObject(state, { isOpenModel: false, deficiency: null, deficiencies: action.payload.deficiencies, isLoading: false });
        case AdminOrSuperAdminActionTypes.GET_DEFICIENCY_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.GET_DEFICIENCY_REJECTED:
            return updateObject(state, { isLoading: false });
        case AdminOrSuperAdminActionTypes.GET_DEFICIENCY_FULFILLED:
            return updateObject(state, { deficiency: action.payload.deficiency, isLoading: false });
        case AdminOrSuperAdminActionTypes.CLEAR_DEFICIENCY:
            return updateObject(state, { deficiency: null })
        case AdminOrSuperAdminActionTypes.DELETE_AREA_TYPE_FULFILLED:
            const areaTypes = [...state.areaTypes];
            areaTypes.splice(areaTypes.findIndex(e => e.id === action.payload.area_id), 1);
            return updateObject(state, { isLoading: false, openDeleteModel: false, areaTypes });
        case AdminOrSuperAdminActionTypes.DELETE_FLOOR_FULFILLED:
            const floorList = [...state.floorList];
            floorList.splice(floorList.findIndex(e => e.id === action.payload.area_id), 1);
            return updateObject(state, { isLoading: false, openDeleteModel: false, floorList });
        case AdminOrSuperAdminActionTypes.GET_ALL_AREA_DETAILS_FULFILLED:
            return updateObject(state, { accountFloorList: action.payload.data.floors, accountAreaTypes: action.payload.data.area_types, areaNames: action.payload.data.area_names })
        case AdminOrSuperAdminActionTypes.CREATE_AREA_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.CREATE_AREA_FULFILLED:
            return updateObject(state, { isLoading: false, isAccountAreaTab: true })
        case AdminOrSuperAdminActionTypes.SCAN_ANALYTICS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.SCAN_ANALYTICS_FULFILLED:
            return updateObject(state, { isLoading: false, scanAnalyticsData: action.payload.data })

        case AdminOrSuperAdminActionTypes.IMPORT_AREA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.IMPORT_AREA_REJECTED:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.IMPORT_AREA_FULFILLED:
            return updateObject(state, { isLoading: false, isAccountAreaTab: true });

        case AdminOrSuperAdminActionTypes.IMPORT_CHECK_AREA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.IMPORT_CHECK_AREA_REJECTED:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.IMPORT_CHECK_AREA_FULFILLED:
            return updateObject(state, {
                isLoading: true,
                areaImportErrorLogs: action && action.payload && action.payload.error ? action.payload.error : null,
                isErrorAvailable: action && action.payload && action.payload.errorAvailable ? action.payload.errorAvailable : null
            });

        case AdminOrSuperAdminActionTypes.AREA_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.AREA_DATA_FULFILLED:
            return updateObject(state, { isLoading: false, allAreaData: action.payload.data.areas });
        case AdminOrSuperAdminActionTypes.NONE_LOGGED_IN_FRONTLINE_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.NONE_LOGGED_IN_FRONTLINE_DATA_FULFILLED:
            return updateObject(state, { isLoading: false, nonLoggedInFrontlineData: action.payload.data.users });
        case AdminOrSuperAdminActionTypes.AREA_OF_ACCOUNT_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.AREA_OF_ACCOUNT_REJECTED:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.AREA_OF_ACCOUNT_FULFILLED:
            return updateObject(state, { isLoading: false, areaOfAccount: { pending_schedules: action.payload.data.pending_schedules, future_schedules: action.payload.data.future_schedules } })
        case AdminOrSuperAdminActionTypes.SCHEDULE_STATUS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.SCHEDULE_STATUS_REJECTED:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.SCHEDULE_STATUS_FULFILLED:
            return updateObject(state, { isLoading: false });

        case AdminOrSuperAdminActionTypes.FUTURE_SCHEDULES_ON_AREA_PENDING:
            return updateObject(state, { isAreaLoading: true })
        case AdminOrSuperAdminActionTypes.FUTURE_SCHEDULES_ON_AREA_FULFILLED:
            return updateObject(state, { isAreaLoading: false, upcommingAreaScheduleList: action.payload })

        case AdminOrSuperAdminActionTypes.GET_HOLIDAYS_LIST_PENDING:
            return updateObject(state, { isHolidaysLoading: true })
        case AdminOrSuperAdminActionTypes.GET_HOLIDAYS_LIST_FULFILLED:
            return updateObject(state, { isHolidaysLoading: false, totalHolidays: action.payload.holiday_count, holidaysList: action.payload.holidays })

        case AdminOrSuperAdminActionTypes.ADD_NEW_HOLIDAY_PENDING:
            return updateObject(state, { isHolidaysLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_NEW_HOLIDAY_FULFILLED:
            return updateObject(state, { isHolidaysLoading: false, isOpenModel: action.payload.openModal, totalHolidays: action.payload.holiday_count, holidaysList: action.payload.holidaysList })

        case AdminOrSuperAdminActionTypes.EDIT_HOLIDAY_CLICKED:
            return updateObject(state, { holiday: action.payload, isOpenModel: true, isEdit: true })

        case AdminOrSuperAdminActionTypes.UPDATE_HOLIDAY_PENDING:
            return updateObject(state, { isHolidaysLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_HOLIDAY_FULFILLED:
            return updateObject(state, { isHolidaysLoading: false, isOpenModel: action.payload.openModal, holidaysList: action.payload.holidaysList, isEdit: action.payload.isEdit })

        case AdminOrSuperAdminActionTypes.DELETE_HOLIDAY_PENDING:
            return updateObject(state, { isHolidaysLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_HOLIDAY_FULFILLED:
            return updateObject(state, { isHolidaysLoading: false, openDeleteModel: false, holidaysList: action.payload })

        case AdminOrSuperAdminActionTypes.SET_ACTIVE_INACTIVE_AREAS_PENDING:
            return updateObject(state, { isAreaActiveInActivePending: true })
        case AdminOrSuperAdminActionTypes.SET_ACTIVE_INACTIVE_AREAS_FULFILLED:
            return updateObject(state, { isAreaActiveInActivePending: false, accountAreas: action.payload.areas })
        case AdminOrSuperAdminActionTypes.SUBMIT_CHI_REVIEW_PENDING:
            return updateObject(state, { chi_review_loading: true })
        case AdminOrSuperAdminActionTypes.SUBMIT_CHI_REVIEW_FULFILLED:
            return updateObject(state, { chi_review_loading: false })

        case AdminOrSuperAdminActionTypes.GET_ALL_CHI_SERVYS_PENDING:
            return updateObject(state, { chi_servyLoading: true })
        case AdminOrSuperAdminActionTypes.GET_ALL_CHI_SERVYS_FULFILLED:

            return updateObject(state, {
                chi_servyLoading: false,
                // chi_page: action.payload.chi_page,
                chi_no_of_rows: action.payload.chi_no_of_rows,
                chiServyList: action.payload.chiServyList,
                chi_total_count: action.payload.chi_total_count,
                chi_total_no_of_pages: action.payload.chi_total_no_of_pages,
                chi_cur_page: action.payload.chi_cur_page
            })

        case AdminOrSuperAdminActionTypes.GET_CHI_DASHBOARD_PENDING:
            return updateObject(state, { chi_servyLoading: true })
        case AdminOrSuperAdminActionTypes.GET_CHI_DASHBOARD_FULFILLED:

            return updateObject(state, {
                chi_servyLoading: false,
                chiChartData: action.payload.chiChartData
            })
        case AdminOrSuperAdminActionTypes.LOGIN_USER_MODE_SELECTION_PENDING:
            return updateObject(state, {
                isLoading: true, isAccountLoading: true, isInspectionLoading: true,
                isTicketPending: true, isQuotesLoading: true, isUserLoading: true, isInspectionForm: true, modeAccountsList: null, inspectionFormsList: null
            })
        case AdminOrSuperAdminActionTypes.LOGIN_USER_MODE_SELECTION_FULFILLED:
            return updateObject(state, {
                isLoading: false, isAccountLoading: false, isInspectionLoading: false,
                isTicketPending: false, isQuotesLoading: false, isUserLoading: false, isInspectionForm: false, inspectionFormsList: null
            })
        // case AdminOrSuperAdminActionTypes.APPROVE_OR_REJECT_QUOTE_FULFILLED:
        //     return updateObject(state, { openRejectQuoteModel: false, isLoading: !action.payload, quotesList: action.payload, searchedQuoteList: action.payload })

        case AdminOrSuperAdminActionTypes.GET_ALL_QR_CODES_PENDING:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.GET_ALL_QR_CODES_REJECTED:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.GET_ALL_QR_CODES_FULFILLED:
            return updateObject(state, { isLoading: false, allQRCodes: action.payload.data.areas });

        case AdminOrSuperAdminActionTypes.QQV_FEEDBACK_PENDING:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.QQV_FEEDBACK_FULFILLED:
            return updateObject(state, { isLoading: false, inspection: action.payload.inspection });

        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_EXPORT_DATA_PENDING:
            return updateObject(state, { isLoading: true, isAccountLoading: true })
        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_EXPORT_DATA_FULFILLED:
            return updateObject(state, { isLoading: false, isAccountLoading: false, accountExportData: action.payload && action.payload.data.accounts });

        case AdminOrSuperAdminActionTypes.GET_USER_EXPORT_DATA_PENDING:
            return updateObject(state, { isLoading: true, isAccountLoading: true })
        case AdminOrSuperAdminActionTypes.GET_USER_EXPORT_DATA_FULFILLED:
            return updateObject(state, { isLoading: false, isAccountLoading: false, userExportData: action.payload && action.payload.data.users });

        case AdminOrSuperAdminActionTypes.MAKE_ACCOUNT_USER_AS_OWNER_PENDING:
            return updateObject(state, { isLoading: true, isAccountLoading: true })
        case AdminOrSuperAdminActionTypes.MAKE_ACCOUNT_USER_AS_OWNER_FULFILLED:
            return updateObject(state, { isLoading: false, isAccountLoading: false, account: action.payload });

        case AdminOrSuperAdminActionTypes.ACCOUNT_ACTIVE_CHANGED:
            return updateObject(state, { activeJobs: !state.activeJobs })

        case AdminOrSuperAdminActionTypes.USERS_ACTIVE_CHANGED:
            return updateObject(state, { activeUsers: !state.activeUsers })

        case AdminOrSuperAdminActionTypes.COMPANY_ACTIVE_CHANGED:
            return updateObject(state, { activeCompanies: !state.activeCompanies })

        case AdminOrSuperAdminActionTypes.GET_ASSOCIATED_INSPECTIONS_PENDING:
            return updateObject(state, { isLoading: true, isAccountLoading: true })
        case AdminOrSuperAdminActionTypes.GET_ASSOCIATED_INSPECTIONS_FULFILLED:
            return updateObject(state, { isLoading: false, isAccountLoading: false, account: action.payload });

        case AdminOrSuperAdminActionTypes.GET_CHI_PREVIEW_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_CHI_PREVIEW_FULFILLED:
            return updateObject(state, { isLoading: false, chi: action.payload })

        case AdminOrSuperAdminActionTypes.CHI_PREVIEW:
            return updateObject(state, { isLoading: true, chi: null })

        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_ADMINLIST_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_ADMINLIST_FULFILLED:
            return updateObject(state, { isLoading: false, accountAdminList: action.payload })

        case AdminOrSuperAdminActionTypes.VIEW_QUOTE_STATUS_CLICKED:
            return updateObject(state, { viewQuoteStatus: true })

        case AdminOrSuperAdminActionTypes.GET_INSPECTION_INDEX_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_INSPECTION_INDEX_FULFILLED:
            return updateObject(state, { isLoading: false, inspectionIndex: action.payload })

        case AdminOrSuperAdminActionTypes.GET_USER_INSPECTION_INDEX_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_USER_INSPECTION_INDEX_FULFILLED:
            return updateObject(state, { isLoading: false, userInspectionIndex: action.payload })

        case AdminOrSuperAdminActionTypes.INVENTARY_CSV:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.INVENTARY_CSV_FULFILLED:
            return updateObject(state, { data_list: action.payload.data_list, fields: action.payload.fields, convertedData: action.payload.convertedData })

        case AdminOrSuperAdminActionTypes.GET_INSPECTION_PDF_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_INSPECTION_PDF_FULFILLED:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.GET_COMPANY_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })

        case AdminOrSuperAdminActionTypes.GET_KPI_DASHBOARD_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_KPI_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiInspectionData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })

        case AdminOrSuperAdminActionTypes.GET_KPI_INS_QQV_SCRORE_DASHBOARD_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_KPI_INS_QQV_SCRORE_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiInspectionQQvScore: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })

        case AdminOrSuperAdminActionTypes.GET_KPI_INS_QQV_ROOTCASE_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiInspectionRootcaseData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })

        case AdminOrSuperAdminActionTypes.GET_KPI_INS_DEFICENCY_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiInspectionDeficencyData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })


        case AdminOrSuperAdminActionTypes.GET_KPI_INS_DATE_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiInspectionDateData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })

        case AdminOrSuperAdminActionTypes.GET_KPI_TOP_INS_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiTopInspectionData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })

        case AdminOrSuperAdminActionTypes.CHANGE_HIDE_STATUS:
            return updateObject(state, { hideRating: action.payload })

        case AdminOrSuperAdminActionTypes.GET_KPI_INS_ACCOUNT_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiInspectionAccountData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })
        case AdminOrSuperAdminActionTypes.GET_KPI_INS_PIECHART_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiInspectionPieChartData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })

        case AdminOrSuperAdminActionTypes.GET_KPI_INS_CUSTOMER_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiInspectionCustomerData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })
        case AdminOrSuperAdminActionTypes.GET_KPI_INS_QQV_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiInspectionQQvData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })

        case AdminOrSuperAdminActionTypes.GET_COMPANY_LIST_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_COMPANY_LIST_FULFILLED:
            return updateObject(state, { companyList: action.payload.company })

        case AdminOrSuperAdminActionTypes.KPI_RESET_CLICKED:
            return updateObject(state, {})

        case AdminOrSuperAdminActionTypes.GET_KPI_TICKET_COUNT_DASHBOARD_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_KPI_TICKET_COUNT_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiTicketCountData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })
        case AdminOrSuperAdminActionTypes.GET_KPI_TICKET_GRAPH_DASHBOARD_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_KPI_TICKET_GRAPH_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiTicketGraphData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })
        case AdminOrSuperAdminActionTypes.GET_KPI_TICKET_DAYSOPEN_DASHBOARD_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_KPI_TICKET_DAYSOPEN_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiTicketDaysOpenData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })
        case AdminOrSuperAdminActionTypes.GET_KPI_TICKET_HIGHUSERS_DASHBOARD_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_KPI_TICKET_HIGHUSERS_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiTicketHightUsersData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })
        case AdminOrSuperAdminActionTypes.GET_KPI_TICKET_JOBS_DASHBOARD_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_KPI_TICKET_JOBS_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, { kpiTicketJobData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })
        case AdminOrSuperAdminActionTypes.GET_KPI_INSPECTION_COUNT_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_KPI_INSPECTION_COUNT_FULFILLED:
            return updateObject(state, { kpiInspectionCount: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })
        case AdminOrSuperAdminActionTypes.GET_KPI_INSPECTION_AVERAGESCRORE_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_KPI_INSPECTION_AVERAGESCRORE_FULFILLED:
            return updateObject(state, { kpiInspectionAverageScore: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })
        case AdminOrSuperAdminActionTypes.GET_KPI_TOP_INSPECTOR_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_KPI_TOP_INSPECTOR_FULFILLED:
            return updateObject(state, { kpiTopInspector: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })
        case AdminOrSuperAdminActionTypes.GET_KPI_QUALITY_INSPECTION_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_KPI_QUALITY_INSPECTION_FULFILLED:
            return updateObject(state, { kpiQualityInspectionData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })
        case AdminOrSuperAdminActionTypes.GET_KPI_AVERAGE_SECTION_QUALITY_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_KPI_AVERAGE_SECTION_QUALITY_FULFILLED:
            return updateObject(state, { isLoading: false, kpiAverageSectionQualityData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })
        case AdminOrSuperAdminActionTypes.GET_KPI_AVERAGE_SECTION_SAFETY_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_KPI_AVERAGE_SECTION_SAFETY_FULFILLED:
            return updateObject(state, { kpiAverageSectionSafetyData: action.payload.data, kpiSearchValues: action.payload.kpiSearchValues })
        case AdminOrSuperAdminActionTypes.GET_AVG_MODAL_DATA_PENDING:
            return updateObject(state, { isLoading: true, avgModalData: [] })
        case AdminOrSuperAdminActionTypes.GET_AVG_MODAL_DATA_FULFILLED:
            return updateObject(state, { isLoading: false, avgModalData: action.payload.data, kpiSectionValues: action.payload.kpiSectionValues })
        case AdminOrSuperAdminActionTypes.GET_QUALITY_MODAL_DATA_PENDING:
            return updateObject(state, { isLoading: true, qualityModalData: [] })
        case AdminOrSuperAdminActionTypes.GET_QUALITY_MODAL_DATA_FULFILLED:
            return updateObject(state, { isLoading: false, qualityModalData: action.payload.data, kpiSectionValues: action.payload.kpiSectionValues })
        case AdminOrSuperAdminActionTypes.GET_AVERAGE_GRAPH_INSPECTIONS_PENDING:
            return updateObject(state, { isLoading: true, averageGraphInspections: [] })
        case AdminOrSuperAdminActionTypes.GET_AVERAGE_GRAPH_INSPECTIONS_FULFILLED:
            return updateObject(state, { isLoading: false, averageGraphInspections: action.payload.data })



        case AdminOrSuperAdminActionTypes.GET_CRITERIAS_LIST_PENDING:
            return updateObject(state, { isLoading: true, isCriteria: true })
        case AdminOrSuperAdminActionTypes.GET_CRITERIAS_LIST_FULFILLED:
            return updateObject(state, { isLoading: false, isCriteria: false, criteries: action.payload.criteries, totalCriteries: action.payload.totalCriteries })

        case AdminOrSuperAdminActionTypes.GET_CRITERIA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_CRITERIA_FULFILLED:
            return updateObject(state, { isLoading: false, criteria: action.payload })

        case AdminOrSuperAdminActionTypes.EDIT_CRITERIA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.EDIT_CRITERIA_FULFILLED:
            return updateObject(state, { isLoading: false, criteria: null, criteries: action.payload })

        case AdminOrSuperAdminActionTypes.DELETE_CRITERIA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_CRITERIA_FULFILLED:
            return updateObject(state, { isLoading: false, openDeleteModel: false })

        case AdminOrSuperAdminActionTypes.CREATE_CRITERIA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.CREATE_CRITERIA_FULFILLED:
            return updateObject(state, { isLoading: false, criteries: action.payload })

        case AdminOrSuperAdminActionTypes.EDIT_CRITERIA_CLICKED:
            return updateObject(state, { criteria: { criteria: action.payload }, isOpenModel: true, isEdit: true })
        case AdminOrSuperAdminActionTypes.ADD_NEW_SCHEDULE_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_NEW_SCHEDULE_FULFILLED:
            return updateObject(state, { isLoading: false, schedule: action.payload, openScheduleOutlookConfirmModal: false })
        case AdminOrSuperAdminActionTypes.GET_ALL_SCHEDULE_DATA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_ALL_SCHEDULE_DATA_FULFILLED:
            return updateObject(state, { isLoading: false, getScheduleList: action.payload.events })
        case AdminOrSuperAdminActionTypes.GET_SCHEDULE_EVENTS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_SCHEDULE_EVENTS_FULFILLED:
            return updateObject(state, { isLoading: false, getScheduleEventsList: action.payload.events })
        case AdminOrSuperAdminActionTypes.GET_SCHEDULE_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_SCHEDULE_FULFILLED:
            return updateObject(state, { isLoading: false, scheduleId: action.payload.schedules })
        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_SCHEDULE_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_ACCOUNT_SCHEDULE_FULFILLED:
            return updateObject(state, { isLoading: false, scheduleWithAccountId: action.payload.events })
        case AdminOrSuperAdminActionTypes.DELETE_SCHEDULE_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_SCHEDULE_FULFILLED:
            return updateObject(state, { isLoading: false, openDeleteModel: false, openConfirmDeleteModel: false, openScheduleConfirmDeleteModel: false })
        case AdminOrSuperAdminActionTypes.DELETE_EVENT_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_EVENT_FULFILLED:
            return updateObject(state, { isLoading: false, getScheduleEventsList: action.payload.events, openDeleteModel: false, openConfirmDeleteModel: false, openScheduleConfirmDeleteModel: false })
        case AdminOrSuperAdminActionTypes.GET_SCHEDULE_EMAIL_EVENTS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_SCHEDULE_EMAIL_EVENTS_FULFILLED:
            return updateObject(state, { isLoading: false, getScheduleEmailEventsList: action.payload.events })
        case AdminOrSuperAdminActionTypes.GET_EVENT_DASHBOARD_DATA_PENDING:
            return updateObject(state, { isSchedulePending: true, SearchOrResetScheduleClicked: true, })
        case AdminOrSuperAdminActionTypes.GET_EVENT_DASHBOARD_DATA_FULFILLED:
            return updateObject(state, {
                isSchedulePending: false,
                SearchOrResetScheduleClicked: false,
                getScheduleDashboardData: action.payload.schedules,
                schedule_page: action.payload.schedule_page,
                schedule_no_of_rows: action.payload.schedule_no_of_rows,
                totalschedules: action.payload.totalschedules,
                scheduleCurPage: action.payload.scheduleCurPage,
                schedule_rows: action.payload.schedule_rows
            })
        case AdminOrSuperAdminActionTypes.HANDLE_CLICK_SCHEDULE_OCCURRENCE_MODAL:
            return updateObject(state, { openScheduleOccurrenceModal: true })
        case AdminOrSuperAdminActionTypes.HANDLE_CLICK_SCHEDULE_OUTLOOK_CONFIRM_MODAL:
            return updateObject(state, { openScheduleOutlookConfirmModal: true })

        case AdminOrSuperAdminActionTypes.SCHEDULE_HISTORY_PENDING:
            return updateObject(state, { isLoading: true, isScheduleHistoryLoading: true })
        case AdminOrSuperAdminActionTypes.SCHEDULE_HISTORY_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                scheduleHistory_logList: action.payload.scheduleLogList,
                total_no_of_pages: action.payload.totalPages,
                scheduleHistory_totalListCount: action.payload.totalListCount,
                fl_user_rows: action.payload.scheduleHistoryRows ? action.payload.scheduleHistoryRows : null,
                scheduleHistoryCurPage: action.payload.scheduleHistoryCurPage
            })

        case AdminOrSuperAdminActionTypes.SCHEDULE_HISTORY_PAGE_CLICKED:
            return updateObject(state, { scheduleHistoryCurPage: action.payload })

        case AdminOrSuperAdminActionTypes.INSPECTIONS_CLICKED:
            return updateObject(state, { inspectionToggleSettings: !state.inspectionToggleSettings })
        case AdminOrSuperAdminActionTypes.TICKETS_CLICKED:
            return updateObject(state, { ticketToggle: !state.ticketToggle })
        case AdminOrSuperAdminActionTypes.PEGASSURE_TAB_CLICKED:
            return updateObject(state, { pegAssureTabToggle: !state.pegAssureTabToggle })
        case AdminOrSuperAdminActionTypes.GET_MY_TICKETS_PENDING:
            return updateObject(state, { isLoading: true, isTicketPending: true })
        case AdminOrSuperAdminActionTypes.GET_MY_TICKETS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isTicketPending: false,
                dataGenerated: false,
                ticketsList: action.payload.tickets,
                ticketIndex: action.payload.ticketIds,
                ticket_page: action.payload.ticket_page,
                ticket_no_of_rows: action.payload.ticket_no_of_rows ? action.payload.ticket_no_of_rows : state.ticket_no_of_rows,
                totalTickets: action.payload.totalTickets,
                ticket_rows: action.payload.ticket_rows ? action.payload.ticket_rows : null,
                searchTicketValues: action.payload.searchTicketValues,
                dashboardTicketCount: action.payload.dashboardTicketCount,
                searchInspectionValues: null,
                scheduleSearchTicketValues: null,
                previewScheduleSearchTicketValues: null,
                searchQuoteValues: null,
                searchaccountValues: null,
                searchUserValues: {},
                // sort_users_column: action.payload.sort_users_column,
                // users_order: action.payload.users_order,
            })
        case AdminOrSuperAdminActionTypes.GET_SCHEDULED_TICKET_PENDING:
            return updateObject(state, { isLoading: true, isTicketPending: true })
        case AdminOrSuperAdminActionTypes.GET_SCHEDULED_TICKET_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isTicketPending: false,
                dataGenerated: false,
                scheduleTicketList: action.payload.tickets,
                scheduleTicketIndex: action.payload.ticketIds,
                schedule_ticket_page: action.payload.ticket_page,
                schedule_ticket_no_of_rows: action.payload.ticket_no_of_rows ? action.payload.ticket_no_of_rows : state.schedule_ticket_no_of_rows,
                scheduleTotalTickets: action.payload.totalTickets,
                schedule_ticket_rows: action.payload.ticket_rows ? action.payload.ticket_rows : null,
                scheduleSearchTicketValues: action.payload.searchTicketValues.value,
                dashboardTicketCount: action.payload.dashboardTicketCount,
                previewScheduleSearchTicketValues: null,
                searchInspectionValues: null,
                searchQuoteValues: null,
                searchaccountValues: null,
                searchUserValues: {},
                project_module_ticket_no_of_rows: action.payload.ticket_no_of_rows ? action.payload.ticket_no_of_rows : state.project_module_ticket_no_of_rows,
            })
        case AdminOrSuperAdminActionTypes.GET_PREVIEW_SCHEDULED_TICKETS_PENDING:
            return updateObject(state, { isLoading: true, isTicketPending: true })
        case AdminOrSuperAdminActionTypes.GET_PREVIEW_SCHEDULED_TICKETS_FULFILLED:

            return updateObject(state, {
                isLoading: false,
                isTicketPending: false,
                dataGenerated: false,
                previewScheduleTicketList: action.payload.tickets,
                previewScheduleTicketIndex: action.payload.ticketIds,
                preview_schedule_ticket_page: action.payload.ticket_page,
                preview_schedule_ticket_no_of_rows: action.payload.ticket_no_of_rows ? action.payload.ticket_no_of_rows : state.preview_schedule_ticket_no_of_rows,
                previewScheduleTotalTickets: action.payload.totalTickets,
                preview_schedule_ticket_rows: action.payload.ticket_rows ? action.payload.ticket_rows : null,
                previewScheduleSearchTicketValues: action.payload.previewScheduleSearchTicketValues.value,
                dashboardTicketCount: action.payload.dashboardTicketCount,
                searchInspectionValues: null,
                searchQuoteValues: null,
                searchaccountValues: null,
                searchUserValues: {},
                // sort_users_column: action.payload.sort_users_column,
                // users_order: action.payload.users_order,
            })
        case AdminOrSuperAdminActionTypes.GET_PRIOR_TICKETS_PENDING:
            return updateObject(state, { isLoading: true, isPriorTicketPending: true })
        case AdminOrSuperAdminActionTypes.GET_PRIOR_TICKETS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isPriorTicketPending: false,
                dataGenerated: false,
                prior_ticketsList: action.payload.tickets,
                prior_ticketIndex: action.payload.ticketIds,
                ticket_page: action.payload.ticket_page,
                ticket_no_of_rows: action.payload.ticket_no_of_rows ? action.payload.ticket_no_of_rows : state.ticket_no_of_rows,
                prior_totalTickets: action.payload.totalTickets,
                ticket_rows: action.payload.ticket_rows ? action.payload.ticket_rows : null,
                searchTicketValues: action.payload.searchTicketValues,
                dashboardTicketCount: action.payload.dashboardTicketCount,
                scheduleSearchTicketValues: null,
                previewScheduleSearchTicketValues: null,
                searchInspectionValues: null,
                searchQuoteValues: null,
                searchaccountValues: null,
                searchUserValues: {},
                openTicketCount: action.payload.openTicketCount,
                acceptedTicketCount: action.payload.acceptedTicketCount,
                resolvedTicketCount: action.payload.resolvedTicketCount,
                // sort_users_column: action.payload.sort_users_column,
                // users_order: action.payload.users_order,
            })
        case AdminOrSuperAdminActionTypes.GET_USER_TICKETS_PENDING:
            return updateObject(state, { isLoading: true, isTicketPending: true })
        case AdminOrSuperAdminActionTypes.GET_USER_TICKETS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isTicketPending: false,
                dataGenerated: false,
                ticketsList: action.payload.tickets,
                ticketIndex: action.payload.ticketIds,
                ticket_page: action.payload.ticket_page,
                ticket_no_of_rows: action.payload.ticket_no_of_rows ? action.payload.ticket_no_of_rows : state.ticket_no_of_rows,
                totalTickets: action.payload.totalTickets,
                ticket_rows: action.payload.ticket_rows ? action.payload.ticket_rows : null,
                searchTicketValues: action.payload.searchTicketValues,
                dashboardTicketCount: action.payload.dashboardTicketCount,
                scheduleSearchTicketValues: null,
                previewScheduleSearchTicketValues: null,
                searchInspectionValues: null,
                searchQuoteValues: null,
                searchaccountValues: null,
                searchUserValues: {},
                // sort_users_column: action.payload.sort_users_column,
                // users_order: action.payload.users_order,
            })
        case AdminOrSuperAdminActionTypes.UPDATE_PENDING_INSPECTION_PENDING:
            return updateObject(state, { prformInspectionLoading: true, isLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_PENDING_INSPECTION_FULFILLED:
            return updateObject(state, { isInspectionLoading: false, isLoading: false, inspectionsList: action.payload.InspectionsList, prformInspectionLoading: action.payload.isLoading ? action.payload.isLoading : false })

        case AdminOrSuperAdminActionTypes.RESET_INSPECTION_SEARCH:
            return updateObject(state, { searchInspectionValues: null, })

        case AdminOrSuperAdminActionTypes.RESET_TICKET_SEARCH:
            return updateObject(state, { searchTicketValues: null, scheduleSearchTicketValues: null, previewScheduleSearchTicketValues: null })

        case AdminOrSuperAdminActionTypes.CHECK_OWNER_INFO_PENDING:
            return updateObject(state, { isAccountOwner: false })
        case AdminOrSuperAdminActionTypes.CHECK_OWNER_INFO_FULFILLED:
            return updateObject(state, { isAccountOwner: action.payload })
        case AdminOrSuperAdminActionTypes.COMPLETED_APPROVE_MODAL_CLICKED:
            return updateObject(state, { completeApporveModal: true })
        case AdminOrSuperAdminActionTypes.CLOSE_COMPLETE_APPROVE_CLOSE:
            return updateObject(state, { completeApporveModal: false })
        case AdminOrSuperAdminActionTypes.GET_LATEST_INSPECTION_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_LATEST_INSPECTION_FULFILLED:
            return updateObject(state, { isLoading: false, latestInspectionList: action.payload })
        case AdminOrSuperAdminActionTypes.SET_LATEST_INSPECTION:
            return updateObject(state, { latestInspectionId: action.payload })
        case AdminOrSuperAdminActionTypes.CLEAR_LATEST_INSPECTION:
            return updateObject(state, { latestInspectionList: '' })

        case AdminOrSuperAdminActionTypes.GET_LOGBOOK_AREA_PREVIEW_PENDING:
            return updateObject(state, { isLogbookAreas: true });
        case AdminOrSuperAdminActionTypes.GET_LOGBOOK_AREA_PREVIEW_FULFILLED:
            return updateObject(state, {
                isLogbookAreas: false,
                logbookAreasList: action.payload.logbookArea_List,
                totalLogbookAreas: action.payload.total_LogbookArea,
                total_logbookArea_pages: action.payload.total_LogbookArea_Pages,
                logbook_rows: action.payload.rows,
                logbookPreviewCurPage: action.payload.logbookPreviewCurPage

            });

        case AdminOrSuperAdminActionTypes.PREVIEW_LOGBOOK_AREA_QR_SCAN_CLICKED:
            return updateObject(state, { viewLogbookQrScan: true })

        case AdminOrSuperAdminActionTypes.CREATE_LOGBOOK_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.CREATE_LOGBOOK_FULFILLED:
            return updateObject(state, { isLoading: false, isAccountAreaTab: true })

        case AdminOrSuperAdminActionTypes.UPDATE_LOGBOOK_PENDING:
            return updateObject(state, { isLoading: true, isAccountAreas: true, isAccountAreaTab: true });
        case AdminOrSuperAdminActionTypes.UPDATE_LOGBOOK_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isAccountAreas: false,
                isAccountAreaTab: true,
                accountArea: null
            });

        case AdminOrSuperAdminActionTypes.GET_LOGBOOK_PENDING:
            return updateObject(state, { isLoading: true, isAccountAreas: true, accountArea: null });
        case AdminOrSuperAdminActionTypes.GET_LOGBOOK_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                accountArea: action.payload.accountArea,
                isAccountAreas: false, isAccountAreaTab: true
            });

        case AdminOrSuperAdminActionTypes.CLEAR_LOGBOOK_AREA:
            return updateObject(state, { accountArea: null })

        case AdminOrSuperAdminActionTypes.GET_LOGBOOK_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.CREATE_LOGBOOK_LOG_FULFILLED:
            return updateObject(state, { isLoading: false })

        case AdminOrSuperAdminActionTypes.CREATE_LOGBOOK_LOG_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.CREATE_LOGBOOK_LOG_FULFILLED:
            return updateObject(state, { isLoading: false })

        case AdminOrSuperAdminActionTypes.GET_ALL_LOGHISTORY_AREA_DETAILS_FULFILLED:
            return updateObject(state, {
                accountFloorList: action.payload.data.floors,
                accountAreaTypes: action.payload.data.area_types,
                logbookNames: action.payload.data.logbook_name
            })

        case AdminOrSuperAdminActionTypes.SEARCH_LOGHISTORY_PENDING:
            return updateObject(state, {
                isLoading: true,
                searchLogHistory: null,
                // areaSearchResult: null
            });
        case AdminOrSuperAdminActionTypes.SEARCH_LOGHISTORY_REJECTED:
            return updateObject(state, {
                isLoading: false,
                searchLogHistory: null
            });
        case AdminOrSuperAdminActionTypes.SEARCH_LOGHISTORY_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                searchLogHistory: {
                    // last_sync: action.payload.data.last_sync,
                    logbook_logs: action.payload.data.logbookLogs,
                    // area: action.payload.data.areas[0],
                    timezone: action.payload.data.timezone,
                    // total_no_of_pages: action.payload.data.total_no_of_pages
                },
                total_no_of_pages: action.payload.data.total_no_of_pages,
            });

        case AdminOrSuperAdminActionTypes.OPEN_CAMERA_CLICKED:
            return updateObject(state, { openCameraModal: true })

        case AdminOrSuperAdminActionTypes.CLOSE_CAMERA_CLICKED:
            return updateObject(state, { openCameraModal: false })

        case AdminOrSuperAdminActionTypes.CLOSE_DEFICIENCY_CLICKED:
            return updateObject(state, { openDeficiencyModal: false })

        case AdminOrSuperAdminActionTypes.OPEN_DEFICIENCY_CLICKED:
            return updateObject(state, { openDeficiencyModal: true })

        case AdminOrSuperAdminActionTypes.SUBMIT_FEEDBACK_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.SUBMIT_FEEDBACK_PRESIGNED_URL_FULFILLED:
            return updateObject(state, { isLoading: true })

        case AdminOrSuperAdminActionTypes.SUBMIT_FEEDBACK_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.SUBMIT_FEEDBACK_FULFILLED:
            return updateObject(state, { isLoading: false })

        case AdminOrSuperAdminActionTypes.GET_PEGASSURE_FEEDBACKS_PENDING:
            return updateObject(state, { isLoading: true, isPegassureLoading: true })
        case AdminOrSuperAdminActionTypes.GET_PEGASSURE_FEEDBACKS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isPegassureLoading: false,
                pegassureFeedbacks: action.payload.pegassureFeedbacks,
                feedbacks_page: action.payload.feedbacks_page,
                feedbacks_no_of_rows: action.payload.feedbacks_no_of_rows,
                totalFeedbacks: action.payload.totalFeedbacks,
                feedbacksCurPage: action.payload.feedbacksCurPage,
                dataGenerated: false,
                feedbacks_rows: action.payload.feedbacks_rows ? action.payload.feedbacks_rows : null,
                searchfeedbacksValues: action.payload.searchfeedbacksValues
            })

        case AdminOrSuperAdminActionTypes.SEARCH_PEGASSURE_FEEDBACKS_PENDING:
            return updateObject(state, { isLoading: true, isPegassureLoading: true, SearchOrResetPegassureClicked: true })
        case AdminOrSuperAdminActionTypes.SEARCH_PEGASSURE_FEEDBACKS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isPegassureLoading: false,
                pegassureFeedbacks: action.payload.pegassureFeedbacks,
                feedbacks_page: action.payload.feedbacks_page,
                feedbacks_no_of_rows: action.payload.feedbacks_no_of_rows,
                totalFeedbacks: action.payload.totalFeedbacks,
                dataGenerated: false,
                feedbacksCurPage: action.payload.feedbacksCurPage,
                feedbacks_rows: action.payload.feedbacks_rows ? action.payload.feedbacks_rows : null,
                searchfeedbacksValues: action.payload.searchfeedbacksValues
            })

        case AdminOrSuperAdminActionTypes.GET_FEEDBACK_PENDING:
            return updateObject(state, { isLoading: true, })
        case AdminOrSuperAdminActionTypes.GET_FEEDBACK_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                feedback: action.payload.feedback
            })

        case AdminOrSuperAdminActionTypes.PREVIEW_FEEDBACK:
            return updateObject(state, { feedback: action.payload })

        case AdminOrSuperAdminActionTypes.UPDATE_SEARCH_LOGBOOK_VALUES:
            return updateObject(state, { searchLogbookValues: action.payload })

        case AdminOrSuperAdminActionTypes.IMPORT_LOGBOOK_AREA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.IMPORT_LOGBOOK_AREA_REJECTED:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.IMPORT_LOGBOOK_AREA_FULFILLED:
            return updateObject(state, { isLoading: false });

        case AdminOrSuperAdminActionTypes.IMPORT_CHECK_LOGBOOK_AREA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.IMPORT_CHECK_LOGBOOK_AREA_REJECTED:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.IMPORT_CHECK_LOGBOOK_AREA_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                logbookImportErrorLogs: action && action.payload && action.payload.error ? action.payload.error : null,
                isErrorAvailable: action && action.payload && action.payload.errorAvailable ? action.payload.errorAvailable : null
            });

        case AdminOrSuperAdminActionTypes.CREATE_NEW_SCHEDULE_TICKET_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.CREATE_NEW_SCHEDULE_TICKET_FULFILLED:
            return updateObject(state, { isLoading: false, scheduleTicket: action.payload, openScheduleOutlookConfirmModal: false })

        case AdminOrSuperAdminActionTypes.GET_SCHEDULE_TICKET_EVENTS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_SCHEDULE_TICKET_EVENTS_FULFILLED:
            return updateObject(state, { isLoading: false, getScheduleTicketEventsList: action.payload.events })

        case AdminOrSuperAdminActionTypes.GET_ONE_SCHEDULE_TICKET_EVENT_INFO_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_ONE_SCHEDULE_TICKET_EVENT_INFO_FULFILLED:
            return updateObject(state, { isLoading: false, scheduleTicketOneEvent: action.payload.scheduleTicket })

        case AdminOrSuperAdminActionTypes.HANDLE_OPEN_SCHEDULE_TICKET_DELETE_MODAL:
            return updateObject(state, { deletingScheduleTicketId: action.payload, openScheduleTicketDeleteModal: true })

        case AdminOrSuperAdminActionTypes.DELETE_CURRENT_SCHEDULE_TICKET_EVENT_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_CURRENT_SCHEDULE_TICKET_EVENT_FULFILLED:
            return updateObject(state, { isLoading: false, deleteScheduleTicketEventAction: false, openScheduleTicketDeleteModal: false, deleteEntireScheduleTicketEventsAction: false })

        case AdminOrSuperAdminActionTypes.DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS_FULFILLED:
            return updateObject(state, { isLoading: false, deleteEntireScheduleTicketEventsAction: false, openScheduleTicketDeleteModal: false, deleteScheduleTicketEventAction: false, })

        case AdminOrSuperAdminActionTypes.HANDLE_DELETE_SCHEDULE_TICKET_EVENT:
            return updateObject(state, { deletingScheduleTicketId: action.payload, deleteScheduleTicketEventAction: true, })

        case AdminOrSuperAdminActionTypes.HANDLE_DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS:
            return updateObject(state, { deletingScheduleTicketId: action.payload, deleteEntireScheduleTicketEventsAction: true, })

        case AdminOrSuperAdminActionTypes.GET_PREVIEW_SINGLE_TICKET_PENDING:
            return updateObject(state, { isLoading: true, ticket: {} })
        case AdminOrSuperAdminActionTypes.GET_PREVIEW_SINGLE_TICKET_FULFILLED:
            return updateObject(state, { isLoading: false, ticket: action.payload })

        case AdminOrSuperAdminActionTypes.GET_SCHEDULE_TICKET_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })

        case AdminOrSuperAdminActionTypes.SCHEDULE_TICKET_HISTORY_PENDING:
            return updateObject(state, { isLoading: true, isScheduleTicketHistoryLoading: true })
        case AdminOrSuperAdminActionTypes.SCHEDULE_TICKET_HISTORY_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                scheduleTicketHistory_logList: action.payload.scheduleTicketLogList,
                total_no_of_pages: action.payload.totalPages,
                scheduleTicketHistory_totalListCount: action.payload.totalListCount,
                fl_user_rows: action.payload.scheduleTicketHistoryRows ? action.payload.scheduleTicketHistoryRows : null,
                scheduleTicketHistoryCurPage: action.payload.scheduleTicketHistoryCurPage
            })

        case AdminOrSuperAdminActionTypes.SCHEDULE_TICKET_HISTORY_PAGE_CLICKED:
            return updateObject(state, { scheduleTicketHistoryCurPage: action.payload })
        case AdminOrSuperAdminActionTypes.DELETE_PREVIEW_SCHEDULED_TICKET_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_PREVIEW_SCHEDULED_TICKET_FULFILLED:
            return updateObject(state, {
                isLoading: false, previewScheduleTicketList: action.payload.TicketsList ? action.payload.TicketsList : state.ticketsList,
                openDeleteModel: false
            })

        case AdminOrSuperAdminActionTypes.CUSTOMER_CLICKED:
            return updateObject(state, { toggleCustomer: !state.toggleCustomer })

        case AdminOrSuperAdminActionTypes.GET_API_ACCESS_PENDING:
            return updateObject(state, { isLoading: true, isCompanyAccessPending: true })
        case AdminOrSuperAdminActionTypes.GET_API_ACCESS_FULFILLED:
            return updateObject(state, {
                companyAccessList: action.payload.company_access,
                company_access_page: action.payload.company_access_page,
                company_access_rows: action.payload.company_access_rows,
                company_access_cur_page: action.payload.company_access_cur_page,
                companyAccessSearchValues: action.payload.companyAccessSearchValues,
                total_company_access: action.payload.total_company_access,
                isLoading: false,
                isCompanyAccessPending: false,
                dataGenerated: false,
            })
        case AdminOrSuperAdminActionTypes.ADD_API_ACCESS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.ADD_API_ACCESS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                companyAccess: {}
            })

        case AdminOrSuperAdminActionTypes.COMPANY_ACCESS_PAGE_CLICKED:
            return updateObject(state, { company_access_cur_page: action.payload })

        case AdminOrSuperAdminActionTypes.COMPANY_ACCESS_ACTIVE_CHANGED:
            return updateObject(state, { activeCompanyAccess: !state.activeCompanyAccess })

        case AdminOrSuperAdminActionTypes.GET_ONE_COMPANY_ACCESS_PENDING:
            return updateObject(state, { isLoading: true });
        case AdminOrSuperAdminActionTypes.GET_ONE_COMPANY_ACCESS_FULFILLED:

            return updateObject(state, { companyAccess: action.payload.companyAccess, editCompanyAccessId: action.payload.editCompanyAccessId, isLoading: false });

        case AdminOrSuperAdminActionTypes.CANCEL_COMPANY_ACCESS_MODEL:
            return updateObject(state, { companyAccess: {}, isEdit: false })

        case AdminOrSuperAdminActionTypes.UPDATE_COMPANY_ACCESS_MODEL:
            return updateObject(state, { isEdit: true })

        case AdminOrSuperAdminActionTypes.UPDATE_API_ACCESS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPDATE_API_ACCESS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isEdit: false,
                companyAccess: {}
            })
        case AdminOrSuperAdminActionTypes.DELETE_API_ACCESS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_API_ACCESS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                openDeleteModel: false,
                companyAccessList: action.payload.companyAccessList ? action.payload.companyAccessList : state.companyAccessList
            })

        case AdminOrSuperAdminActionTypes.LOGBOOK_PREVIEW_PAGE_CLICKED:
            return updateObject(state, { logbookPreviewCurPage: action.payload })

        case AdminOrSuperAdminActionTypes.GET_CHISURVEY_QUARTERLY_CHECK_CHATTELS_PENDING:
            return updateObject(state, {
                isLoading: true,
            })
        case AdminOrSuperAdminActionTypes.GET_CHISURVEY_QUARTERLY_CHECK_CHATTELS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                chiSurveyCheck: action.payload
            })

        case AdminOrSuperAdminActionTypes.IMPORT_TICKET_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.IMPORT_TICKET_REJECTED:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.IMPORT_TICKET_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                ticketImportErrorLogs: action && action.payload && action.payload.error ? action.payload.error : null,
            });

        case AdminOrSuperAdminActionTypes.CLOSE_CAMERA_CLICKED:
            return updateObject(state, { openCameraModal: false })

        case AdminOrSuperAdminActionTypes.CLOSE_DEFICIENCY_CLICKED:
            return updateObject(state, { openDeficiencyModal: false })

        case AdminOrSuperAdminActionTypes.OPEN_DEFICIENCY_CLICKED:
            return updateObject(state, { openDeficiencyModal: true })

        case AdminOrSuperAdminActionTypes.SUBMIT_FEEDBACK_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.SUBMIT_FEEDBACK_PRESIGNED_URL_FULFILLED:
            return updateObject(state, { isLoading: true })

        case AdminOrSuperAdminActionTypes.SUBMIT_FEEDBACK_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.SUBMIT_FEEDBACK_FULFILLED:
            return updateObject(state, { isLoading: false })

        case AdminOrSuperAdminActionTypes.GET_PEGASSURE_FEEDBACKS_PENDING:
            return updateObject(state, { isLoading: true, isPegassureLoading: true })
        case AdminOrSuperAdminActionTypes.GET_PEGASSURE_FEEDBACKS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isPegassureLoading: false,
                pegassureFeedbacks: action.payload.pegassureFeedbacks,
                feedbacks_page: action.payload.feedbacks_page,
                feedbacks_no_of_rows: action.payload.feedbacks_no_of_rows,
                totalFeedbacks: action.payload.totalFeedbacks,
                feedbacksCurPage: action.payload.feedbacksCurPage,
                dataGenerated: false,
                feedbacks_rows: action.payload.feedbacks_rows ? action.payload.feedbacks_rows : null,
                searchfeedbacksValues: action.payload.searchfeedbacksValues
            })

        case AdminOrSuperAdminActionTypes.SEARCH_PEGASSURE_FEEDBACKS_PENDING:
            return updateObject(state, { isLoading: true, isPegassureLoading: true, SearchOrResetPegassureClicked: true })
        case AdminOrSuperAdminActionTypes.SEARCH_PEGASSURE_FEEDBACKS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isPegassureLoading: false,
                pegassureFeedbacks: action.payload.pegassureFeedbacks,
                feedbacks_page: action.payload.feedbacks_page,
                feedbacks_no_of_rows: action.payload.feedbacks_no_of_rows,
                totalFeedbacks: action.payload.totalFeedbacks,
                dataGenerated: false,
                feedbacksCurPage: action.payload.feedbacksCurPage,
                feedbacks_rows: action.payload.feedbacks_rows ? action.payload.feedbacks_rows : null,
                searchfeedbacksValues: action.payload.searchfeedbacksValues
            })

        case AdminOrSuperAdminActionTypes.GET_FEEDBACK_PENDING:
            return updateObject(state, { isLoading: true, })
        case AdminOrSuperAdminActionTypes.GET_FEEDBACK_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                feedback: action.payload.feedback
            })

        case AdminOrSuperAdminActionTypes.PREVIEW_FEEDBACK:
            return updateObject(state, { feedback: action.payload })

        case AdminOrSuperAdminActionTypes.UPDATE_SEARCH_LOGBOOK_VALUES:
            return updateObject(state, { searchLogbookValues: action.payload })

        case AdminOrSuperAdminActionTypes.IMPORT_LOGBOOK_AREA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.IMPORT_LOGBOOK_AREA_REJECTED:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.IMPORT_LOGBOOK_AREA_FULFILLED:
            return updateObject(state, { isLoading: false });

        case AdminOrSuperAdminActionTypes.IMPORT_CHECK_LOGBOOK_AREA_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.IMPORT_CHECK_LOGBOOK_AREA_REJECTED:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.IMPORT_CHECK_LOGBOOK_AREA_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                logbookImportErrorLogs: action && action.payload && action.payload.error ? action.payload.error : null,
                isErrorAvailable: action && action.payload && action.payload.errorAvailable ? action.payload.errorAvailable : null
            });

        case AdminOrSuperAdminActionTypes.CREATE_NEW_SCHEDULE_TICKET_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.CREATE_NEW_SCHEDULE_TICKET_FULFILLED:
            return updateObject(state, { isLoading: false, scheduleTicket: action.payload, openScheduleOutlookConfirmModal: false })

        case AdminOrSuperAdminActionTypes.GET_SCHEDULE_TICKET_EVENTS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_SCHEDULE_TICKET_EVENTS_FULFILLED:
            return updateObject(state, { isLoading: false, getScheduleTicketEventsList: action.payload.events })

        case AdminOrSuperAdminActionTypes.GET_ONE_SCHEDULE_TICKET_EVENT_INFO_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_ONE_SCHEDULE_TICKET_EVENT_INFO_FULFILLED:
            return updateObject(state, { isLoading: false, scheduleTicketOneEvent: action.payload.scheduleTicket })

        case AdminOrSuperAdminActionTypes.HANDLE_OPEN_SCHEDULE_TICKET_DELETE_MODAL:
            return updateObject(state, { deletingScheduleTicketId: action.payload, openScheduleTicketDeleteModal: true })

        case AdminOrSuperAdminActionTypes.DELETE_CURRENT_SCHEDULE_TICKET_EVENT_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_CURRENT_SCHEDULE_TICKET_EVENT_FULFILLED:
            return updateObject(state, { isLoading: false, deleteScheduleTicketEventAction: false, openScheduleTicketDeleteModal: false, deleteEntireScheduleTicketEventsAction: false })

        case AdminOrSuperAdminActionTypes.DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS_FULFILLED:
            return updateObject(state, { isLoading: false, deleteEntireScheduleTicketEventsAction: false, openScheduleTicketDeleteModal: false, deleteScheduleTicketEventAction: false, })

        case AdminOrSuperAdminActionTypes.HANDLE_DELETE_SCHEDULE_TICKET_EVENT:
            return updateObject(state, { deletingScheduleTicketId: action.payload, deleteScheduleTicketEventAction: true, })

        case AdminOrSuperAdminActionTypes.HANDLE_DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS:
            return updateObject(state, { deletingScheduleTicketId: action.payload, deleteEntireScheduleTicketEventsAction: true, })

        case AdminOrSuperAdminActionTypes.GET_PREVIEW_SINGLE_TICKET_PENDING:
            return updateObject(state, { isLoading: true, ticket: {} })
        case AdminOrSuperAdminActionTypes.GET_PREVIEW_SINGLE_TICKET_FULFILLED:
            return updateObject(state, { isLoading: false, ticket: action.payload })

        case AdminOrSuperAdminActionTypes.GET_SCHEDULE_TICKET_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })

        case AdminOrSuperAdminActionTypes.SCHEDULE_TICKET_HISTORY_PENDING:
            return updateObject(state, { isLoading: true, isScheduleTicketHistoryLoading: true })
        case AdminOrSuperAdminActionTypes.SCHEDULE_TICKET_HISTORY_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                scheduleTicketHistory_logList: action.payload.scheduleTicketLogList,
                total_no_of_pages: action.payload.totalPages,
                scheduleTicketHistory_totalListCount: action.payload.totalListCount,
                fl_user_rows: action.payload.scheduleTicketHistoryRows ? action.payload.scheduleTicketHistoryRows : null,
                scheduleTicketHistoryCurPage: action.payload.scheduleTicketHistoryCurPage
            })

        case AdminOrSuperAdminActionTypes.SCHEDULE_TICKET_HISTORY_PAGE_CLICKED:
            return updateObject(state, { scheduleTicketHistoryCurPage: action.payload })
        case AdminOrSuperAdminActionTypes.DELETE_PREVIEW_SCHEDULED_TICKET_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.DELETE_PREVIEW_SCHEDULED_TICKET_FULFILLED:
            return updateObject(state, {
                isLoading: false, previewScheduleTicketList: action.payload.TicketsList ? action.payload.TicketsList : state.ticketsList,
                openDeleteModel: false
            })

        case AdminOrSuperAdminActionTypes.GET_CHISURVEY_QUARTERLY_CHECK_CHATTELS_PENDING:
            return updateObject(state, {
                isLoading: true,
            })
        case AdminOrSuperAdminActionTypes.GET_CHISURVEY_QUARTERLY_CHECK_CHATTELS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                chiSurveyCheck: action.payload
            })

        case AdminOrSuperAdminActionTypes.FRONTLINE_CLICKED:
            return updateObject(state, { frontlineToggle: !state.frontlineToggle })

        case AdminOrSuperAdminActionTypes.UPLOAD_FLUSER_PROFILE_IMAGE_TO_S3_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.UPLOAD_FLUSER_PROFILE_IMAGE_TO_S3_FULFILLED:
            return updateObject(state, { isLoading: true })

        case AdminOrSuperAdminActionTypes.GET_CREATE_FLUSER_PROFILE_IMAGE_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_CREATE_FLUSER_PROFILE_IMAGE_PRESIGNED_URL_FULFILLED:
            return updateObject(state, { isLoading: true })

        case AdminOrSuperAdminActionTypes.GET_EDIT_FLUSER_PROFILE_IMAGE_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.GET_EDIT_FLUSER_PROFILE_IMAGE_PRESIGNED_URL_FULFILLED:
            return updateObject(state, { isLoading: true })

        case AdminOrSuperAdminActionTypes.IMPORT_TICKET_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.IMPORT_TICKET_REJECTED:
            return updateObject(state, { isLoading: false })
        case AdminOrSuperAdminActionTypes.IMPORT_TICKET_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                ticketImportErrorLogs: action && action.payload && action.payload.error ? action.payload.error : null,
            });

        case AdminOrSuperAdminActionTypes.HANDLE_CLOSE_FRONTLINE_FORM:
            return updateObject(state, { frontlineUser: null })

        case AdminOrSuperAdminActionTypes.CUSTOMER_FEEDBACK_QUESTION_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.CUSTOMER_FEEDBACK_QUESTION_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                qqvQuestion: action.payload.question
            })

        case AdminOrSuperAdminActionTypes.INSPECTOR_FEEDBACK_PENDING:
            return updateObject(state, { isLoading: true })
        case AdminOrSuperAdminActionTypes.INSPECTOR_FEEDBACK_FULFILLED:
            return updateObject(state, {
                isLoading: false,
            })

        case AdminOrSuperAdminActionTypes.PROJECT_MODULE_TICKET_PAGE_CLICKED:
            return updateObject(state, { projectModuleTicketCurPage: action.payload })

        case AdminOrSuperAdminActionTypes.PREVIEW_PROJECT_MODULE_TICKET_PAGE_CLICKED:
            return updateObject(state, { previewProjectModuleTicketCurPage: action.payload })

        case AdminOrSuperAdminActionTypes.REMOVE_PRESIGNED_URL:
            debugger;
            return updateObject(state, { ticket: { ...state.ticket, attachments_attributes: [] } })

        /* FL User Shift Attestation Question retrieve GET Call */
        case AdminOrSuperAdminActionTypes.GET_PER_FLUSER_SHIFT_ATTESTATION_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case AdminOrSuperAdminActionTypes.GET_PER_FLUSER_SHIFT_ATTESTATION_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                flShift_attestationQQV: action.payload?.data?.question ?? [],
                language : action.payload?.language
            });

        case AdminOrSuperAdminActionTypes.HANDLE_WEEKLY_LIMIT_SET_CLOSE_MODAL:
            debugger;
            return updateObject(state, {
                weekly_limit_modal: false
            });

        case AdminOrSuperAdminActionTypes.HANDLE_WEEKLY_LIMIT_SET_OPEN_MODAL:

            return updateObject(state, {
                weekly_limit_modal: true
            });
        //AdminOrSuperAdminActionTypes.HANDLE_WEEKLY_LIMIT_SET_CLOSE_MODAL

            case AdminOrSuperAdminActionTypes.GET_LOGBOOK_LOGS_QUESTIONS_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case AdminOrSuperAdminActionTypes.GET_LOGBOOK_LOGS_QUESTIONS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                logbookLogQuestions: action.payload?.data?.question ?? [],
            });

            case AdminOrSuperAdminActionTypes.UPDATE_FRONTLINE_ACTIVE_USER_NAME:
            return updateObject(state, {
                frontline_active_user_name:action.payload
            });
            case AdminOrSuperAdminActionTypes.ADD_NEW_TICKET_WITH_AXIOS:
                return updateObject(state, { isLoading: false, ticketsList: action.payload && action.payload.ticketsList ? action.payload.ticketsList : state.ticketsList, inspection: action.payload && action.payload.inspection ? action.payload.inspection : state.inspection, ticket: {} })
            

            //UPDATE_FRONTLINE_ACTIVE_USER_NAME:"UPDATE_FRONTLINE_ACTIVE_USER_NAME"

        default: return state;

    }
}

//attachments_attributes