
import axios, { API_VERSION, accessToken, timeTrackerBaseUrl } from '../config';

import storage from '../utility/storage';

const pureAxios = require('axios');

const user = storage.get('user');

const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};

export const uploadImageToS3 = (url, arrayBuffer) => {
    return pureAxios.put(url, arrayBuffer);
};

// FOR FACE RECOGNITION
export const getScannedFaceImagePresignUrl = (extention) => {
    return timeTrackerBaseUrl.patch(API_VERSION + `config/public/presignUrl?id=100&name=face`, { data: { ext: extention } }, headers);
};

// FOR FACE RECOGNITION
export const getRecognizedFaceAccountCreds = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `config/face/recognize`, body, headers);
}

export const getUsersListByTheirRoles = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `users/list`, { params }, headers);
}

export const getJobAccountSitesList = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `accounts/list`, { params }, headers);
}

export const getLeaveUpdateManagersList = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `users/list`, { params }, headers);
}

export const getSkillSetList = (data) => {
    return timeTrackerBaseUrl.get(API_VERSION + `${user?.role === 'superadmin' ? 'admin/' : ''}skill${data.activeStatus ? '?activeStatus=' + data.activeStatus : ''}`, headers);
};

export const createSkillSet = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `admin/skill`, body, headers);
};

export const editSkillSet = (body, id) => {
    return timeTrackerBaseUrl.put(API_VERSION + `admin/skill/${id}`, body, headers);
};

export const deleteSkillSet = (id) => {
    return timeTrackerBaseUrl.delete(API_VERSION + `admin/skill/${id}`, headers);
};

export const getDepartmentList = (data) => {
    if (data && data.frontlineLogin && data.frontlineLogin == true) {
        return timeTrackerBaseUrl.get(API_VERSION + `department`, headers);
    } else {
        return timeTrackerBaseUrl.get(API_VERSION + `admin/department${data && data.activeStatus ? '?activeStatus=' + data.activeStatus : ''}`, headers);
    }
};

export const createDepartment = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `admin/department`, body, headers);
};

export const editDepartment = (body, id) => {
    return timeTrackerBaseUrl.put(API_VERSION + `admin/department/${id}`, body, headers);
};

export const deleteDepartment = (id) => {
    return timeTrackerBaseUrl.delete(API_VERSION + `admin/department/${id}`, headers);
};

export const getAllLeaveList = (params) => {
    params.sorted = JSON.stringify(params.sorted);
    return timeTrackerBaseUrl.get(API_VERSION + `leave`, { params }, headers);
}

export const getSingleLeaveRequest = (id) => {
    return timeTrackerBaseUrl.get(API_VERSION + `leave/${id}`, headers);
}

export const deleteLeave = (id) => {
    return timeTrackerBaseUrl.delete(API_VERSION + `leave/${id}`, headers);
};

export const getCreateLeaveAttachmentPresignUrl = (leaveUpdate, extentions) => {
    return timeTrackerBaseUrl.patch(API_VERSION + `config/presignUrl?name=leave${leaveUpdate && leaveUpdate.id ? '?id=' + leaveUpdate.id : ''}`, { data: { ext: extentions } }, headers);
};

export const getEditLeaveAttachmentPresignUrl = (leaveUpdate, extentions) => {
    return timeTrackerBaseUrl.patch(API_VERSION + `config/presignUrl?name=leave${leaveUpdate && leaveUpdate.id ? '?id=' + leaveUpdate.id : ''}`, { data: { ext: extentions } }, headers);
};

export const createFrontlinerLeaveRequest = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `leave`, body, headers);
};

export const editFrontlinerLeaveRequest = (body, id) => {
    return timeTrackerBaseUrl.put(API_VERSION + `leave/${id}`, body, headers);
};

export const getCloseLeaveRequest = (request, id) => {
    return timeTrackerBaseUrl.put(API_VERSION + `leave/status/${id}`, request, headers);
};

export const getScheduledTask = (params) => {
    params.sorted = JSON.stringify(params.sorted);
    return timeTrackerBaseUrl.get(API_VERSION + `task/schedule`, { params }, headers);
};

export const getSubScheduledTask = (params, id) => {
    return timeTrackerBaseUrl.get(API_VERSION + `task/schedule/${id}/tickets`, { params }, headers);
};

export const getSubScheduledTaskPreview = (id, ticketId) => {
    if(id==undefined && ticketId){
        return timeTrackerBaseUrl.get(API_VERSION + `task/schedule/tickets/${ticketId}`,headers)
    }
    return timeTrackerBaseUrl.get(API_VERSION + `task/schedule/${id}/tickets/${ticketId}`, headers);
};

export const getCompanyList = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `companies/list`, { params }, headers);
};

export const newScheduledTaskTicket = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `task/schedule`, body, headers);
}

export const getJobsList = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `accounts/list`, { params }, headers);
};

export const getScheduledTaskEvents = (startDate, endDate, id) => {
    return timeTrackerBaseUrl.get(API_VERSION + `task/schedule?${id ? 'accountId=' + id : ''}&from=${startDate}&to=${endDate}`, headers);
};

export const addComment = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `comments`, body, headers);
};

export const updateComment = (body, commentId) => {
    return timeTrackerBaseUrl.put(API_VERSION + `comments/${commentId}`, body, headers);
};

export const getcommentsAttachmentPresignUrl = (comment, extentions) => {
    return timeTrackerBaseUrl.patch(API_VERSION + `config/presignUrl?name=comment${comment && comment.id ? '?id=' + comment.id : ''}`, { data: { ext: extentions } }, headers);
};

export const getAvailabilityCalendarScheduledList = (startDate, endDate, id) => {
    return timeTrackerBaseUrl.get(API_VERSION + `availability/${id}?from=${startDate}&to=${endDate}`, headers);
};

export const addAvailability = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `availability`, body, headers);
};

export const overrideAvailability = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `availability/overwrite`, body, headers);
};

export const getShiftSchedule = (params) => {
    params.sorted = JSON.stringify(params.sorted);
    return timeTrackerBaseUrl.get(API_VERSION + `shift`, { params }, headers);
};

export const getShiftScheduleDetail = (params, id) => {
    params.sorted = JSON.stringify(params.sorted);
    return timeTrackerBaseUrl.get(API_VERSION + `shift/slot/${id}`, { params }, headers);
};

export const getShiftCalendarScheduledList = (startDate, endDate, id) => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/slot?userId=${id}&from=${startDate}&to=${endDate}`, headers);
};

export const getSingleShifSchedule = (id) => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/slot/one/${id}`, headers);
};

export const getShiftDetailForSingleFLUser = (id) => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/${id}`, headers);
};

export const checkFrontlinerShift = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `shift/user/check`, body, headers);
};

export const createFrontlinerShift = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `shift`, body, headers);
};

export const editFrontlinerShift = (body, id) => {
    return timeTrackerBaseUrl.put(API_VERSION + `shift/${id}`, body, headers);
};

export const editPerDayFLUserShiftDetail = (body, id) => {
    return timeTrackerBaseUrl.put(API_VERSION + `shift/slot/${id}`, body, headers);
};

export const deleteMainShift = (id) => {
    return timeTrackerBaseUrl.delete(API_VERSION + `shift/${id}`, headers);
};

export const getShift = (params) => {
    params.sorted = JSON.stringify(params.sorted);
    return timeTrackerBaseUrl.get(API_VERSION + `shift/slot`, { params }, headers);
};

export const grantCancelShiftRequest = (body, id) => {
    return timeTrackerBaseUrl.put(API_VERSION + `shift/slot/status/${id}`, body, headers);
};

export const getSwapList = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/swap`, { params }, headers);
};

export const getPerDayFLUserGeneralShiftInfo = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `/shift/list${params.userId ? '?userId=' + params.userId : ''}${params.shiftDate ? '&date=' + params.shiftDate : ''}`, headers);
};

export const getPerDayFLUserElaboratedShiftInfo = (params, id) => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/slot/${id}`, { params }, headers);
};

export const createFLUserShiftSwap = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `shift/swap`, body, headers);
};

export const getSpecificShiftSwapInfo = (id) => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/swap/${id}`, headers);
};

export const grantSwapRequestReview = (body, id) => {
    return timeTrackerBaseUrl.put(API_VERSION + `shift/swap/${id}`, body, headers);
};

export const getOverrideAvailabilityRequestList = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `availability/overwrite`, { params }, headers);
};

export const grantOverrideAvailabilityRequest = (body, id) => {
    return timeTrackerBaseUrl.put(API_VERSION + `availability/overwrite/status/${id}`, body, headers);
};

export const getAllAvailability = (params) => {
    params.sorted = JSON.stringify(params.sorted);
    return timeTrackerBaseUrl.get(API_VERSION + `availability`, { params }, headers);
};

/* FL User Shift Clock In Shift Identify CHECK CALL */
export const flUserShiftClockChecker = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `shift/nearer`, body, headers);
};

/* FL User Shift Clock In - Check In POST Call */
export const flUserShiftClockCheckIn = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `shift/checkIn`, body, headers);
};

/* FL User Shift Meal Break Session PUT Call */
export const flUserMealBreakSession = (body, id) => {
    return timeTrackerBaseUrl.put(API_VERSION + `shift/break/${id}`, body, headers);
};

/* FL User Scheduled Tasks Tickets retrieve GET Call */
export const getPerFLUserScheduledTaskTickets = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `task/tickets/shift`, { params }, headers);
};

/* FL User Signature Attachment PATCH Call */
export const getFLUserSignAttachPresignUrl = (shiftId, extentions) => {
    return timeTrackerBaseUrl.patch(API_VERSION + `config/presignUrl?name=shift${shiftId ? '?id=' + shiftId : ''}`, { data: { ext: extentions } }, headers);
};

/* FL User Shift Clock Out - Check Out POST Call */
export const flUserShiftClockCheckOut = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `shift/checkOut`, body, headers);
};

/* FL User Scheduled Task My Tickets GET call */
export const getFlUserScheduledTaskTickets = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `task/tickets`, { params }, headers);
};

/* Timecard Correction Signature Attachment PATCH Call */
export const acquireTimecardCorrectionSignPresignUrl = (shiftId, extentions) => {
    return timeTrackerBaseUrl.patch(API_VERSION + `config/presignUrl?name=shift${shiftId ? '?id=' + shiftId : ''}`, { data: { ext: extentions } }, headers);
};

/* Time Card Correction POST Call */
export const createTimecardCorrectionQuery = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `shift/timecard`, body, headers);
};

/* Time Card Correction Reviewed List GET call */
export const getTimecardReviewedList = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/timecard`, { params }, headers);
};

/* Missed Meal Penalty Break Check API GET call */
export const getMissedMealPenaltyBreaks = (shiftDetailId, currentTime) => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/meal/penalty${shiftDetailId ? '?shift_detail_id=' + shiftDetailId : ''}${currentTime ? '&current_time=' + currentTime : ''}`, headers);
};

/* Time card correction single card API GET call */
export const getSingleTimecardListData = (id) => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/timecard/${id}`, headers);
};

/* Time Card Correction PUT Call */
export const updateTimecardCorrectionQuery = (id, body) => {
    return timeTrackerBaseUrl.put(API_VERSION + `shift/timecard/${id}`, body, headers);
};

/* Time Sheet List GET call */
export const getTimeSheetListInfo = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/slot`, { params }, headers);
};

/* Download Payroll CSV GET call */
export const triggerExportPayrollCSV = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/slot/export`, { params }, headers);
};

/* Get shifts by manipulating params - GET call */
export const getShiftListByParams = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/slot`, { params }, headers);
};

/* Get shifts by Attendance Dashboard Calendar List - GET call */
export const getShiftAttendanceCalendarList = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/calendar`, { params }, headers);
};

export const resolveTicket = (body, scheduleId, ticketId) => {
    if(scheduleId==undefined || scheduleId==null || !scheduleId){
        return timeTrackerBaseUrl.put(API_VERSION + `task/schedule/tickets/${ticketId}`, body, headers);
    }
    return timeTrackerBaseUrl.put(API_VERSION + `task/schedule/${scheduleId}/tickets/${ticketId}`, body, headers);
};

export const deleteTimecardCorrectionCard = (id) => {
    return timeTrackerBaseUrl.delete(API_VERSION + `shift/timecard/${id}`, headers);
};

/* FL User Move on to Next Job PUT Call */
export const flUserShiftNextJob = (body, id) => {debugger
    return timeTrackerBaseUrl.put(API_VERSION + `shift/checkIn/${id}`, body, headers);
};


//HoursType 
export const createHoursType = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `rates`, body, headers);
};

export const getHoursTypeList = (data) => {
    return timeTrackerBaseUrl.get(API_VERSION + `rates${data.activeStatus ? '?activeStatus=' + data.activeStatus : ''}`, headers);
};

export const deleteHoursTypeSet = (id) => {
    return timeTrackerBaseUrl.delete(API_VERSION + `rates/${id}`, headers);
};

export const editHoursType = (body, id) => {
    return timeTrackerBaseUrl.put(API_VERSION + `rates/${id}`, body, headers);
};

export const getPayrollHollidayApi = (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `accounts/holiday`, {params}, headers);
};

export const createPayrollHollidayApi = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `accounts/holiday`, body, headers);
};

export const deletePayrollHollidayApi = (id) => {
    return timeTrackerBaseUrl.delete(API_VERSION + `accounts/holiday/${id}`,);
};

export const importHoursType = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `accounts/list/rate/csv/import`, body, headers);
};
export const fetchRateListDropDown= (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `rates/list/`,{params});
};


export const updatePreviewShift= (body,account_id) => {
    debugger
    return timeTrackerBaseUrl.put(API_VERSION + `shift/slot/${account_id}`,body);
};


export const getAccountHoursType= (params) => {
    return timeTrackerBaseUrl.get(API_VERSION + `rates/list/all`, {params}, headers);
};

export const deleteAccountHoursType = (id) => {
    return timeTrackerBaseUrl.delete(API_VERSION + `accounts/rate/${id}`,);
};







export const importHoursTypeCheck = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `accounts/list/rate/csv/check`, body, headers);
};

// FOR CLOCKIN AND CLOCKOUT FACE RECOGNITION
export const getScannedFrontlineFaceImagePresignUrl = (extention) => {
    return timeTrackerBaseUrl.patch(API_VERSION + `config/public/presignUrl?id=100&name=face`, { data: { ext: extention } }, headers);
};

// FOR CLOCKIN AND CLOCKOUT FACE RECOGNITION
export const frontlineFaceRecognition = (body) => {
    return timeTrackerBaseUrl.post(API_VERSION + `config/shiftface/recognize`, body, headers);
}
    
export const checkClockInValidityAPI = () => {
    return timeTrackerBaseUrl.get(API_VERSION + `shift/facetime`, headers);
}